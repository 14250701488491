import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-datetime/css/react-datetime.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "tippy.js/dist/tippy.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { BsCheckCircle } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 10000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};
const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={style}
    className={`app_alert flex_row_between ${
      options.type === "info"
        ? "app_alert_info"
        : options.type === "success"
        ? "app_alert_success"
        : options.type === "error"
        ? "app_alert_error"
        : ""
    }`}
  >
    {options.type === "info" && <BsInfoCircle />}
    {options.type === "success" && <BsCheckCircle />}
    {options.type === "error" && <BiErrorCircle />}

    {message}
    <button onClick={close}>
      {/* <AiOutlineCloseCircle className="close_alert" /> */}
    </button>
  </div>
);

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </AlertProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
