import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
const Calender = () => {
  // const [subject, setSubject] = useState("");
  // const [body, setBody] = useState("");
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(
    "All users (all locations)"
  );
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");

  useEffect(() => {
    setPageWidth(window.innerWidth);
    setLocations([
      { name: "All users (all locations)" },
      { name: "Lekki Phase 1 Kitchen" },
      { name: "Oniru Kitchen" },
      { name: "Ikeja Kitchen" },
    ]);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  const submitForm = (e) => {
    e.preventDefault();
  };
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab calender="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">in-app Marketing Calendar</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {locations.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentLocation(item.name)}
                      activeItem={currentLocation === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container px_50">
              <form
                onSubmit={(e) => submitForm(e)}
                className="flex_column_right third_width_desktop"
              ></form>
            </section>
            <section className="desktop_visible"></section>
          </section>
        </section>
      </div>
    </main>
  );
};
export default Calender;
