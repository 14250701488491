import React from 'react'
import Input from '../../../components/inputs';
import AppMap from '../../../components/map';
import Modal from '../../../components/modal';


const Addpolygon = ({
  showAddForm, closeModal, submitting, currentCokitchen, setCords, formData, setFormData, onSubmit
}) => {
  return (
    <Modal
      showModal={showAddForm}
      closeModal={closeModal}
      customContent={
        <div>
          <AppMap
            lat={(currentCokitchen && currentCokitchen.lat) || 0}
            lng={(currentCokitchen && currentCokitchen.lng) || 0}
            setCords={setCords}
            setCord
            withMarker
          />
          <Input
            withLabel
            label="Polygon name"
            placeholder="Polygon name"
            value={formData?.polygonName}
            onChange={(e) => setFormData({
              ...formData,
              polygonName: e
            })}
            required
            containerClass="mt_25"
          />
          <Input
            withLabel
            label="Delivery fee"
            placeholder="Delivery fee"
            value={formData?.deliveryFee}
            onChange={(e) => setFormData({
              ...formData,
              deliveryFee: e
            })}
            required
            containerClass="mt_25"
          />
        </div>
      }
      customClass="map_modal"
      onSubmit={onSubmit}
      saveText={submitting ? "Creating Polygon..." : "Create polygon"}
      title="Create a New Polygon"
    />
  )
}

export default Addpolygon