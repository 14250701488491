import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import PolygonItem from "../../components/polygonItem";
import AppMap from "../../components/map";
import Input from "../../components/inputs";
import CommonStore from "../../stores/dataStore/commonStore";
import LocationStore from "../../stores/dataStore/locationsStore";
import { withAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { CheckBox } from "../../components/checkBoxes";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import db from "../../services/firebase.config";
import EditPolygon from "./modals/EditPolygon";
import Addpolygon from "./modals/Addpolygon";
import { POLYGON_COLORS } from "../../helpers/constants";

const LocationSettings = ({ alert, visible }) => {
	const [polygons, setPolygons] = useState([]);
	const [ckLoading, setCkLoading] = useState(false);
	const [cokitchens, setCokitchens] = useState([]);
	const [currentCokitchen, setCurrentCokitchen] = useState("");
	const [currentPolygon, setCurrentPolygon] = useState("");
	const [currentCkId, setCurrentCkId] = useState("");

	const [showSide, setShowSide] = useState(true);
	const [showAddForm, setShowAddForm] = useState(false);
	const [deliverySubmitting, setDeliverySubmitting] = useState(false);
	const [showUpdateForm, setShowUpdateForm] = useState(false);
	const [pageWidth, setPageWidth] = useState("");
	const [selectedPolygons, setSelectedPolygons] = useState([]);
	const [selectedPolygonsText, setSelectedPolygonsText] =
		useState("Select all");
	const [cords, setCords] = useState("");
	const [updateCords, setUpdateCords] = useState("");
	const [submitting, setSubmitting] = useState(null);
	const [updating, setUpdating] = useState(null);
	const [loading, setLoading] = useState(false);
	const [counter, setCounter] = useState(0);
	const [deliveryValues, setDeliveryValues] = useState({
		delivery: false,
		surge: "",
	});
	const [formData, setFormData] = useState({
		polygonName: '',
		deliveryFee: ''
	})

	useEffect(() => {
		setCounter(1);
	}, [visible]);

	useEffect(() => {
		setPageWidth(window.innerWidth);

		if (pageWidth <= 628) {
			setShowSide(false);
		} else {
			setShowSide(true);
		}
	}, [pageWidth]);

	useEffect(() => {
		const handleWindowResize = () => {
			setPageWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [pageWidth]);

	useEffect(() => {
		if (selectedPolygons.length !== polygons.length) {
			setSelectedPolygonsText("Select all");
		} else if (selectedPolygons.length === polygons.length) {
			setSelectedPolygonsText("Deselect all");
		}
	}, [selectedPolygons, polygons.length]);

	console.log(formData)
	console.log(currentCokitchen)

	const loadData = async () => {
		setLoading(true);
		setCkLoading(true);
		await CommonStore.getCokitchens(alert, true);
		await getDeliveryValue();
		setCokitchens(CommonStore.cokitchens);
		setCurrentCokitchen(CommonStore.cokitchens[0]);
		sortRectPolygons();
		setCurrentCkId([0]);
		setCkLoading(false);
		setLoading(false);
	}
	// load data from store
	useEffect(() => {
		loadData()
	}, []);

	const getCurrentCokitchen = () => {
		let curr = (currentCokitchen && currentCokitchen?.cokitchen_polygons) || [];
		setPolygons(curr)
	}

	// Set polygons based on current cokitchen
	useEffect(() => {
		getCurrentCokitchen()
	}, [currentCokitchen]);

	// Set all polygons for the map
	const sortRectPolygons = () => {
		let pols =
			CommonStore.cokitchens[0] && CommonStore.cokitchens[0].cokitchen_polygons;
		let i;
		let polsCopy = [];
	};

	const getDeliveryValue = async () => {
		const response = await db
			.collection("delivery_values")
			.doc("delivery_values")
			.onSnapshot(
				(items) => {
					setDeliveryValues(items?.data());
				},
				(err) => {
					console.log(err);
				}
			);
	};
	// Update cokitchen data

	const updateData = async () => {
		alert.show("Fetching Updates", {
			type: "info",
		});
		await CommonStore.getCokitchens(alert, true);
		setCokitchens(CommonStore.cokitchens);
		setCurrentCokitchen(CommonStore.cokitchens[currentCkId]);
		setCurrentPolygon(
			CommonStore.cokitchens &&
			CommonStore.cokitchens[currentCkId] &&
			CommonStore.cokitchens[currentCkId].cokitchen_polygons[currentCkId]
		);

	};


	// Create polygon
	const createPolygon = async () => {
		setSubmitting(true);
		let cordsCopy = [];
		let i;
		for (i = 0; i < cords?.length; i++) {
			cordsCopy.push([cords[i].lat, cords[i].lng]);
		}
		let data = {
			cokitchen_id: currentCokitchen.id,
			name: formData?.polygonName,
			delivery_fee: formData?.deliveryFee,
			polygon: cordsCopy,
		};
		await LocationStore.postPolygons(alert, data);
		setSubmitting(false);
		resetForm();
		setPolygons([...polygons, data]);
		await updateData();
	};

	// Update delivery factors
	const updateDeliveryFactors = async () => {
		if (parseFloat(deliveryValues?.surge) < 1) {
			alert.show("Surge cannot be less than x1", {
				type: "info",
			});
		} else {
			setDeliverySubmitting(true);
			let body = deliveryValues;
			body.surge = parseFloat(deliveryValues.surge);
			await LocationStore.postDeliveryValues(alert, body);
			setDeliverySubmitting(false);
			await getDeliveryValue();
		}
	};

	// Update polygon
	const updatePolygon = async () => {
		setUpdating(true);
		let cordsCopy = [];
		let i;
		for (i = 0; i < updateCords?.length; i++) {
			cordsCopy.push([updateCords[i].lat, updateCords[i].lng]);
		}
		const data = {
			cokitchen_polygon_id: currentPolygon.id,
			name: currentPolygon?.name,
			delivery_fee: currentPolygon?.delivery_fee,
			polygon: cordsCopy.length !== 0 ? cordsCopy : currentPolygon.polygon,
		}
		await LocationStore.updatePolygons(alert, data);
		resetForm();
		setUpdating(false);
		await updateData();
	};

	// Delete polygon
	const deletePolygon = async () => {
		setSubmitting(true);
		await LocationStore.deletePolygons(currentPolygon.id, alert);
		const filteredPolygon = polygons.filter(
			(item) => item?.id !== currentPolygon?.id
		);
		setPolygons(filteredPolygon || []);
		setSubmitting(false);
		resetForm();
		await updateData();
	};
	const resetForm = () => {
		setFormData({
			polygonName: '',
			deliveryFee: ''
		});
		setShowAddForm(false);
		setShowUpdateForm(false);
	};
	const togglePolygonsSelect = () => {
		if (selectedPolygons.length !== polygons.length) {
			let i;
			let ind = [];
			for (i = 0; i < polygons.length; i++) {
				ind.push(polygons[i]);
			}
			setSelectedPolygons(ind);
			setSelectedPolygonsText("Deselect all");
		} else {
			setSelectedPolygons([]);
			setSelectedPolygonsText("Select all");
		}
	};

	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header />
				<section className="main_section">
					<div className="top_fixed">
						<Tab location_set="active_nav_item nav_item_first" />
						<div className="border_bottom_thick">
							<div className="flex_row_between title_bar">
								<h6 className="bold_24px">
									<span className="underlined">
										Cokitchen Polygons ({polygons?.length})
									</span>{" "}
								</h6>
								<div className="flex_row_center">
									<Button
										withIcon
										icon={
											<PlusIcon width="13px" height="13px" fill="#2699FB" />
										}
										text="New Polygon"
										onClick={() => setShowAddForm(!showAddForm)}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="top_section">
						<div className="mobile_visible px_50 flex_row_between">
							{pageWidth <= 628 ? (
								<button
									className="mr_25 cursor_pointer"
									onClick={() => setShowSide(!showSide)}
								>
									<LocationIcon
										fill={"#FF0000"}
										width="18px"
										height="18px"
										className={showSide ? "rotate_90 ease" : "no_rotate ease"}
									/>
								</button>
							) : null}
						</div>
					</div>
					<section className="content_section">
						{pageWidth > 628 || showSide ? (
							<aside className={`slide_in`}>
								{ckLoading ? (
									<div className="flex_column_left">
										<p className="mb_12">Cokitchens loading...</p>
										<Loader
											type="ThreeDots"
											color="#ff000060"
											height={10}
											width={50}
										/>
									</div>
								) : null}

								{cokitchens.map((item, i) => {
									return (
										<NavItem
											navText={item.name}
											key={i + "name"}
											onClick={() => {
												setCurrentCokitchen(item);
												setCurrentCkId(i);
											}}
											activeItem={
												currentCokitchen && currentCokitchen.name === item.name
											}
										/>
									);
								})}
							</aside>
						) : null}

						<section className="cards_container px_50 flex_column_left_top app_map_container">
							<h6 className="bold_24px mb_15">Map</h6>

							{currentCokitchen && currentCokitchen.lat ? (
								<AppMap
									lat={currentCokitchen && currentCokitchen.lat}
									lng={currentCokitchen && currentCokitchen.lng}
									withMarker
									positions={currentPolygon && currentPolygon.polygon}
									withRect
									withAllRects
									rectPolygons={currentCokitchen?.cokitchen_polygons}
								/>
							) : null}
						</section>
						<aside className="desktop_visible flex_column_left fixed_aside_right" >
							<div className="flex_column_left fixed_aside_inner">
								<form
									className="flex_column_left mb_35"
									onSubmit={(e) => {
										e.preventDefault();
										updateDeliveryFactors();
									}}
								>
									<Tippy content="Contains factors that affect delivery and delivery prices">
										<h6 className="bold_24px mb_25 ">Delivery factors</h6>
									</Tippy>
									<div className="flex_row_left mb_25">
										<h6 className="mr_10 black normal_18px">Surge x</h6>

										<Input
											placeholder="Surge"
											value={deliveryValues?.surge}
											onChange={(e) => {
												setDeliveryValues({
													...deliveryValues,
													surge: e,
												});
											}}
											required
											containerClass="width_52"
											type="number"
											min="1"
											step="0.1"
										/>
										<Tippy content="Surge multiplies the delivery price by its value. 1 is the minimum value">
											<div style={{ marginTop: "-5px", marginLeft: "10px" }}>
												<AiFillQuestionCircle />
											</div>
										</Tippy>
									</div>
									<div className="flex_row_center mb_25">
										<CheckBox
											id="set_end_date"
											label={`Switch delivery ${deliveryValues?.delivery ? "off" : "on"
												}`}
											checked={deliveryValues?.delivery}
											onClick={() => {
												setDeliveryValues({
													...deliveryValues,
													delivery: !deliveryValues?.delivery,
												});
											}}
										/>

										<Tippy content="Checking the box switches on delivery and switches off pickup. Unchecking the box does vice versa">
											<div style={{ marginTop: "-5px", marginLeft: "10px" }}>
												<AiFillQuestionCircle />
											</div>
										</Tippy>
									</div>

									<Button
										text={`${deliverySubmitting ? "Updating" : "Update"
											} delivery factors`}
										type="submit"
										loading={deliverySubmitting}
									/>
								</form>

								<Tippy content="Contains polygons for each cokitchen. Each polygon encaps the areas between its borders.">
									<h6 className="bold_24px mb_25 ">
										Polygons
										{/* <label
                    className="normal_18px cursor_pointer underlined ml_10"
                    onClick={togglePolygonsSelect}
                  >
                    {selectedPolygonsText}
                  </label> */}
									</h6>
								</Tippy>
								{polygons?.map((item, i) => {
									return (
										<PolygonItem
											polygonText={item.name}
											key={i + "name"}
											activeItem={true}
											onClick={() => {
												setCurrentPolygon(item);
												setShowUpdateForm(true);
											}}
											fill={POLYGON_COLORS[i]?.color_code}
										/>
									);
								})}
							</div>
						</aside>
					</section>
				</section>
			</div>

			<Addpolygon
				showAddForm={showAddForm}
				closeModal={() => setShowAddForm(!showAddForm)}
				setCords={setCords}
				onSubmit={(e) => {
					e.preventDefault();
					createPolygon();
				}}
				currentCokitchen={currentCokitchen}
				formData={formData}
				setFormData={setFormData}
				submitting={submitting}
				updating={updating}
			/>

			<EditPolygon
				showUpdateForm={showUpdateForm}
				closeModal={() => setShowUpdateForm(!showUpdateForm)}
				submitting={submitting}
				deletePolygon={deletePolygon}
				setCords={setUpdateCords}
				onSubmit={(e) => {
					e.preventDefault();
					updatePolygon();
				}}
				currentPolygon={currentPolygon}
				setCurrentPolygon={setCurrentPolygon}
				updating={updating}
			/>
		</main >
	);
};
export default withAlert()(LocationSettings);
