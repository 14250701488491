import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import { CheckBox } from "../../components/checkBoxes";
import Select from "../../components/select";
import { DateRangePicker } from "react-date-range";
import ReviewsStore from "../../stores/dataStore/reviewStore";
import CommonStore from "../../stores/dataStore/commonStore";
import Loader from "react-loader-spinner";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import { withAlert } from "react-alert";
import moment from "moment";
import PageLoading from "../../components/loader";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import numberWithCommas from "../../helpers/numberWithCommas";

const RatingsReviews = ({ alert }) => {
	const [brand, setBrand] = useState({ id: "All", label: "All Brands" });
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [showAvgRate, setShowAvgRate] = useState(false);
	const [brands, setBrands] = useState([]);
	const [showDateRange, setShowDateRange] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [ratings, setRatings] = useState([]);
	const [ckLoading, setCkLoading] = useState(false);
	const [cokitchens, setCokitchens] = useState([]);
	const [currentCokitchen, setCurrentCokitchen] = useState("");
	const [showSide, setShowSide] = useState(true);
	//const [ratingsHidden, setRatingsHidden] = useState(false);
	const [currentData, setCurrentData] = useState("");
	const [currentBrands, setCurrentBrands] = useState([]);
	const [pageWidth, setPageWidth] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	useEffect(() => {
		setPageWidth(window.innerWidth);
		if (pageWidth <= 628) {
			setShowSide(false);
		} else {
			setShowSide(true);
		}
	}, [pageWidth]);
	useEffect(() => {
		const handleWindowResize = () => {
			setPageWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [pageWidth]);

	// load reviews from store
	useEffect(async () => {
		setCkLoading(true);
		setLoading(true);

		if (!CommonStore.dataCached) {
			await CommonStore.getCokitchens(alert);
			setCokitchens(CommonStore.cokitchens);
		}
		if (!ReviewsStore.dataCached) {
			await ReviewsStore.getReviews(
				alert,
				true,
				"85c7d173-56ed-44c8-8335-ef0eecabf454"
			);
		}
		if (!BrandStore.dataCached) {
			await BrandStore.getBrands(alert);
		}

		setCkLoading(false);
		setLoading(false);

		setCokitchens(CommonStore.cokitchens);
		setCurrentCokitchen({
			brands: BrandStore.brands,
			name: "All users (all locations)",
		});

		setBrands(BrandStore.brands);
		setRatings(ReviewsStore.reviews);
		//    setPosts(HomeStore.posts);
	}, []);
	// Set brand options based on current cokitchen
	useEffect(async () => {
		let i;
		let options = [{ id: "All", label: "All Brands" }];
		let curr = currentCokitchen.brands || [];
		for (i = 0; i < curr.length; i++) {
			options.push({ ...curr[i], label: curr[i].name, value: curr[i].id });
		}
		setCurrentBrands(options);
		await ReviewsStore.getReviews(alert, false, currentCokitchen.id);
	}, [currentCokitchen]);

	useEffect(() => {
		let i;
		let ind = [];
		for (i = 0; i < ratings.length; i++) {
			ind.push(i);
		}
		setSelectedBrands(ind);
	}, [ratings.length]);

	const onCardSelect = (i) => {
		let newArr = [...selectedBrands, i];
		if (selectedBrands.includes(i)) {
			newArr = newArr.filter((day) => day !== i);
		}
		setSelectedBrands(newArr);
	};

	const handleSelect = (ranges) => {
		setSelectionRange(ranges.selection);
	};

	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header />

				<section className="main_section">
					<div className="top_fixed">
						<Tab ratings="active_nav_item " />
						<div className="border_bottom_thick">
							<div className="flex_row_between title_bar">
								<h6 className="bold_24px">Brand ratings & reviews</h6>
							</div>
						</div>
					</div>
					<div className="top_section">
						<div className="mobile_visible px_50 flex_row_between">
							{pageWidth <= 628 ? (
								<button
									className="mr_25 cursor_pointer"
									onClick={() => setShowSide(!showSide)}
								>
									<LocationIcon
										fill={"#FF0000"}
										width="18px"
										height="18px"
										className={showSide ? "rotate_90 ease" : "no_rotate ease"}
									/>
								</button>
							) : null}
						</div>
					</div>
					<section className="content_section">
						{pageWidth > 628 || showSide ? (
							<aside className={`slide_in`}>
								{ckLoading ? (
									<div className="flex_column_left">
										<p className="mb_12">Cokitchens loading...</p>
										<Loader
											type="ThreeDots"
											color="#ff000060"
											height={10}
											width={50}
										/>
									</div>
								) : null}
								{!ckLoading ? (
									<NavItem
										navText="All users (all locations)"
										onClick={() =>
											setCurrentCokitchen({
												brands: brands,
												name: "All users (all locations)",
											})
										}
										activeItem={
											currentCokitchen.name === "All users (all locations)"
										}
									/>
								) : null}
								{cokitchens.map((item, i) => {
									return (
										<NavItem
											navText={item.name}
											key={i + "name"}
											onClick={() => setCurrentCokitchen(item)}
											activeItem={currentCokitchen.name === item.name}
										/>
									);
								})}
							</aside>
						) : null}
						<section className="flex_column_left width_full">
							<Select
								placeholder="Select brand"
								defaultValue={brand}
								handleChange={(e) => setBrand(e)}
								options={currentBrands}
							/>
							<div className="mb_25 flex_row_top width_full">
								<CheckBox
									id="showAvgRate"
									label="Show average rating on brand page after 5 ratings."
									checked={showAvgRate}
									onClick={() => setShowAvgRate(!showAvgRate)}
								/>

								<button
									className="cursor_pointer"
									onClick={() => setShowDateRange(!showDateRange)}
								>
									Filter by date
								</button>
							</div>

							<div className="flex_row_top width_full mb_35 tab_column">
								<label className="bold_18_px">Ratings & reviews</label>
								<div className="flex_row">
									<label
										className="normal_18px cursor_pointer mr_30"
										// onClick={() => {
										//   ratingsHidden ? setRatings(ratingsCopy) : setRatings([]);
										//   setRatingsHidden(!ratingsHidden);
										// }}
									>
										Hide selected
									</label>
									<label className="normal_18px cursor_pointer">
										Filter: {"All ratings"}
									</label>
								</div>
								{showDateRange && (
									<DateRangePicker
										ranges={[selectionRange]}
										onChange={handleSelect}
										showMonthAndYearPickers={false}
									/>
								)}
							</div>

							{ratings.map((itm, i) => {
								if (brand?.id === "All") {
									return (
										<div key={i + "item"} className="mb_35 width_full">
											<div className="bold_24px mb_15">
												{itm.name} ({itm?.brand_reviews?.length})
											</div>

											{itm?.brand_reviews?.length !== 0 ? (
												itm?.brand_reviews
													?.sort(
														(a, b) =>
															new Date(b.created_at) - new Date(a.created_at)
													)
													.map((item, i) => {
														let rate = parseInt(item?.rate);
														return (
															<TextCard
																stars
																key={i + "item"}
																cardName={
																	item?.user?.first_name +
																	" " +
																	item?.user?.last_name
																}
																cardTime={
																	item?.created_at &&
																	moment(item.created_at).format("LLLL")
																}
																cardLabel={item?.comment || "No comment"}
																starCount={rate}
																star1={rate > 0}
																star2={rate > 1}
																star3={rate > 2}
																star4={rate > 3}
																star5={rate > 4}
																checked={selectedBrands.includes(i)}
																onChange={() => onCardSelect(i)}
																onViewClick={() => {
																	setShowModal((prev) => !prev);
																	setCurrentData(item);
																}}
															/>
														);
													})
											) : (
												<div className="gray">{`No Reviews for ${itm.name} `}</div>
											)}
										</div>
									);
								} else if (itm?.id === brand.id) {
									return (
										<div key={i + "item"} className="mb_35 width_full">
											<div className="bold_24px mb_15">
												{itm.name} ({itm?.brand_reviews?.length})
											</div>

											{itm?.brand_reviews?.length !== 0 ? (
												itm?.brand_reviews.map((item, i) => {
													let rate = parseInt(item?.rate);
													return (
														<TextCard
															stars
															key={i + "item"}
															cardName={
																item?.user?.first_name +
																" " +
																item?.user?.last_name
															}
															cardTime={
																item?.created_at &&
																moment(item.created_at).format("LLLL")
															}
															cardLabel={item?.comment || "No comment"}
															starCount={rate}
															star1={rate > 0}
															star2={rate > 1}
															star3={rate > 2}
															star4={rate > 3}
															star5={rate > 4}
															checked={selectedBrands.includes(i)}
															onChange={() => onCardSelect(i)}
															onViewClick={() => {
																setShowModal((prev) => !prev);
																setCurrentData(item);
															}}
														/>
													);
												})
											) : (
												<div className="gray">{`No Reviews for ${itm.name} `}</div>
											)}
										</div>
									);
								}
							})}
							<PageLoading loading={loading} />
						</section>
						<section className="desktop_visible"></section>
					</section>
				</section>
			</div>
			<Modal
				title="Review Details"
				showModal={showModal}
				closeModal={() => setShowModal(!showModal)}
				customContent={
					<div>
						<div className="mb_15">
							<label className="gray_label">Customer's Name:</label>
							<p className="capitalize black">
								{currentData?.user?.first_name +
									" " +
									currentData?.user?.last_name}
							</p>
						</div>

						<div className="mb_15">
							<label className="gray_label">Gender:</label>
							<p className="capitalize black">
								{currentData?.user?.user_gender}
							</p>
						</div>

						<div className="mb_15">
							<label className="gray_label">Rating:</label>
							<p className="black">
								{parseInt(currentData?.rate) + "/" + "5" || "No Rate."}
							</p>
						</div>
						<div className="mb_15">
							<label className="gray_label">Comment:</label>
							<p className="black">{currentData?.comment || "No comment."}</p>
						</div>

						<div className="mb_15">
							<label className="gray_label">Date/Time:</label>
							<p className="black">
								{moment(currentData?.created_at).format("LLLL")}
							</p>
						</div>

						<div className="mb_15">
							<label className="gray_label">Wallet balance:</label>
							<p className="capitalize black">
								₦
								{currentData?.user?.balance &&
									numberWithCommas(currentData?.user?.balance)}
							</p>
						</div>
						<div className="mb_15">
							<label className="gray_label">User since:</label>
							<p className="black">
								{moment(currentData?.user?.created_at).format("LLLL")}
							</p>
						</div>
					</div>
				}
				customClass="feedback_modal"
				onSubmit={(e) => {
					e.preventDefault();
					setShowModal(false);
				}}
				saveText={"Close"}
			/>
		</main>
	);
};
export default withAlert()(RatingsReviews);
