import React from "react";
import { Link } from "react-router-dom";
import { Clock } from "../../assets/icons/icons";
import { ADD_DEALS } from "../../router/router";
import { Button } from "../buttons";
import "./styles.scss";
const FeedCard = ({
	loading,
	src,

	subtitle,
	name,
	className,

	logo_src,
	isLoading,
	onClick,
	deleteFeed,
	onClickItem,
}) => {
	return (
		(loading && (
			<div className="image_placeholder deal_card" onClick={onClickItem}>
				<div className="post_card_image" />
				<div className="time_span" />
				<div>
					<div className="flex_row_top">
						<div className="mr_10 brand_logo_placeholder" />
						<div className="flex_column width_full">
							<div className="title_placeholder" />
							<div className="name_placeholder" />
						</div>
					</div>

					<div className="body_placeholder" />
				</div>
			</div>
		)) || (
			<figure className={`deal_card feed ${className}`} onClick={onClickItem}>
				<div
					className="post_card_image"
					// src={src}
					// alt={title}
					style={{
						backgroundImage: `url(${src})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundColor: "#acacac",
						width: "100%",
						height: "98px",
						borderRadius: "10px 10px 0 0",
					}}
				/>

				<div className="card_captions">
					<div className="card_flex">
						<img src={logo_src} alt="brand_logo" className="mr_15" />
						<div className="width_full">
							<figcaption className="name gray mb_4 capitalize">
								{name}
							</figcaption>
							<figcaption className="subtitle gray ellipses_end_two mb_15">
								{subtitle}
							</figcaption>
						</div>
					</div>
					<div className="view-btn">
						<Button
							text={"Delete Feed"}
							isRed
							loading={isLoading}
							onClick={deleteFeed}
						/>
						<label
							className="gray normal_16px cursor_pointer "
							onClick={onClick}
						>
							view & edit {"  >"}
						</label>
					</div>
				</div>
			</figure>
		)
	);
};

export default FeedCard;
