import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import { Button } from "../../components/buttons";
import Select from "../../components/select";
import Loader from "react-loader-spinner";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { FaInfo } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import errorHandler from "../../helpers/errorHandler";
import MealsList from "../../components/mealsList";
import CsvParserNew from "../../components/csvParserNew";
import { CSV_TITLES_NEW } from "../../components/csvParserNew/utils";
import MealLayout from "../../components/layout";
import BulkFileUpload from "../../components/bulkFileUpload";
import { uploadFilesAndGetUrl } from "../../helpers/upload";

const MealListPage = ({ alert }) => {
  const history = useHistory();
  const [cokitchens, setCokitchens] = useState([]);
  const [csvImages, setCsvImages] = useState([]);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [csvImagesFound, setCsvImagesFound] = useState([]);
  const [pageWidth, setPageWidth] = useState("");
  const [showSide, setShowSide] = useState(true);
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [checkedMeals, setCheckedMeals] = useState([]);
  const [csvAddonData, setCsvAddonData] = useState([]);
  const [form, setForm] = useState({
    cokitchen_id: "",
    brand_id: "",
  });
  const [ckLoading, setCkLoading] = useState([]);
  const [loading, setLoading] = useState([]);

  const [csvMeals, setCsvMeals] = useState([]);
  const [csvAddons, setCsvAddons] = useState([]);
  const [showCsvNewModal, setShowCsvNewModal] = useState(false);
  const [csvNewData, setCsvNewData] = useState([]);
  const [checkedNewMeals, setCheckedNewMeals] = useState([]);
  const [csvNewAddonData, setCsvNewAddonData] = useState([]);
  const [titlesNew, setTitlesNew] = useState([]);
  const [addingNew, setAddingNew] = useState(false);
  const [isAddonCsv, setIsAddonCsv] = useState(false);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentLocation, setCurrentLocation] = useState("add meals via csv");
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (!BrandStore.dataAltCached || !CommonStore.dataCached || !dataCached) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        CommonStore.getCokitchens(alert),
      ]);
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });
    setBrands(BrandStore.brandsAlt);
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = currentCokitchen.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name,
        value: curr[i].id,
        index: i,
      });
    }
    setBrands(options?.filter((item) => item?.item_type === "FOOD"));
  }, [currentCokitchen]);
  const createNewMeals = async () => {
    const { createBulkMeal } = BrandStore;
    const images = csvImages.filter(image => checkedNewMeals.find(meal => {
     console.log(meal.image, image.name)
      return meal?.Image?.name === image.name

    }))
    let imagesUrl = [];
    try {
      imagesUrl = await uploadFilesAndGetUrl(images);
      
    } catch (error) {
      errorHandler(error, alert);
      console.log(error)
    }
    console.log(imagesUrl)
    
    let body = checkedNewMeals
    console.log(images)
    const imageObj = images.map((image, index) => {
      return {
        name: image.name,
        url: imagesUrl[index]
      }
    }).reduce((acc, imageObj) => {
      acc[imageObj.name] = imageObj.url
      return acc
    }, {})
    body = body?.map((item) => {
      let imgUrlFormat = item?.Image;
      const imageUrlMatch = imageObj[imgUrlFormat.name]
      console.log(imageObj, imgUrlFormat)
      let bodyAddons = item[CSV_TITLES_NEW.addonsString]?.map((ele) => {
        return {
          meal_category_name: ele[CSV_TITLES_NEW.addonCategoryString],
          name: ele[CSV_TITLES_NEW.addonNameString],
          amount: ele[CSV_TITLES_NEW.addonPriceString],
        };
      });
  return {
    name: item[CSV_TITLES_NEW.nameString],
    description: item[CSV_TITLES_NEW.descString],
    preparation_time: item[CSV_TITLES_NEW.prepString],
    amount: item[CSV_TITLES_NEW.rateString],
    meal_category_name: item[CSV_TITLES_NEW.categoryString],
    addons: bodyAddons,
    brand_id: brand,
    item_type: "FOOD",
    images: imageUrlMatch ? [imageUrlMatch] : [],
    calories: item[CSV_TITLES_NEW.calorieString]|| null,
  };
    });

    if (!brand) {
      alert.show("Select a brand for the new meals!", {
        type: "info",
      });
    } else if (!csvMeals[0]) {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    } else {
      // console.log("body",body,"checkedNewMeals",checkedNewMeals)
      setAddingNew(true);
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
     await createBulkMeal(alert, { meals: body });
     console.log(body)
      setAddingNew(false);
    }

  };
  const handleCsvImages = (e) => {
    const imgFiles = Object.values(e);
    let newImaageFiles = [...csvImages, ...imgFiles];
    newImaageFiles = newImaageFiles.filter((item) => item?.name);
    setCsvImages(newImaageFiles);
  };
  return (
    <MealLayout
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      headerTitle={"New meals via CSV"}
      content={"Upload meals via csv "}
      handleChange={setCurrentCokitchen}
      pageLoading={ckLoading}
      loading={loading}
      options={cokitchens}
      children={
        <Fragment>
          <section className="width_full new_addon_container">
            {ckLoading ? (
              <div className="mb_25">
                <Loader
                  type="ThreeDots"
                  color="#ff000060"
                  height={10}
                  width={50}
                />
              </div>
            ) : (
              <div>
                <div className="info_important bold flex_row">
					<div className="flex_row">
					<h5 className=""><FaInfo width="20px" height="40px" /></h5> 
                  <p>
                    Select brand to then upload Csv files
                  </p>
					</div>
               
                </div>
                <Select
                  placeholder="Select brand"
                  defaultValue={brand}
                  handleChange={(e) => {
                    setBrand(e.id);
                  }}
                  containerClass="width_full"
                  options={brands}
                  name="name"
                  id="id"
                />
              </div>
            )}
            {
              brand === "" ? null : (
                <BulkFileUpload
                handleChange={(e) => handleCsvImages(e)}
                file={csvImages}
                multiple
              />
              ) 
            }

            {csvImages.length === 0 ? null : (
              <div>
                <div className="parser_container">
                  <CsvParserNew
                    setCsvData={(e) => setCsvNewData(e)}
                    setTitles={(e) => setTitlesNew(e)}
                    setCsvAddons={(e) => setCsvAddons(e)}
                    setCsvMeals={(e) => setCsvMeals(e)}
                    setCsvImagesFound={(e)=>setCsvImagesFound(e)}
                    csvImages={csvImages}
                  />
                  
     
                  {csvNewData?.length > 0 && (
                    <h4 className="black ">
                      {csvNewData?.length} Meals Found |{" "}
                      {checkedNewMeals?.length} Meals Selected
                    </h4>
                  )}
                  {csvMeals && (
                    <div className="meal_list">
                      <MealsList
                        meals={csvNewData}
                        titles={titlesNew}
                        setCheckedMeals={(e) => {
                          setCheckedNewMeals(e);
                        }}
                        isNew
                      />
                    </div>
                  )}
                </div>

                <div className="flex_row_center mt_15 mb_15">
                  <Button
                    text={addingNew ? "Creating..." : "Create meals"}
                    loading={addingNew}
                    onClick={(e) => {
                      e.preventDefault();
                      createNewMeals();
                    }}
                    disabled={() => csvMeals.length < 1}
                  />
                </div>
              </div>
            )}
          </section>
        </Fragment>
      }
      backArrow
    />
  );
};

export default withAlert()(MealListPage);
