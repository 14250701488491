import React, { useState, useEffect} from "react";
import "./styles.scss";
import MealListAcc from "./mealListAcc";
import { CSV_TITLES_NEW } from "../csvParserNew/utils";
const MealsList = ({ meals=[], titles, setCheckedMeals, isNew }) => {
  const [selectedMeals, setSelectedMeals] = useState([]);
  let newString=CSV_TITLES_NEW.newString;
  let brandString=CSV_TITLES_NEW.brandString;
  let categoryString=CSV_TITLES_NEW.categoryString;
  let prepString=CSV_TITLES_NEW.prepString;
  let amountString=CSV_TITLES_NEW.amountString;
  let descString=CSV_TITLES_NEW.descString;
  let summaryString=CSV_TITLES_NEW.summaryString;
  let addonString=CSV_TITLES_NEW.addonString;
  let addonsString=CSV_TITLES_NEW.addonsString;
  let parentString=CSV_TITLES_NEW.parentString;
  let parentAltString=CSV_TITLES_NEW.parentAltString;
  let nameString=CSV_TITLES_NEW.nameString;
  let rateString=CSV_TITLES_NEW.rateString;
  let imageString = CSV_TITLES_NEW.imageString;
  let calorieString = CSV_TITLES_NEW.calorieString;


  useEffect(()=>{
    let allMeals=[...meals]
    let validMeals=sortValidMeals(allMeals)
   
    setSelectedMeals(validMeals)
    setCheckedMeals(validMeals)

  },[])
  useEffect(()=>{
    let allMeals=[...meals]
    let validMeals=sortValidMeals(allMeals)
      setSelectedMeals(validMeals)
      setCheckedMeals(validMeals)
    console.log("selectedMeals length",selectedMeals?.length, meals?.length)

  },[meals])

  const sortValidMeals=(items)=>{
    let validMeals =[];
    
    if(isNew){

      validMeals =  items?.filter(item=>
        item&&
        item[nameString]&&
        item[prepString]&&
        item[rateString]&&
        item[descString]&&
        item[addonsString]&&
        item[imageString]&&
        item[categoryString]
        
        
        )
    }
    else{
      validMeals =  items?.filter(item=>
        item&&
        item[nameString]&&
        item[prepString]&&
        item[amountString]&&
        item[descString]&&
        item[addonsString]&&
        item[imageString]&&
        item[brandString]?.id&&
        item[categoryString]?.id
        
        )
    }
    console.log("validMeals",validMeals,"items",items)
    return validMeals
  
  }
  const handleMealCheckboxChange = (item) => {
    let newArr = [...selectedMeals, item];
    let match = selectedMeals?.find((el) => el?.meal_index === item?.meal_index)
    if (match) {
      newArr = newArr.filter((itm) => itm?.meal_index !== item?.meal_index);
    }
    setSelectedMeals(newArr);
    setCheckedMeals(newArr);
  };
  const handleMealInputChange = (item, el,e) => {
    let newArr = [...selectedMeals];
    let match = selectedMeals?.find((el) => el?.meal_index === item?.meal_index)
    if ( 
      el?.toUpperCase()===newString?.toUpperCase()||
      el?.toUpperCase()===addonString?.toUpperCase()
    
    )
    {
      match[el]=!e
    }
    else{
      match[el]=e
    }
    setSelectedMeals(newArr);
  };
let brandData;

  return (
    meals?.length>0?
  <div className="meals_list_container">

{meals?.map((item,i)=>{
  item.meal_index=i;
  let isChecked= selectedMeals?.find(itm=>itm?.meal_index===i)?true:false;
return(
  
<MealListAcc
 i={i} 
 item={item}
 isChecked={isChecked}
 handleMealCheckboxChange={(e)=>handleMealCheckboxChange(e)}
 titles={titles}
 brandData={brandData}
 handleMealInputChange={(a,b,c)=>{handleMealInputChange(a,b,c)}}
 isNew={isNew}
 meals={meals}
/>
)}
)}

   </div>
   :null
  );
};
export default MealsList;
