import React, { useState, useEffect , useRef} from "react";
import "./styles.scss";
import { CheckBox } from "../checkBoxes";
import IconInput from "../inputs/iconInput";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import Select from "../select";
import { FiChevronDown } from 'react-icons/fi'
import ImageFile from "../imageFile";

const MealListAcc =({
    i, 
    item,
    isChecked,
    handleMealCheckboxChange,
    titles,
    brandData,
    handleMealInputChange,
    isNew,
    meals,


})=>{
    
    const [setActive, setActiveState] = useState(i===0?'activeAccordion':'')
    const [showAcc, setShowAcc] = useState(i===0?true:false)
    const [setRotate, setRotateState] = useState(i===0?'accordion__icon rotate':'accordion__icon')
    const content = useRef(null)
    let newString="Meal is new";
    let brandString="brand";
    let categoryString="Category Name";
    let prepString="Prepration Time (mins)";
    let amountString="Amount";
    let descString="Item Description";
    let summaryString="summary";
    let addonString="Meal is addon";
    let addonsString="addons";
    let parentString="Meal parents and addon categories";
    let parentAltString="Parent Meals"
    let nameString="Item Name";
    let rateString="Base Rate (inclusive of tax)";
    let imageString="Image";
    



function toggleAccordion () {
    setActiveState(setActive === '' ? 'activeAccordion' : '')
    setShowAcc(
      setActive === 'activeAccordion'
        ?false
        : true
    )
    setRotateState(
      setActive === 'activeAccordion'
        ? 'accordion__icon'
        : 'accordion__icon rotate'
    )
  } 

  return(

<div 
className="text_card mb_30 black flex_column_left cursor_pointer position_relative width_full"  
key={i+"item"} 

>
<div className="flex_row_between width_full">
<div className=" bold" >
{i+1}. {item[nameString]}

        </div>
<div className="flex_row_between " >
   <CheckBox
      id={"is_addon"+i}
      mb=""
      checked={isChecked}
      onClick={()=>{handleMealCheckboxChange(item)}}
      label={`${isChecked?"Deselect":"Select"} meal`}
      />


            <button
            style={{
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '50%',
              width: 28,
              height: 28,
              marginLeft:"10px"
            }}
            className={`flex_row_center cursor_pointer accordion ${setActive} ${setRotate}`}
            onClick={toggleAccordion}
            type="button"
          >
            <FiChevronDown size={30} />
          </button>
      </div>
      
</div>
 <div 
 className="grid_third_two width_full  mt_35 accordion__content"
 ref={content}
 style={{ display: `${showAcc?"grid":"none"}` }}
 >
   {titles
   ?.filter(itm=> itm!==parentString)
   ?.map((el,ind)=>{
 let isBrand= el?.toUpperCase()===brandString?.toUpperCase(),
     isMealCategory= el?.toUpperCase()===categoryString?.toUpperCase();
  let isImageFound   = el?.toUpperCase()===imageString?.toUpperCase();
     brandData= isBrand &&item[el]

     return (
   <div className="mb_12" key={el+ind}> 
     {
     el?.toUpperCase()===newString?.toUpperCase()||
     el?.toUpperCase()===addonString?.toUpperCase()
     ?
     <div className="flex_column_left mt_25" style={{height:"100%", }}>
     <CheckBox
        id={"is_new_addon"+i}
        checked={item[el]}
        onClick={()=>{
          handleMealInputChange(item, el, item[el])
        }}
        label={el}
        disabled={!isChecked}
        />
        </div>
        : (isBrand||isMealCategory)&&!isNew
        ?
        <div className="flex_column_left">
          <label className="mb_15 normal_17px">{el}</label>
          <Select
            disabled={!isChecked}
              placeholder={isBrand? "Select brand": "Select meal category"}
              value={item[el]}

              handleChange={(e) => {
                handleMealInputChange(item, el, e)
              }}
              containerClass=""
              options={isBrand? BrandStore.brandsAlt: meals[i][brandString]?.meal_categories }
              name="name"
              id="id"
            />
            </div>
            :
            el?.toUpperCase() === imageString?.toUpperCase()
              ?
              <ImageFile
                onChange={e => {
                  handleMealInputChange(item, el, e.target.files[0])
                }}
                src={typeof (item[el]) === "object" ? URL.createObjectURL(item[el]) : item[el]}
              /> 
            :el?.toUpperCase()==="placeholder"?.toUpperCase()
            ? <div/>
            :el?.toUpperCase()===parentAltString?.toUpperCase()
            ? 
            <div className="width_full flex_column_left" style={{width:"300%"}}>
             <div className="flex_column_left">
          <label className="mb_15 normal_17px">{el}</label>
          <Select

            disabled={!isChecked}

            placeholder="Select parent meal"
              value={item[el]?.parentMeals}
              isMulti
              handleChange={(e) => {
                // let i;
                // let copy = [];
                // for (i = 0; i < e.length; i++) {
                //   copy.push(e[i].id);
                // }
                // setForm({ ...form, meal_parent_ids: copy });
                handleMealInputChange(item, el, {parentMeals:e})
              }}
             
              containerClass=""
              options={item[el]?.parentMeals }
              name="name"
              id="id"
            />
            </div>
            <div className="grid_second width_full">
                      {
                        item[el]?.parentMeals?.map((itm, ind)=>{
                          
                          
                          return(
                            <div key={i+itm?.id}  className="align_left justify_start column width_full mb_25 border_bottom_thin">
                            <p className="mb_25">
                            {itm?.name}
                          </p>
                          {
                            itm?.meal_addon_categories?.map((ele, index)=>{
                              let isChecked= item[el]?.parentObject?.addon_categories?.find(el=> el?.toUpperCase()===itm?.name?.toUpperCase())
                              return(

                          <div className="flex_row_left mb_4 width_full" key={index+itm?.id}>
                            
                                <CheckBox
                                  id={ele?.id}
                                  label={ele?.name}
                                  checked={ isChecked}
                                  onClick={() => {
                                    if(isChecked)
                                    {
                                    //  handleCheckboxChangeAlt(itm)

                                    }
                                    else{
                                    //  handleCheckboxChange(itm)

                                    }

                                   
                                  }
                                
                                }
                                />
                          </div>
                              )
                            }) 
                          }

                    
                          </div>
                          )
                        })
                         
                      }
                      
                    </div>
            </div>
        :
        // addonsString
        el?.toUpperCase()===addonsString?.toUpperCase()
            ? 
            <div className="width_full flex_column_left" style={{width:"300%"}}>
           <h5 className="mb_25">Addons ({item[el]?.length})</h5>
            <div className="grid_second width_full">
                      {
                        item[el]?.map((itm, ind)=>{
                             return(
                            <div 
                className="text_card black flex_row_between"  
                key={ind+"item"} 
                onClick={()=>{
                 

                }}
                >
                  <div className="flex_column_left">
                 <span className="mb_12"><strong>Name:</strong> {itm["Addon Name"]}</span> 
                 <span className="mb_12"><strong>Price:</strong> {itm["Addon Price"]}</span> 
                 <span className="mb_12"><strong>Category:</strong> {itm["Addon Category"]}</span> 
                
                 </div>
                
                </div>
                          )
                        })
                         
                      }
                      
                    </div>
            </div>
        :
     <IconInput
      disabled={!isChecked}
      required={el!==summaryString}
        label={el}
        value={item[el]}
        onChange={(e) =>
          handleMealInputChange(item, el, e)
        }
        />}
   </div>
   )})
   
    }  
   </div> 
 
</div>

  )

}
export default MealListAcc