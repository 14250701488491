import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import { DateRangePicker } from "react-date-range";
import AppVersionsStore from "../../stores/dataStore/appVersionsStore";
import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import Prompt from "../../components/modal/prompt";
import { Button } from "../../components/buttons";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";

const AppVersions = ({ alert }) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [faqs, setFaqs] = useState("");
  const [loading, setLoading] = useState(false);
  // const [selectedRatings, setSelectedRatings] = useState([]);
  const locations = [
    { name: "All users (all locations)" },
    { name: "Lekki Phase 1 Kitchen" },
    { name: "Oniru Kitchen" },
    { name: "Ikeja Kitchen" },
  ];

  const [currentLocation, setCurrentLocation] = useState(
    "All users (all locations)"
  );

  const [showSide, setShowSide] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  //const [ratingsHidden, setRatingsHidden] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [faqForm, setFaqForm] = useState({
    android: "",
    ios: "",
    text: "",
    header: "",
    button_link: "",
    skip_bool: false,
    skip_text: "",
    button_text: "",
    whats_new: "",
  });
  const [submitting, setSubmitting] = useState(null);
  const [rearrangeDisabled, setrRarrangeDisabled] = useState(false);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load faqs from store
  useEffect(async () => {
    if (!AppVersionsStore.dataCached) {
      setLoading(true);
      await AppVersionsStore.getVersions(alert);
      setLoading(false);
    }
    setFaqs(AppVersionsStore.versions);
    let data = AppVersionsStore.versions;
    setFaqForm({
      ...faqForm,
      android: data?.android,
      ios: data?.ios,
      text: data?.text,
      header: data?.header,
      button_link: data?.button_link,
      skip_bool: data?.skip_bool,
      skip_text: data?.skip_text,
      button_text: data?.button_text,
      whats_new: data?.whats_new,
    });
  }, []);

  const formSubmit = async (e) => {
    e.preventDefault();
    const { postVersions, getVersions } = AppVersionsStore;
    setSubmitting(true);
    await postVersions(alert, faqForm);
    setSubmitting(false);
    await getVersions(alert);
    let data = AppVersionsStore.versions;
    setFaqForm({
      ...faqForm,
      android: data?.android,
      ios: data?.ios,
      text: data?.text,
      header: data?.header,
      button_link: data?.button_link,
      skip_bool: data?.skip_bool,
      skip_text: data?.skip_text,
      button_text: data?.button_text,
      whats_new: data?.whats_new,
    });
  };

  const faqUpdate = async () => {
    const { updateVersions, getVersions } = AppVersionsStore;
    setSubmitting(true);
    await updateVersions(alert, {
      faq_id: currentData.id,
      answer: currentData.answer,
    });
    setSubmitting(false);
    await getVersions(alert);
    let data = AppVersionsStore.versions;
    setFaqForm({
      ...faqForm,
      android: data?.android,
      ios: data?.ios,
      text: data?.text,
      header: data?.header,
      button_link: data?.button_link,
      skip_bool: data?.skip_bool,
      skip_text: data?.skip_text,
      button_text: data?.button_text,
      whats_new: data?.whats_new,
    });

    setShowModal(false);
  };

  const faqArrangementUpdate = async () => {
    const { updateVersionsArrangement, getVersions } = AppVersionsStore;
    setLoading(true);
    let i;
    let data = [];
    for (i = 0; i < faqs.length; i++) {
      data.push({
        id: faqs[i].id,
        answer: faqs[i].answer,
        question: faqs[i].question,
      });
    }
    await updateVersionsArrangement(alert, {
      faq_arrangement: data,
    });
    await getVersions(alert);
    setFaqs(AppVersionsStore.faqs);
    setLoading(false);
  };

  const faqDelete = async () => {
    const { deleteVersions, getVersions } = AppVersionsStore;
    setSubmitting(true);
    await deleteVersions(currentData.id, alert);
    await getVersions(alert);
    setFaqs(AppVersionsStore.faqs);
    setSubmitting(false);
    setShowPrompt(false);
    setShowModal(false);
  };

  const onCardSelect = (i) => {
    let newArr = [...selectedBrands, i];
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedBrands(newArr);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab versions="active_nav_item " />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">App Versions</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {locations.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentLocation(item.name)}
                      activeItem={currentLocation === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section
              className="flex_column_left width_full"
              style={{ width: "100%" }}
            >
              <div className="mb_25 flex_row_top width_full"></div>

              <div className="flex_row_top width_full mb_35 tab_column">
                <label className="bold_18_px">App Versions</label>
                {/* <div className="flex_row">
                  <label
                    className="normal_18px cursor_pointer mr_30"
                    // onClick={() => {
                    //   ratingsHidden ? setRatings(ratingsCopy) : setRatings([]);
                    //   setRatingsHidden(!ratingsHidden);
                    // }}
                  >
                    Hide selected
                  </label>
                  <label className="normal_18px cursor_pointer">
                    Filter: {"All ratings"}
                  </label>
                </div> */}

                {showDateRange && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                )}
              </div>

              <form className="width_52" onSubmit={formSubmit}>
                <Input
                  placeholder="Android version"
                  value={faqForm.android}
                  onChange={(e) => setFaqForm({ ...faqForm, android: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="Android version"
                />
                <Input
                  placeholder="ios version"
                  value={faqForm.ios}
                  onChange={(e) => setFaqForm({ ...faqForm, ios: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="ios version"
                />
                <Input
                  placeholder="Header"
                  value={faqForm.header}
                  onChange={(e) => setFaqForm({ ...faqForm, header: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="Header"
                />
                <Input
                  placeholder="What's new"
                  value={faqForm.whats_new}
                  onChange={(e) => setFaqForm({ ...faqForm, whats_new: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="What's new"
                />
                <Input
                  placeholder="Button link"
                  value={faqForm.button_link}
                  onChange={(e) => setFaqForm({ ...faqForm, button_link: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="Button link"
                />
                <Input
                  placeholder="Button text"
                  value={faqForm.button_text}
                  onChange={(e) => setFaqForm({ ...faqForm, button_text: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="Button text"
                />
                <Input
                  placeholder="Skip text"
                  value={faqForm.skip_text}
                  onChange={(e) => setFaqForm({ ...faqForm, skip_text: e })}
                  required
                  containerClass="mb_15 "
                  withLabel
                  label="Skip text"
                />
                <div className="flex_row_start">
                  <CheckBox
                    id="skip_bool"
                    label={`Skip?`}
                    checked={faqForm?.skip_bool}
                    onClick={() => {
                      setFaqForm({
                        ...faqForm,
                        skip_bool: !faqForm?.skip_bool,
                      });
                    }}
                  />
                </div>
                <label className="mb_30  normal_17px capitalize">
                  Description
                </label>
                <textarea
                  placeholder="Write short text"
                  value={faqForm.text}
                  onChange={(e) =>
                    setFaqForm({ ...faqForm, text: e.target.value })
                  }
                  required
                  className="text_area text_area_short mb_35"
                />
                <Button
                  className="mt_35"
                  text={submitting ? "Updating..." : "Update app version"}
                  loading={submitting}
                />
              </form>

              <PageLoading loading={loading} />
            </section>
          </section>
        </section>
      </div>
      <Modal
        title="Edit FAQ"
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        customContent={
          <div>
            <Input
              placeholder="Android version"
              value={currentData.android}
              onChange={(e) => setCurrentData({ ...currentData, android: e })}
              required
              containerClass="mb_15 "
              disabled
            />
            <Input
              placeholder="ios version"
              value={currentData.ios}
              onChange={(e) => setCurrentData({ ...currentData, ios: e })}
              required
              containerClass="mb_15 "
            />

            <Input
              placeholder="Write short text"
              value={currentData.text}
              onChange={(e) => setCurrentData({ ...currentData, text: e })}
              required
              containerClass="mb_15 "
            />
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          faqUpdate();
        }}
        saveText={submitting ? "Updating..." : "Update FAQ"}
        loading={submitting}
        withDel
        deleteText="Delete FAQ"
        delClick={() => setShowPrompt(true)}
      />

      <Modal
        title="Create FAQ"
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(!showCreateModal)}
        customContent={
          <div>
            <Input
              placeholder="Android version"
              value={faqForm.android}
              onChange={(e) => setFaqForm({ ...faqForm, android: e })}
              required
              containerClass="mb_15 "
            />
            <Input
              placeholder="ios version"
              value={faqForm.ios}
              onChange={(e) => setFaqForm({ ...faqForm, ios: e })}
              required
              containerClass="mb_15 "
            />
            <Input
              placeholder="Write short text"
              value={faqForm.text}
              onChange={(e) => setFaqForm({ ...faqForm, text: e })}
              required
              containerClass="mb_15 "
            />
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? "Creating..." : "Create app version"}
        loading={submitting}
      />

      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          faqDelete();
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to delete this version?"
        deleteText={submitting ? "Deleting..." : "Delete"}
      />
    </main>
  );
};
export default withAlert()(AppVersions);
