import axios from "axios"


const uploadFile = async (file, compress) => {
  const baseUrl = process.env.REACT_APP_UPLOAD_SERVICE_URL
  const formData = new FormData()
  formData.append('file', file)
  formData.append('compress', compress)
  const urlRes = await axios.post(baseUrl + `/upload`, formData)
  console.log(urlRes.data)
  if (urlRes && urlRes.data) return urlRes.data.url
  throw(1)
}
export const uploadFilesAndGetUrl = async (files, compress) =>  {
  const promises = files.map(file => uploadFile(file, compress))
  return await Promise.all(promises)
}

const defaultUrl = {
  image: 'http://res.cloudinary.com/cokitchen/image/upload/v1656488793/sraln3sthyxj9qbgcohb.jpg',
}

export async function fetchDefaultPhoto(imageTag, setter) {
    setter(defaultUrl[imageTag])
}
