import { el } from "date-fns/locale";
import React, { useState, CSSProperties, useEffect } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";

import "./styles.scss";
import { CSV_TITLES_NEW } from "./utils";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

const CsvParserNew = ({
  setCsvData,
  setCsvMeals,
  setCsvAddons,
  setTitles,
  setCsvImagesFound,
  csvImages,
}) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [meals, setMeals] = useState([]);
  const [addons, setAddons] = useState([]);

  const [mealsWIthaddons, setMealsWIthAddons] = useState([]);
  const [csvType, setCsvType] = useState("");
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  let numberString = CSV_TITLES_NEW?.numberString;
  let nameString = CSV_TITLES_NEW?.nameString;
  let prepString = CSV_TITLES_NEW?.prepString;
  let calorieString = CSV_TITLES_NEW?.calorieString;
  let categoryString = CSV_TITLES_NEW?.categoryString;
  let rateString = CSV_TITLES_NEW?.rateString;
  let descString = CSV_TITLES_NEW?.descString;
  let imageString = CSV_TITLES_NEW?.imageString;

  useEffect(() => {
    //csvImages = csvImages.map(item=>item);
    sortItemImages(csvImages.map((item) => item));
  }, [csvImages]);

  const collectCsvData = (items) => {
    const titles = items[0];
    if (titles?.includes("Associated") || titles?.includes("Addon Name")) {
      setCsvType("addon");
      return sortAddonCsvData(items);
    } else {
      setCsvType("meal");
      return sortCsvData(items);
    }
  };
  const sortCsvData = (items) => {
    let sortedData = [];
    let titles = items[0];
    items?.shift();

    sortedData = items?.reduce((acc, curr) => {
      let item = {};
      for (let index = 0; index < curr.length; index++) {
        const isNumber =
          titles[index]?.toUpperCase() === numberString?.toUpperCase();
        const isPrep =
          titles[index]?.toUpperCase() === prepString?.toUpperCase();
        const isName =
          titles[index]?.toUpperCase() === nameString?.toUpperCase();
        const isCategory =
          titles[index]?.toUpperCase() === categoryString?.toUpperCase();
        const isRate =
          titles[index]?.toUpperCase() === rateString?.toUpperCase();
        const isDesc =
          titles[index]?.toUpperCase() === descString?.toUpperCase();
        const isImage =
          titles[index]?.toUpperCase() === imageString?.toUpperCase();
        const isCalorie =
          titles[index]?.toUpperCase() === calorieString?.toUpperCase();
        if (
          isNumber ||
          isPrep ||
          isName ||
          isCategory ||
          isRate ||
          isImage ||
          isDesc ||
          isCalorie
        ) {
          item[titles[index]] =
            curr[index] === "TRUE"
              ? true
              : curr[index] === "FALSE"
              ? false
              : curr[index];
        }
        item["placeholder"] = "";
      }
      delete item[numberString];
      return [...acc, item];
    }, []);
    titles?.shift();
    setTitles([...titles, "placeholder", "addons"]);
    console.log(sortedData);
    setMeals(sortedData);
    setCsvMeals(sortedData);
    sortItemImages(csvImages, sortedData);
    console.log(csvImages);
    let mealsAddons = sortFullMealData(addons, sortedData);
    return mealsAddons;
  };

  // For addon sortAddonCsvData

  const sortAddonCsvData = (items) => {
    let sortedData = [];
    let titles = items?.shift();
    //setTitles(titles);

    sortedData = items?.reduce((acc, curr) => {
      let item = {};
      for (let index = 0; index < curr.length; index++) {
        let isPrep = titles[index]?.toUpperCase() === prepString?.toUpperCase();
        let isName = titles[index]?.toUpperCase() === nameString?.toUpperCase();
        let isCategory =
          titles[index]?.toUpperCase() === categoryString?.toUpperCase();
        let isRate = titles[index]?.toUpperCase() === rateString?.toUpperCase();
        let isDesc = titles[index]?.toUpperCase() === descString?.toUpperCase();
        let isImage =
          titles[index]?.toUpperCase() === imageString?.toUpperCase();
        let isCalorie =
          titles[index]?.toUpperCase() === calorieString?.toUpperCase();

        if (curr[index]) {
          item[titles[index]] =
            curr[index] === "TRUE"
              ? true
              : curr[index] === "FALSE"
              ? false
              : curr[index];
        }
      }
      return [...acc, item];
    }, []);
    setAddons(sortedData);
    setCsvAddons(sortedData);

    let mealsAddons = sortFullMealData(sortedData, meals);
    return mealsAddons;
  };
  const sortItemImages = (items, allMeals = meals) => {
    const findMatchedImage = (meal) => {
      return items?.find((img) => img?.name === meal);
    };
    const imagesAddedtoMealsData = allMeals.map((item) => {
      const itmImg = item[imageString];
      const matchedItmIng = findMatchedImage(itmImg) || itmImg;
      console.log(matchedItmIng);
      return { ...item, [imageString]: matchedItmIng };
    });
    const itemsWithImage = imagesAddedtoMealsData.filter(
      (item) => item[imageString]?.name
    );
    console.log(imagesAddedtoMealsData);
    setMeals(imagesAddedtoMealsData);
    setCsvMeals(imagesAddedtoMealsData);
    setCsvData(imagesAddedtoMealsData);
    setCsvImagesFound(itemsWithImage);
  };

  const sortFullMealData = (sortedData = [], allMeals = []) => {
    console.log("sortedData", sortedData);
    let mealsAddons = allMeals?.map((ele) => {
      let mealAddons = sortedData?.filter((itm) => {
        let itmKeys = Object.keys(itm)
          ?.map((elll) => elll?.toLowerCase())
          ?.filter((str) => str.includes("associated"));

        return (
          Object.values(itm)
            ?.map((ell) => ell?.toLowerCase())
            ?.includes(ele[nameString]?.toLowerCase()) &&
          itmKeys &&
          itmKeys[0]
        );
      });

      // let mealAddons=  sortedData?.filter(
      //   itm=>{
      //     console.log("Object.values(itm)",Object.values(itm),"sortedData",sortedData)
      //     let itmVals=Object.values(itm)?.map(elll=>elll?.toLowerCase());
      //   return ( Object.keys(itm)?.map((ell,ind)=>ell?.toLowerCase())?.includes("associated")&& (itmVals?.includes (ele[nameString]?.toLowerCase() )))
      //   }
      //   )
      //   mealAddons=mealAddons?.map(mealAddon=>
      //     {
      //       Object.keys(mealAddon).forEach((key, index) => {
      //         if (key?.toLowerCase()?.includes("associated")) {
      //           if (mealAddon[key]?.toLowerCase()!== ele[nameString]?.toLowerCase()) {
      //             delete mealAddon[key]
      //           }

      //         }

      //     });
      // return({...mealAddon})
      //   })
      return { ...ele, addons: mealAddons };
    });
    setMealsWIthAddons(mealsAddons);
    console.log("all meals for full meal", mealsAddons);
    return mealsAddons;
  };
  return (
    <div>
      <div className="file-progress-bar-container width_full mb_25">
        <div
          style={{ background: "#2699FB" }}
          className={`width_full file-progress-bar ${
            meals?.length > 0 && addons?.length > 0
              ? "full"
              : meals?.length > 0 || addons?.length > 0
              ? "half"
              : "empty"
          }`}
        />
        <div
          className="width_full mt_10"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {meals?.length > 0 && <span className="normal_14px">Menu items</span>}
          {addons?.length > 0 && (
            <span className="normal_14px">Addon Mapping</span>
          )}
        </div>
      </div>
      <CSVReader
        onUploadAccepted={(results) => {
          let sortedData = collectCsvData(results?.data);
          setCsvData(sortedData);
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }) => (
          <>
            <div
              className="cursor_pointer mb_30"
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                "Drop CSV file here or click to upload"
              )}
            </div>
          </>
        )}
      </CSVReader>
    </div>
  );
};

export default CsvParserNew;
