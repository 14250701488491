import React, { useState } from "react";
import { Button } from "../../components/buttons";
import ImageFile from "../../components/imageFile";
import Input from "../../components/inputs";
import AppSelect from "../../components/select";
import CommonStore from "../../stores/dataStore/commonStore";
import axios from "axios";
import Modal from "../../components/modal";
import { CheckBox } from "../../components/checkBoxes";
import { SketchPicker } from "react-color";
import { useEffect } from "react";
import { uploadFilesAndGetUrl } from "../../helpers/upload";

const CreateFeed = ({
	showCreateFeed,
	setShowCreateFeed,
	setCurrentBrands,
	alert,
	currentBrands,
	cokitchens,
}) => {
	const emptyCreateForm = {
		name: "",
		description: "",
		item_type: "",
		position: "",
		short_text: "",
		summary: "",
		pop_up: false,
		popup_color: "",
		cokitchen_id: "",
	};
	console.log(cokitchens);
	const [submitting, setSubmitting] = useState(false);
	const [form, setForm] = useState({ ...emptyCreateForm });
	const [itemType, setItemType] = useState("FOOD");
	const [imageDisplay, setImageDisplay] = useState(null);
	const [brandImage, setBrandImage] = useState(null);
	const [logoDisplay, setLogoDisplay] = useState(null);
	const [brandLogo, setBrandLogo] = useState(null);
	const [showPallete, setShowPallete] = useState(false);
	const [cokitchenOptions, setCokitchenOptions] = useState([]);
	const [brand, setBrand] = useState([]);

	useEffect(() => {
		feedOptions();
	}, [cokitchens]);

	const onImageChange = async (e) => {
		setBrandImage(e);
		e && setImageDisplay(URL.createObjectURL(e));
	};

	const onLogoChange = async (e) => {
		setBrandLogo(e);
		e && setLogoDisplay(URL.createObjectURL(e));
	};
	const createFeed = async () => {
		setSubmitting(true);
		const { createShopFeed } = CommonStore;

		let url;
    if (brandImage) {
      const imageUrls = await uploadFilesAndGetUrl([brandImage], true)
      url = imageUrls[0]
    }
    let logoUrl;
    if (brandLogo) {
      const imageUrls = await uploadFilesAndGetUrl([brandLogo], false)
      logoUrl = imageUrls[0]
      setLogoDisplay(logoUrl);
    }
		let data = {
			name: form?.name,
			description: form?.description,
			cokitchen_id: form?.cokitchen_id,
			item_type: itemType?.value,
			logo: logoUrl || logoDisplay,
			images: (url && [url]) || [imageDisplay],
			position: form?.position.toString(),
			short_text: form?.short_text,
			summary: form?.summary,
			pop_up: form?.pop_up,
			popup_color: form?.popup_color,
		};
		await createShopFeed(alert, data);
		setSubmitting(false);
		setCurrentBrands([...currentBrands, data]);
		setForm({ ...emptyCreateForm });
		setImageDisplay("");
		setLogoDisplay("");
		setShowCreateFeed(!showCreateFeed);
	};

	const feedOptions = () => {
		let i;
		let options = [];
		let curr = cokitchens || [];
		for (i = 0; i < curr.length; i++) {
			options.push({ ...curr[i], label: curr[i].name, value: curr[i].id });
		}
		setCokitchenOptions(options);
	};

	return (
		<Modal
			noSave
			showModal={showCreateFeed}
			closeModal={() => setShowCreateFeed(!showCreateFeed)}
			title={"Create Feed"}
			customContent={
				<div className="flex_column_center">
					<AppSelect
						placeholder="Select CoKitchen"
						defaultValue={brand}
						handleChange={(e) => {
							setForm({ ...form, cokitchen_id: e?.id });
							console.log(form?.cokitchen_id);
						}}
						containerClass="width_full "
						options={cokitchenOptions}
					/>
					<Input
						placeholder="Brand Name"
						value={form?.name}
						onChange={(e) => setForm({ ...form, name: e })}
						required
						containerClass="width_full mb_35"
					/>

					<AppSelect
						placeholder="Item Type"
						defaultValue={itemType}
						handleChange={(e) => setItemType(e)}
						containerClass="width_full "
						options={[
							{ label: "FOOD", value: "FOOD" },
							{ label: "SHOP", value: "SHOP" },
						]}
					/>
					<Input
						type={"number"}
						placeholder="Brand Position"
						value={form?.position}
						onChange={(e) => setForm({ ...form, position: e })}
						required
						containerClass="width_full mb_35"
					/>
					<Input
						placeholder="Brand summary"
						value={form?.summary}
						onChange={(e) => setForm({ ...form, summary: e })}
						required
						containerClass="width_full mb_35"
					/>
					<Input
						placeholder="Short Text"
						value={form?.short_text}
						onChange={(e) => setForm({ ...form, short_text: e })}
						containerClass="width_full mb_35"
						maxLength="15"
					/>
					<div className="flex_row_left mb_25 width_full">
						<CheckBox
							id="pop-up"
							label={`Pop Up brand ?`}
							checked={form?.pop_up}
							onClick={() => {
								setForm({
									...form,
									pop_up: !form?.pop_up,
								});
							}}
						/>
					</div>

					{form?.pop_up && (
						<div className="d_flex flex_row_between width_full ">
							<Input
								placeholder="Enter popup color text"
								value={form?.popup_color}
								onChange={(e) => {
									setForm({ ...form, popup_color: e });
								}}
								containerClass="mb_15 width_full"
								label="Popup color code(HEX)"
							/>
							<span
								className=" open_pallete "
								onClick={() => setShowPallete(!showPallete)}
							>
								{!showPallete ? "Open Color Pallete" : "Close Color Palette"}
							</span>
						</div>
					)}
					{showPallete && (
						<SketchPicker
							className="mb_15"
							color={form?.popup_color}
							onChangeComplete={(color) => {
								setForm({
									...form,
									popup_color: color?.hex,
								});
							}}
						/>
					)}
					<div className="flex_row_left width_full mb_35">
						<div className="flex_column_left mr_25">
							<ImageFile
								onChange={(e) => {
									onImageChange(e.target.files[0]);
								}}
								src={imageDisplay}
								text="Upload Image"
							/>
							<label className="gray_label mt_10">Main brand image:</label>
						</div>
						<div className="flex_column_left">
							<ImageFile
								onChange={(e) => {
									onLogoChange(e.target.files[0]);
								}}
								src={logoDisplay}
								text="Upload logo"
							/>
							<label className="mt_10 gray_label">Brand logo:</label>
						</div>
					</div>

					<textarea
						className="text_area text_area_short mb_35"
						placeholder="Full brand description"
						value={form?.description}
						onChange={(e) => {
							setForm({ ...form, description: e.target.value });
						}}
						required
					/>

					<div className="d_flex flex_row_between">
						<Button
							type="button"
							onClick={createFeed}
							text={submitting ? "Creating..." : "Create Feed"}
							loading={submitting}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default CreateFeed;
