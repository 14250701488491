import React, { useState ,CSSProperties} from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import BrandStore from "../../stores/dataStore/brandInfoStore";
import  MealCategoryStore  from "../../stores/dataStore/mealCategoryStore";

import "./styles.scss";

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const CsvParser = ({
 
  setCsvData,
  isAddon,
  setTitles,
  
}) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  let newString="Meal is new";
  let brandString="brand";
  let categoryString="Category Name";
  let addonString="Meal is addon";
  let parentString="Meal parents and addon categories";

  
const sortCsvData=(items)=>{
  console.log("BrandStore.brandsAlt",BrandStore.brandsAlt)
let sortedData=[];
let titles=items?.shift();
setTitles(titles);

sortedData= items?.reduce((acc,curr)=>{
  let item={};
  let currentBrand;
for (let index = 0; index < curr.length; index++) {
let isBrand= titles[index]?.toUpperCase()===brandString?.toUpperCase();
//console.log("Is brand",isBrand )
let isMealCategory= titles[index]?.toUpperCase()===categoryString?.toUpperCase();
currentBrand= isBrand ?BrandStore.brandsAlt?.find(el=>el?.name?.toUpperCase()===curr[index]?.toUpperCase()):currentBrand;
currentBrand&&console.log("currentBrand",currentBrand);
let mealCategory= isMealCategory? currentBrand?.meal_categories?.find(el=>curr[index]?.toUpperCase()===el?.name?.toUpperCase()):null;
isMealCategory && console.log("currentBrand for category", currentBrand);

item[titles[index]]= 
curr[index]==="TRUE"?true:
curr[index]==="FALSE"?false:
isBrand?{
  id: currentBrand?.id,
  name: currentBrand?.name,
  meal_categories: currentBrand?.meal_categories}:
isMealCategory?mealCategory:
curr[index];
}
return [...acc, item]
},[])
console.log("sortedData",sortedData)
return sortedData
}
const mergeMealCategories=()=>{
  let i;
  let options = [];
  let curr = MealCategoryStore.mealCategories || [];
  for (i = 0; i < curr.length; i++) {
    options= [...options, ...curr[i]?.meals ]
   
  }

  console.log("mergeMealCategories",options)
  return options
}

// Extract parent meal and meal addon categories from ["Meal parents and addon categories"] string
const extractParent=(str)=>{

let strArr= str?.split("|");
let newStrArr=[];

for (let index = 0; index < strArr.length; index++) {
let mealObj={}
let singleStrArr=  strArr[index]?.split(":");
mealObj.parent_meal=singleStrArr[0]?.trim();
mealObj.addon_categories=singleStrArr[1]?.split(",")
?.map((item,ind,itms)=>item?.trim()
?.replace("(","")?.replace(")","")?.trim());
newStrArr=[...newStrArr,mealObj ]
}
return( newStrArr)
}
// For addon sortAddonCsvData

const sortAddonCsvData=(items)=>{
  console.log("BrandStore.brandsAlt",BrandStore.brandsAlt)
let sortedData=[];
let titles=items?.shift();
setTitles([...titles,"Parent Meals"]);
let brandsAddonMeals=mergeMealCategories()
sortedData= items?.reduce((acc,curr)=>{
  let item={};
  let currentBrand;
  let currentMealCategory;
  let currentParentMeals;
  let parentDetails;
  let activeMealsAddonMatches;
  let currentParentMealsInAddonMeals;

for (let index = 0; index < curr.length; index++) {
let isBrand= titles[index]?.toUpperCase()===brandString?.toUpperCase();
let isMealCategory= titles[index]?.toUpperCase()===categoryString?.toUpperCase();
let isParentMeal= titles[index]?.toUpperCase()===parentString?.toUpperCase();
currentBrand= isBrand ?BrandStore.brandsAlt?.find(el=>el?.name?.toUpperCase()===curr[index]?.toUpperCase()):currentBrand;
// currentBrand&&console.log("currentBrand",currentBrand);
 currentMealCategory= isMealCategory? currentBrand?.meal_categories?.find(el=>curr[index]?.toUpperCase()===el?.name?.toUpperCase()):currentMealCategory;
// isMealCategory && console.log("currentMealCategory", currentMealCategory);
 parentDetails= isParentMeal &&extractParent(curr[index])||parentDetails;
 currentParentMeals=  currentMealCategory?.meals?.filter(item=>parentDetails?.find(el=>el?.parent_meal?.toUpperCase()===item?.name?.toUpperCase() ));
 currentParentMealsInAddonMeals= brandsAddonMeals?.filter(item=> parentDetails?.find(el=>el?.parent_meal?.toUpperCase()===item?.name?.toUpperCase() ) );
 

  console.log(
    "parentDetails",parentDetails, "currentParentMeals",
    currentParentMeals,"currentParentMealsInAddonMeals",currentParentMealsInAddonMeals);
item[titles[index]]= 
curr[index]==="TRUE"?true:
curr[index]==="FALSE"?false:
isBrand?{
  id: currentBrand?.id,
  name: currentBrand?.name,
  meal_categories: currentBrand?.meal_categories}:
isMealCategory?currentMealCategory:
curr[index];

if(isParentMeal)
{
  item["Parent Meals"]= {parentMeals: currentParentMealsInAddonMeals, parentObject:parentDetails }
}
}
return [...acc, item]
},[])
console.log("sortedData",sortedData)

return sortedData
}
  return (
    <CSVReader
    onUploadAccepted={(results) => {
      console.log('---------------------------');
      console.log(results);
      console.log('---------------------------');
      let sortedData =isAddon?sortAddonCsvData(results?.data): sortCsvData(results?.data)
      setCsvData(sortedData)
      setZoneHover(false);
    }}
    onDragOver={(event) => {
      event.preventDefault();
      setZoneHover(true);
    }}
    onDragLeave={(event) => {
      event.preventDefault();
      setZoneHover(false);
    }}
  >
    {({
      getRootProps,
      acceptedFile,
      ProgressBar,
      getRemoveFileProps,
      Remove,
    }) => (
      <>
        <div
        className="cursor_pointer mb_30"
          {...getRootProps()}
          style={Object.assign(
            {},
            styles.zone,
            zoneHover && styles.zoneHover
          )}
        >
          {acceptedFile ? (
            <>
              <div style={styles.file}>
                <div style={styles.info}>
                  <span style={styles.size}>
                    {formatFileSize(acceptedFile.size)}
                  </span>
                  <span style={styles.name}>{acceptedFile.name}</span>
                </div>
                <div style={styles.progressBar}>
                  <ProgressBar />
                </div>
                <div
                  {...getRemoveFileProps()}
                  style={styles.remove}
                  onMouseOver={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                  }}
                  onMouseOut={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                  }}
                >
                  <Remove color={removeHoverColor} />
                </div>
              </div>
            </>
          ) : (
            'Drop CSV file here or click to upload'
          )}
        </div>
      </>
    )}
  </CSVReader>
  );
};

export default CsvParser;
