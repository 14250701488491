
import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
  BRAND_INFO_URL,
  GET_BRANDS_URL,
  MEAL_INFO_URL,
  MULTIPLE_MEAL_URL,
  DELETE_ADDON_CATEGORIES,
  GET_MEALS_,
  POST_BRAND_WORKING_HOUR_URL,
  GET_BRAND_WORKING_HOUR_URL,
  GET_MEAL_CATEGORIES_URL,
  GET_MEAL_WORKING_HOUR_URL,
  POST_MEAL_WORKING_HOUR_URL,
  CREATE_MULTIPLE_ADDONS,
  DUPLICATE_ADDON_CATEGORIES,
  DELETE_MEAL_URL,
  CREATE_BULK_MEALS_URL,
  DUPLICATE_ADDON_CATEGORY,
  GET_ADDONS_CATEGORIES,
  CREATE_CATEGORIES_ADDONS
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class BrandStore {
  @observable brands = [];
  @observable mealsOnly = [];
  @observable addonsOnly = [];
  @observable brandsAlt = [];
  @observable workingHours = [];
  @observable workingMealHours = [];
  @observable mealAddonCategories = [];
  @observable dataCached = false;
  @observable dataAltCached = false;

  @action updateWorkingHrs = (item) => {
    this.workingHours = item;
    return item;
  };
  @action updateMealWorkingHrs = (item) => {
    this.workingMealHours = item;
    return item;
  };

  getBrands = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_BRANDS_URL);

      runInAction(() => {
        let res = response.data && response.data.data;
        this.brands = res;
        alert.show("Brands loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  getMealsOnly = async (alert, id) => {
    try {
      let response = await dataService.getInternalData(`internal/brand/meals/${id}`);

      runInAction(() => {
        let res = response && response.data && response.data.meals
        this.mealsOnly = res;


        alert.show("Meals loaded successfully!", {

          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getAddonsOnly = async (alert, id) => {
    try {
      let response = await dataService.getInternalData(`brand/addons/${id}`);

      runInAction(() => {
        let res = response 
        this.addonsOnly =  response && response.data && response.data.meals;

        alert.show("Addons loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };



  getBrandsAlt = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_MEAL_CATEGORIES_URL);

      runInAction(() => {
        let res = response.data && response.data.data;
        this.brandsAlt = res;
        alert.show("Brands loaded successfully!", {
          type: "success",
        });

        this.dataAltCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createBrands = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(BRAND_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand created successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  updateBrands = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(BRAND_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  deleteBrands = async (alert, id) => {
    try {
      let response = await dataService.deleteAuthData(
        `${BRAND_INFO_URL}/${id}`
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand deleted successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateMeal = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(MEAL_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  getAddonCategories = async (alert, data) => {
    try {
      let response = await dataService.postInternalData(GET_ADDONS_CATEGORIES, data);

      runInAction(() => {
        let res = response && response.data.data
        this.mealAddonCategories = res;
        // alert.show("Addon Categories loaded successfully!", {
        //   type: "success",
        // });

       
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  createAddonCategoriesandAddons = async (alert, data) => {
    try {
      let response = await dataService.postInternalData(CREATE_CATEGORIES_ADDONS, data);

      runInAction(() => {
        let res = response && response.data.data
       
         alert.show("Addon created successfully!", {
           type: "success",
         });

       
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createMeal = async (alert, data, reRoute) => {
    try {
      let response = await dataService.postAuthData(MEAL_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal created successfully!", {
            type: "success",
          });

          reRoute();
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createMealforMultipleBrands = async (alert, data, reRoute) => {
    try {
      let response = await dataService.postAuthData(MULTIPLE_MEAL_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal created successfully!", {
            type: "success",
          });

          reRoute();
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createMultipleAddonCategories = async (alert, data, ) => {
    try {
      let response = await dataService.postAuthData(CREATE_MULTIPLE_ADDONS, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Addon created successfully!", {
            type: "success",
          });
          
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
 duplicateAddonCategory = async (alert, data, ) => {
    try {
      let response = await dataService.postAuthData(DUPLICATE_ADDON_CATEGORIES, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Addon created successfully!", {
            type: "success",
          });
          
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  deleteAddonCategories = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(DELETE_ADDON_CATEGORIES, data);

      runInAction(() => {
        if (response ) {
          alert.show("Meal addon category deleted successfully!", {
            type: "success",
          });

       
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createBulkMeal = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        CREATE_BULK_MEALS_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meals created successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert, "is_bulk");
    }
  };

  duplicateAddonCat = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        DUPLICATE_ADDON_CATEGORY,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meals addon category duplicated successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  // Working hours
  getWorkingHrs = async (alert, id) => {
    try {
      let response = await dataService.getAuthData(
        GET_BRAND_WORKING_HOUR_URL + id
      );
      let res;
      runInAction(() => {
        res = response.data && response.data.data;
        this.workingHours = res;

        this.dataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Post
  postWorkingHrs = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        POST_BRAND_WORKING_HOUR_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          this.dataCached = true;
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Working hours
  getMealWorkingHrs = async (alert, id) => {
    try {
      let response = await dataService.getAuthData(
        GET_MEAL_WORKING_HOUR_URL + id
      );
      let res;
      runInAction(() => {
        res = response.data && response.data.data;
        this.workingMealHours = res;

        this.dataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Post
  postMealWorkingHrs = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        POST_MEAL_WORKING_HOUR_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          this.dataCached = true;
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteMeals = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(
        `${DELETE_MEAL_URL}/${id}`
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal deleted successfully!", {
            type: "success",
          });
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new BrandStore();
