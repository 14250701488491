import React from "react";
import "./styles.scss";
import { BsFillOctagonFill } from "react-icons/bs";

const PolygonItem = ({ activeItem, polygonText, onClick, fill = 'purple ' }) => {
  return (
    <div
      className="polygon_item"
    >
      <span className="mr_15">
        <BsFillOctagonFill
          width="12px"
          height="12px"
          fill={fill}
        />
      </span>
      <label className={`${activeItem ? "bold_18_px" : "gray_label_sec"}`}>
        {polygonText}
      </label>
      <p className="edit-txt" onClick={onClick}
      >Edit</p>
    </div>
  );
};

export default PolygonItem;
