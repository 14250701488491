import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import { DateRangePicker } from "react-date-range";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import Prompt from "../../components/modal/prompt";
import { Button } from "../../components/buttons";
import Input from "../../components/inputs";
import Select from "../../components/select";
import IconInput from "../../components/inputs/iconInput";
import { BsSearch } from "react-icons/bs";
import CommonStore from "../../stores/dataStore/commonStore";
import moment from "moment";
import Loader from "react-loader-spinner";
import commonStore from "../../stores/dataStore/commonStore";
import { CheckBox } from "../../components/checkBoxes";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import { useHistory } from "react-router-dom";
import brandInfoStore from "../../stores/dataStore/brandInfoStore";
import { uploadFilesAndGetUrl } from "../../helpers/upload";

const MealCategories = ({ alert, ...props }) => {
  const path = props.match.params.type;
  const history = useHistory();
  const [currentData, setCurrentData] = useState("");
  const [currentDataCopy, setCurrentDataCopy] = useState("");
  const [currentMeallAddonCat, setCurrentMeallAddonCat] = useState(null);
  const [brand, setBrand] = useState({ id: "All", name: "All Brands" });
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [showAddon, setShowAddon] = useState(true);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [mealCategorySelectionType, setMealCategorySelectionType] = useState();
  const [brandmealCategories, setBrandMealCategories] = useState([]);
  const [brandmealCategoriesAlt, setBrandMealCategoriesAlt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicateAddonData, setDuplicateAddonData] = useState([]);
  const [currentMealCat, setCurrentMealCat] = useState([]);
  const [currentBrandName, setCurrentBrandName] = useState([]);
  const [currentMealCategories, setCurrentMealCategories] = useState([]);
  const [mealCategoriesId, setMealCategoriesId] = useState([]);

  const [ckLoading, setCkLoading] = useState(false);

  const [cokitchens, setCokitchens] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsAlt, setBrandsAlt] = useState([]);
  const [showSide, setShowSide] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const [showPrompt, setShowPrompt] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  //const [ratingsHidden, setRatingsHidden] = useState(false);
  const [pageWidth, setPageWidth] = useState("");

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [submitting, setSubmitting] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);

  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  const [supermealCategorySelections, setSupermealCategorySelections] =
    useState([]);
  const [defaultMeal, setdefaultMeal] = useState("");
  const [defaultMealCategory, setdefaultMealCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [adding, setAdding] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [duplicateForm, setDuplicateForm] = useState({
    meal_category_ids: [],
    brand_id: "",
    meal_addon_category_id: "",
  });

  const [currentMealBrand, setCurrentMealBrand] = useState([]);

  const [form, setForm] = useState({
    name: "",
    summary: "",
    brand_id: "",
    meal_category_selection_type_id: "",
    super_meal_category_id: "",
    selection_no: "",
    min_selection_no: "",
    is_addon: false,
    meal_category_id: "",
  });
  const [formTwo, setFormTwo] = useState({
    name: "",
    brand_id: "",
  });
  const [SelectedParentMeals, setSelectedParentMeals] = useState([]);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  useEffect(() => {
    console.log(duplicateAddonData);

    let currentMealCat = duplicateAddonData?.meal_category;
    let currentMealBrandId = duplicateAddonData?.brand_id;
    setCurrentMealBrand(currentMealBrandId);

    setCurrentMealCat(currentMealCat);
    let Brand = BrandStore.brandsAlt?.find(
      (item) => item.id === currentMealBrand
    );
    setCurrentBrandName(Brand);

    let active = BrandStore.brandsAlt?.find(
      (item) => item.id === duplicateAddonData?.brand_id
    );
    let activeMeals =
      active?.meal_categories?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      ) || [];
    setCurrentMealCategories(activeMeals);
    console.log(currentMealCategories);

    let mealCatIds = currentMealCategories.map((item, index) => {
      return item.id;
    });
    console.log(mealCatIds);
    setMealCategoriesId(mealCatIds);
    setDuplicateForm({ ...duplicateForm, meal_category_ids: mealCategoriesId });
    console.log(duplicateForm);
  }, [duplicateAddonData]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);

    if (
      !CommonStore.dataCached &&
      !BrandStore.dataAltCached &&
      !MealCategoryStore.dataCached
    ) {
      await Promise.all([
        CommonStore.getCokitchens(alert),
        BrandStore.getBrandsAlt(alert),
        MealCategoryStore.getMealCategories(alert, true),
        MealCategoryStore.getSuperMealCategories(alert, true),
        MealCategoryStore.getMealCategorySelection(alert),
      ]);
    } else {
      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchens(alert);
        setCokitchens(CommonStore.cokitchens);
        setCkLoading(true);
      }

      if (!MealCategoryStore.dataCached) {
        await Promise.all([
          MealCategoryStore.getMealCategories(alert, true),
          MealCategoryStore.getSuperMealCategories(alert, true),
          MealCategoryStore.getMealCategorySelection(alert),
        ]);

        setBrands(MealCategoryStore.mealCategories);
        setMealCategoryTypesOptions();
        setSuperMealCategoryTypesOptions();
      }

      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrandsAlt(BrandStore.brandsAlt);
      }
    }

    setLoading(false);
    setCkLoading(false);
    setCurrentCokitchen({
      name: "All users (all locations)",
      brands: CommonStore.cokitchens?.reduce(
        (accumulator, obj) => [...accumulator, ...obj?.brands],
        []
      ),
    });

    setCokitchens(CommonStore.cokitchens);
    setBrands(MealCategoryStore.mealCategories);
    setMealCategoryTypesOptions();
    setSuperMealCategoryTypesOptions();
  }, []);
  // Set brand options based on current cokitchen
  useEffect(async () => {
    let i;
    let options =
      currentCokitchen?.name === "All users (all locations)"
        ? [{ id: "All", name: "All Brands" }]
        : [];
    let curr = currentCokitchen?.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        name: curr[i].name + ` (${curr[i]?.meals?.length || 0})`,
        id: curr[i].id,
      });
    }
    setCurrentBrands(options);
    setBrand((options && options[0]) || "");
  }, [currentCokitchen]);

  // FInd currently selected brands's meal categories
  useEffect(async () => {
    findCurrentBrandMealCategories();
    setForm({
      ...form,
      meal_category_id: "",
      meal_category_selection_type_id: "",
      selection_no: "",
      min_selection_no: "",
    });
  }, [form?.brand_id, form?.is_addon]);

  useEffect(async () => {
    findCurrentBrandMealCategoriesAlt();
  }, [currentData, currentMeallAddonCat]);

  useEffect(() => {
    if (path === "new-meal-category") {
      setShowCreateModal(true);
    }
  }, []);

  const duplicateFormUpdate = async () => {
    setSubmitting(true);
    const { duplicateAddonCat } = brandInfoStore;

    let body = duplicateForm;
    console.log(body);
    if (!body?.brand_id) {
      alert.show(" is required!", {
        type: "info",
      });
    } else {
      try {
        let res;
        console.log(body);
        res = await duplicateAddonCat(alert, body);
        if (res) {
          alert.show("Fetching updates...", {
            type: "info",
          });
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setSubmitting(false);
      }
    }
  };
  useEffect(() => {
    setDuplicateForm({
      ...duplicateForm,
      meal_addon_category_id: currentMeallAddonCat?.id,
    });
  }, [currentMeallAddonCat]);

  const formUpdate = async () => {
    const { updateMealCategory } = MealCategoryStore;
    const { getCokitchens } = commonStore;
    setSubmitting(true);
    let url =
      (currentData && currentData.images && currentData.images[0]) || "";
    if (image) {
      try {
        const imageUrls = await uploadFilesAndGetUrl([image], true);
        const url = imageUrls[0];
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );

        setSubmitting(false);
      }
    }

    let body = {
      meal_addon_category_id: showAddon ? currentMeallAddonCat?.id : "",
      meal_category_id: showAddon
        ? currentMeallAddonCat?.meal_category_id || ""
        : currentData?.meal_category_id,
      summary: showAddon
        ? currentMeallAddonCat?.summary
        : currentData?.meal_category?.summary,
      meal_category_selection_type_id: showAddon
        ? currentMeallAddonCat?.meal_category_selection_type?.id
        : mealCategorySelections[0].id,
      selection_no: showAddon ? currentMeallAddonCat?.selection_no || "" : "",
      min_selection_no: showAddon
        ? currentMeallAddonCat?.min_selection_no || ""
        : "",
      meal_default_id: showAddon
        ? currentMeallAddonCat?.meal_default_id || ""
        : "",
      is_addon: showAddon,
      name: showAddon
        ? currentMeallAddonCat?.name
        : currentData?.meal_category?.name,
    };
    Object.keys(body).forEach((key, index) => {
      if (!body[key] || body[key] == {}) {
        if (body !== "is_addon") delete body[key];
      }
    });

    await updateMealCategory(alert, body);
    setSubmitting(false);
    alert.show("Fetching updates...", {
      type: "info",
    });
    let dataRes = await MealCategoryStore.getMealCategories(alert, false);
    if (dataRes) {
      setBrands(dataRes);
    }
    setSubmitting(false);
    //    setShowModal(false);
  };
  // FInd currently selected brands's meal categories
  const findCurrentBrandMealCategories = () => {
    let active = BrandStore.brandsAlt?.find(
      (item) => item.id === form?.brand_id
    );
    let activeMeals =
      active?.meal_categories?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      ) || [];
    setBrandMealCategories(activeMeals);
  };

  // FInd currently selected meal category brand's meal categories
  const findCurrentBrandMealCategoriesAlt = () => {
    let active = BrandStore.brandsAlt?.find(
      (item) => item.id === currentData?.brand_id
    );
    let activeMeals =
      active?.meal_categories?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      ) || [];
    let activeMeal =
      activeMeals?.find((item) => item?.id === currentData?.meal_category_id) ||
      "";
    setBrandMealCategoriesAlt(activeMeals);
    setdefaultMealCategory(activeMeal);
  };

  // Sort setMealCategoryTypesOptions options for react select
  const setMealCategoryTypesOptions = () => {
    let opts = MealCategoryStore.mealCategoriesSelection;
    console.log(
      "MealCategoryStore.mealCategoriesSelection",
      MealCategoryStore.mealCategoriesSelection
    );
    setmealCategorySelections(opts);
  };

  // Sort setSuperMealCategoryTypesOptions options for react select
  const setSuperMealCategoryTypesOptions = () => {
    let opts = MealCategoryStore.superMealCategories;
    setSupermealCategorySelections(opts);
  };

  const onCardSelect = (i) => {
    let newArr = [...selectedBrands, i];
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedBrands(newArr);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };
  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  // Submit add form
  const formSubmit = async () => {
    const { getMealCategories, createMealCategory } = MealCategoryStore;
    let body = JSON.parse(JSON.stringify(form));
    if (!body?.brand_id) {
      alert.show("Brand is required!", {
        type: "info",
      });
    } else if (body?.is_addon && !body?.meal_category_selection_type_id) {
      alert.show("Meal category type is required!", {
        type: "info",
      });
    } else if (body?.is_addon && !body?.meal_category_id) {
      alert.show("Meal category is required!", {
        type: "info",
      });
    } else if (
      body?.is_addon &&
      body?.meal_parent_ids &&
      !body?.meal_parent_ids[0]
    ) {
      alert.show("Meal parent is required!", {
        type: "info",
      });
    } else if (
      body?.meal_category_selection_type_id &&
      !body?.meal_category_id
    ) {
      alert.show("Meal category type is required!", {
        type: "info",
      });
    }
    // else if (!body?.super_meal_category_id) {
    //   alert.show("Super meal category type is required!", {
    //     type: "info",
    //   });
    // }
    else {
      if (!body.is_addon) {
        body.meal_category_selection_type_id = mealCategorySelections[0]?.id;
      }
      Object.keys(body).forEach((key, index) => {
        if (!body[key] || body[key] == {}) {
          if (body !== "is_addon") delete body[key];
        }
      });

      try {
        setAdding(true);

        let res;

        res = await createMealCategory(alert, body);

        if (res) {
          setAdding(false);
          setShowCreateModal(false);

          alert.show("Fetching updates...", {
            type: "info",
          });

          await getMealCategories(alert, false);
          setBrands(MealCategoryStore.mealCategories);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();
      }
    }
  };

  // submit super meal category form

  const formTwoSubmit = async () => {
    const { getSuperMealCategories, createSuperMealCategory } =
      MealCategoryStore;
    let body = formTwo;
    if (!body?.brand_id) {
      alert.show("Brand is required!", {
        type: "info",
      });
    } else {
      try {
        setAdding(true);

        let res;

        res = await createSuperMealCategory(alert, body);

        if (res) {
          setAdding(false);
          setShowAddForm(false);

          alert.show("Fetching updates...", {
            type: "info",
          });

          await getSuperMealCategories(alert, false);
          setSuperMealCategoryTypesOptions();
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();
      }
    }
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab meal_categories="active_nav_item " />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Meal Categories</h6>
                <div className="flex_row_center">
                  <a
                    className="normal_18px mr_30"
                    href="#/"
                    onClick={() => {
                      setFormTwo({
                        name: "",
                        brand_id: "",
                      });
                      setShowAddForm(true);
                    }}
                  >
                    New super meal category
                  </a>

                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="New Meal Category"
                    onClick={() => {
                      setForm({
                        summary: "",
                        meal_category_selection_type_id: "",
                        super_meal_category_id: "",
                        selection_no: "",
                        min_selection_no: "",
                        brand_id: "",
                      });

                      setShowCreateModal(!showCreateModal);
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                      setCurrentCokitchen({
                        name: "All users (all locations)",
                        brands: CommonStore.cokitchens?.reduce(
                          (accumulator, obj) => [
                            ...accumulator,
                            ...obj?.brands,
                          ],
                          []
                        ),
                      })
                    }
                    activeItem={
                      currentCokitchen.name === "All users (all locations)"
                    }
                  />
                ) : null}
                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen.name === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section
              className="flex_column_left width_full"
              style={{ width: "100%" }}
            >
              <div className="flex_row_between width_full top_bar">
                <Select
                  placeholder="Select brand"
                  value={brand}
                  handleChange={(e) => setBrand(e)}
                  options={currentBrands?.filter(
                    (item) => item?.item_type === "FOOD"
                  )}
                  name="name"
                  id="id"
                />
                <IconInput
                  required
                  containerClass="ml_15 width_full"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e)}
                  icon={<BsSearch width="11px" height="11px" />}
                  placeholder="Search meal caterory"
                />
              </div>
              <div className="mb_25 flex_row_top width_full"></div>

              <div className="flex_row_top width_full mb_35 tab_column">
                <label className="bold_18_px">Meal Categories</label>

                {showDateRange && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                )}
              </div>
              {!loading && !brands ? (
                <label className="bold_24px gray">
                  There are no Meal Categories to show
                </label>
              ) : null}
              {brands &&
                brands
                  ?.filter((item) => item?.item_type === "FOOD")
                  .map((itm, i) => {
                    if (brand?.id === "All") {
                      return (
                        <div key={i + "item"} className="width_full mb_35">
                          <div className="bold_24px mb_15">
                            {itm.name} (
                            {
                              itm?.meals?.filter((item) => !item.is_addon)
                                .length
                            }{" "}
                            meals)
                          </div>
                          {itm &&
                            itm.meals
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .filter(
                                (item) =>
                                  item?.name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase()) &&
                                  !item.is_addon
                              )
                              .map((item, ind) => {
                                return (
                                  <TextCard
                                    key={item && item.id + ind}
                                    cardName={item && item.name}
                                    cardLabel={item && item.description && ""}
                                    cardTime={`Created on ${moment(
                                      item.created_at
                                    ).format("MMMM Do YYYY, h:mm:ss a")}`}
                                    onChange={() => onCardSelect(i)}
                                    onViewClick={async () => {
                                      setCurrentData(item);
                                      console.log(item);
                                      if (item && item.meal_default_id) {
                                        let currentDefault =
                                          await item.meals.filter(
                                            (itm) =>
                                              itm.id === item.meal_default_id
                                          );

                                        setdefaultMeal(
                                          currentDefault && currentDefault[0]
                                        );
                                      }
                                      setCurrentDataCopy(item);

                                      setShowModal(true);
                                    }}
                                    onDuplicateClick={async () => {
                                      setShowDuplicateModal(true);
                                      setDuplicateAddonData(item);
                                    }}
                                    viewText="Edit meal category >"
                                    duplicate
                                    headTippy
                                    duplicateText="Duplicate meal addon category> "
                                    withImage
                                    image={
                                      item && item.images && item.images[0]
                                    }
                                    className="image_text_card"
                                    cardBody={item && item.summary}
                                  />
                                );
                              })}
                        </div>
                      );
                    } else if (itm?.id === brand?.id && !itm.is_addon) {
                      return (
                        <div key={i + "item"} className="width_full">
                          <div className="bold_24px mb_15">
                            {itm.name} (
                            {
                              itm?.meals?.filter((item) => !item.is_addon)
                                .length
                            }{" "}
                            meals)
                          </div>
                          {itm &&
                            itm.meals
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .filter(
                                (item) =>
                                  item?.name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase()) &&
                                  !item.is_addon
                              )
                              .map((item, ind) => {
                                return (
                                  <TextCard
                                    key={item && item.id + ind}
                                    cardName={item && item.name}
                                    cardLabel={item && item.description && ""}
                                    cardTime={`Created on ${moment(
                                      item.created_at
                                    ).format("MMMM Do YYYY, h:mm:ss a")}`}
                                    onChange={() => onCardSelect(i)}
                                    onViewClick={async () => {
                                      setCurrentData(item);
                                      if (item && item.meal_default_id) {
                                        let currentDefault =
                                          await item.meals.filter(
                                            (itm) =>
                                              itm.id === item.meal_default_id
                                          );

                                        setdefaultMeal(
                                          currentDefault && currentDefault[0]
                                        );
                                      }
                                      setCurrentDataCopy(item);
                                      setShowModal(true);
                                    }}
                                    onDuplicateClick={async () => {
                                      setShowDuplicateModal(true);
                                      setDuplicateAddonData(item);
                                      console.log(duplicateAddonData);
                                    }}
                                    viewText="Edit meal category >"
                                    withImage
                                    duplicate
                                    duplicateText="Duplicate meal addon category> "
                                    headTippy
                                    image={
                                      item && item.images && item.images[0]
                                    }
                                    className="image_text_card"
                                    cardBody={item && item.summary}
                                  />
                                );
                              })}
                        </div>
                      );
                    }
                  })}

              <PageLoading loading={loading} />
            </section>
            <section className="desktop_visible"></section>
          </section>
        </section>
      </div>
      <Modal
        title={`Edit ${currentDataCopy && currentDataCopy.name}`}
        showModal={showModal}
        closeModal={() => {
          setShowModal(!showModal);
          setCurrentMeallAddonCat(null);
          setCurrentData(null);
          setdefaultMeal("");
        }}
        customContent={
          <div>
            <div className="flex_row_left ml_15 width_full">
              <CheckBox
                id="show_addon"
                label={
                  showAddon
                    ? "Swich to Meal category"
                    : "Swich to Addon categories"
                }
                checked={showAddon}
                onClick={() => {
                  setShowAddon(!showAddon);
                }}
              />
            </div>
            {showAddon ? (
              <Select
                placeholder="Select meal addon category to be updated"
                defaultValue={
                  currentData?.meal_category_selection_type?.id
                    ? {
                        id:
                          currentData &&
                          currentData.meal_category_selection_type &&
                          currentData.meal_category_selection_type.id,
                        name:
                          currentData &&
                          currentData.meal_category_selection_type &&
                          currentData.meal_category_selection_type.name,
                      }
                    : null
                }
                handleChange={(e) => {
                  setCurrentMeallAddonCat(e);
                  let currentDefault;

                  for (
                    let i = 0, val = e?.addons, valLen = e?.addons?.length;
                    i < valLen;
                    i++
                  ) {
                    if (e.meal_default_id === val[i]?.meal_data?.id) {
                      currentDefault = val[i];
                    }
                  }

                  setdefaultMeal(currentDefault);
                }}
                containerClass=""
                options={currentData?.meal_addon_categories}
                name="name"
                id="id"
              />
            ) : (
              <Input
                placeholder="Edit Meal Category Name"
                value={currentData?.meal_category?.name || ""}
                onChange={(e) => {
                  setCurrentData({
                    ...currentData,
                    meal_category: {
                      ...currentData.meal_category,
                      name: e,
                    },
                  });
                }}
                containerClass="mb_15 "
              />
            )}
            <Input
              placeholder="Edit Addon Category Name"
              value={currentMeallAddonCat?.name || ""}
              onChange={(e) => {
                setCurrentMeallAddonCat({
                  ...currentMeallAddonCat,
                  name: e,
                });
              }}
              containerClass="mb_15 "
            />
            <textarea
              className="text_area text_area_short mb_15"
              placeholder="Summary "
              value={
                showAddon
                  ? currentMeallAddonCat?.summary || ""
                  : currentData?.meal_category?.summary || ""
              }
              onChange={(e) => {
                if (showAddon) {
                  setCurrentMeallAddonCat({
                    ...currentMeallAddonCat,
                    summary: e.target.value,
                  });
                } else {
                  setCurrentData({
                    ...currentData,
                    meal_category: {
                      ...currentData.meal_category,
                      summary: e.target.value,
                    },
                  });
                }
              }}
            />
            {!showAddon ? (
              <div />
            ) : (
              <Select
                placeholder="Select meal category type"
                defaultValue={
                  currentMeallAddonCat?.meal_category_selection_type
                }
                value={currentMeallAddonCat?.meal_category_selection_type}
                handleChange={(e) => {
                  setCurrentMeallAddonCat({
                    ...currentMeallAddonCat,
                    meal_category_selection_type: e,
                  });
                }}
                containerClass=""
                options={mealCategorySelections}
                name="name"
                id="id"
              />
            )}
            {currentMeallAddonCat?.meal_category_selection_type?.name ===
              "MULTI_SELECTION" ||
            currentMeallAddonCat?.meal_category_selection_type?.name ===
              "MULTI_SELECTION_WITH_QUANTITY" ? (
              <div>
                <Input
                  placeholder="Selection Number (Optional)"
                  value={currentMeallAddonCat?.selection_no || ""}
                  onChange={(e) =>
                    setCurrentMeallAddonCat({
                      ...currentMeallAddonCat,
                      selection_no: e,
                    })
                  }
                  containerClass="mb_15 "
                />
                <Input
                  placeholder="Minimum Selection Number (Optional)"
                  value={currentMeallAddonCat?.min_selection_no || ""}
                  onChange={(e) =>
                    setCurrentMeallAddonCat({
                      ...currentMeallAddonCat,
                      min_selection_no: e,
                    })
                  }
                  containerClass="mb_15 "
                />
              </div>
            ) : null}

            {!showAddon ? (
              <div />
            ) : (
              <Select
                placeholder="Select default meal"
                defaultValue={
                  currentData && currentData.meal_default_id
                    ? defaultMeal
                    : null
                }
                value={defaultMeal ? defaultMeal : ""}
                handleChange={(e) => {
                  setCurrentMeallAddonCat({
                    ...currentMeallAddonCat,
                    meal_default_id: e?.meal_data?.id,
                  });
                  setdefaultMeal(e);
                }}
                containerClass=""
                options={currentMeallAddonCat?.addons}
                isCustom
                customNameOne="meal_data"
                customNameTwo="name"
                id="id"
              />
            )}

            {/* <Select
              placeholder="Select meal category"
              value={defaultMealCategory}
              handleChange={(e) => {
                setCurrentMeallAddonCat({
                  ...currentMeallAddonCat,
                  meal_category_id: e?.id,
                })
               // setdefaultMealCategory(e)
              }}
              containerClass=""
              options={brandmealCategoriesAlt}
              name="name"
              id="id"
            /> */}
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formUpdate();
        }}
        saveText={submitting ? "Updating..." : "Update meal category"}
        loading={submitting}
        withDel
        deleteText="Delete meal category"
        delClick={() => setShowPrompt(true)}
      />

      <Modal
        title={`Duplicate ${duplicateAddonData && duplicateAddonData.name}`}
        showModal={showDuplicateModal}
        closeModal={() => {
          setShowDuplicateModal(!showDuplicateModal);
          setCurrentMeallAddonCat(null);
          setDuplicateAddonData([]);
          setdefaultMeal("");
        }}
        customContent={
          <div>
            {duplicateAddonData && (
              <div>
                <Select
                  placeholder="Select meal addon category to be updated"
                  value={currentMeallAddonCat}
                  handleChange={(e) => {
                    setCurrentMeallAddonCat(e);
                    setDuplicateForm({
                      ...duplicateForm,
                      brand_id: currentMealBrand,
                      meal_addon_category_id: currentMeallAddonCat?.id,
                    });
                  }}
                  containerClass=""
                  options={duplicateAddonData?.meal_addon_categories}
                  name="name"
                  id="id"
                />
                <Select
                  placeholder="Select Brand"
                  handleChange={(e) => {
                    setDuplicateForm({
                      ...duplicateForm,
                      brand_id: e?.id,
                    });
                  }}
                  value={currentBrandName}
                  containerClass=""
                  options={currentCokitchen?.brands?.filter(
                    (item) => item?.item_type === "FOOD"
                  )}
                  name="name"
                  id="id"
                />
                <Select
                  placeholder="Select meal category"
                  value={currentMealCat}
                  // handleChange={(e) => {
                  //  setDuplicateForm({
                  //   ...duplicateForm,
                  //   meal_category_ids: e?.id,
                  //  });
                  // }}
                  containerClass=""
                  name="name"
                  id="id"
                  isLoading={loading}
                />
              </div>
            )}
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          duplicateFormUpdate();
        }}
        saveText="Duplicate meal category addon"
      />

      <Modal
        title="Create Meal category"
        showModal={showCreateModal}
        closeModal={() => {
          history.push("/meal-categories/meals");
          setShowCreateModal(!showCreateModal);
        }}
        customContent={
          <div>
            <Input
              placeholder="Enter meal category name"
              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e });
              }}
              required
              containerClass="mb_15"
            />
            <textarea
              className="text_area text_area_short mb_15"
              placeholder="Meal category description "
              value={form.summary}
              onChange={(e) => {
                setForm({ ...form, summary: e.target.value });
              }}
            />
            <Select
              placeholder="Select brand"
              handleChange={(e) => {
                setForm({
                  ...form,
                  brand_id: e?.id,
                });
                setSelectedParentMeals([]);
              }}
              containerClass=""
              options={currentCokitchen?.brands?.filter(
                (item) => item?.item_type === "FOOD"
              )}
              name="name"
              id="id"
              isLoading={loading}
            />
            {/* <Select
              placeholder="Select super meal category type"


              handleChange={(e) => {
                setForm({
                  ...form,
                  super_meal_category_id: e?.id,
                });
              }}
              containerClass="mb_15"
              options={supermealCategorySelections}
              name="name"
              id="id"
            /> */}
            <div className="flex_row_start mb_15">
              <CheckBox
                id="set_mc_addon"
                label={`Is addon?`}
                checked={form?.is_addon}
                onClick={() => {
                  setForm({
                    ...form,
                    is_addon: !form?.is_addon,
                  });
                }}
              />
            </div>
            {form?.is_addon ? (
              <Select
                placeholder="Select meal category type"
                value={
                  mealCategorySelections?.find(
                    (item) => item?.id === form?.meal_category_selection_type_id
                  ) || ""
                }
                handleChange={(e) => {
                  setForm({
                    ...form,
                    meal_category_selection_type_id: e.id,
                  });
                  setMealCategorySelectionType(e);
                }}
                containerClass=""
                options={mealCategorySelections}
                name="name"
                id="id"
                isLoading={loading}
              />
            ) : null}
            {mealCategorySelectionType?.name === "MULTI_SELECTION" ||
            mealCategorySelectionType?.name ===
              "MULTI_SELECTION_WITH_QUANTITY" ? (
              <div>
                {" "}
                <Input
                  placeholder="Selection Number (Optional)"
                  value={form?.selection_no || ""}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      selection_no: e,
                    })
                  }
                  containerClass="mb_15 "
                />
                <Input
                  placeholder="Minimum Selection Number (Optional)"
                  value={form?.min_selection_no || ""}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      min_selection_no: e,
                    })
                  }
                  containerClass="mb_15 "
                />
              </div>
            ) : null}

            {form?.is_addon ? (
              <>
                <Select
                  placeholder="Select meal category"
                  value={
                    brandmealCategories?.find(
                      (item) => item?.id === form?.meal_category_id
                    ) || ""
                  }
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      meal_category_id: e?.id,
                    });
                    setSelectedParentMeals([]);
                  }}
                  containerClass=""
                  options={brandmealCategories}
                  name="name"
                  id="id"
                  isLoading={loading}
                />

                <Select
                  placeholder="Select parent meal"
                  value={SelectedParentMeals || []}
                  isMulti
                  handleChange={(e) => {
                    let i;
                    let copy = [];
                    for (i = 0; i < e.length; i++) {
                      copy.push(e[i].id);
                    }
                    setForm({ ...form, meal_parent_ids: copy });
                    setSelectedParentMeals(e);
                  }}
                  containerClass=""
                  options={
                    brandmealCategories?.find(
                      (item) => item?.id === form?.meal_category_id
                    )?.meals || []
                  }
                  name="name"
                  id="id"
                  isLoading={loading}
                />
              </>
            ) : null}
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={adding ? "Creating..." : "Create Meal Category"}
        loading={adding}
      />

      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to delete this meal category?"
        deleteText={submitting ? "Deleting..." : "Delete"}
      />

      {/* Add post modal */}
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        loading={adding}
        onSubmit={(e) => {
          e.preventDefault();
          formTwoSubmit();
        }}
        saveText={adding ? "Creating..." : "Create"}
        title="Add New Super Meal Category"
        customContent={
          <div>
            <Input
              placeholder="Enter super meal category name"
              value={formTwo.name}
              onChange={(e) => {
                setFormTwo({ ...formTwo, name: e });
              }}
              required
              containerClass="mb_15"
              withLabel
              label="Super meal category name"
            />

            <Select
              placeholder="Select brand"
              handleChange={(e) => {
                setFormTwo({
                  ...formTwo,
                  brand_id: e?.id,
                });
              }}
              containerClass=""
              options={currentCokitchen?.brands}
              name="name"
              id="id"
            />
          </div>
        }
      />
    </main>
  );
};
export default withAlert()(MealCategories);
