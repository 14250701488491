import React from 'react'
import Input from '../../../components/inputs';
import AppMap from '../../../components/map';
import Modal from '../../../components/modal';

const EditPolygon = ({
  showUpdateForm, closeModal, submitting, currentPolygon, setCurrentPolygon, setCords, deletePolygon, updating, onSubmit
}) => {
  return (
    <Modal
      showModal={showUpdateForm}
      closeModal={closeModal}
      withDel
      deleteText={submitting ? "Deleting polygon..." : "Delete polygon"}
      delClick={deletePolygon}
      customContent={
        <div>
          <AppMap
            setCord
            setCords={setCords}
            lat={
              currentPolygon &&
              currentPolygon.polygon &&
              currentPolygon.polygon[0] &&
              currentPolygon.polygon[0][0]
            }
            lng={
              currentPolygon &&
              currentPolygon.polygon &&
              currentPolygon.polygon[0] &&
              currentPolygon.polygon[0][1]
            }
            withRect
            positions={currentPolygon && currentPolygon.polygon}
          />
          <Input
            withLabel
            label="Polygon name"
            placeholder="Polygon name"
            value={currentPolygon && currentPolygon.name}
            onChange={(e) =>
              setCurrentPolygon({ ...currentPolygon, name: e })
            }
            required
            containerClass="mt_25"
          />
          <Input
            withLabel
            label="Delivery fee"
            placeholder="Delivery fee"
            value={currentPolygon && currentPolygon.delivery_fee}
            onChange={(e) =>
              setCurrentPolygon({
                ...currentPolygon,
                delivery_fee: e,
              })
            }
            required
            containerClass="mt_25"
          />
        </div>
      }
      customClass="map_modal"
      onSubmit={onSubmit}
      saveText={updating ? "Updating..." : "Update polygon"}
      title={`Update ${currentPolygon && currentPolygon.name}`}
    />
  )
}

export default EditPolygon