import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Select from "../../components/select";
import { Tags } from "../../components/appTag";
import Loader from "react-loader-spinner";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { IoMdCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { HomeStore } from "../../stores/dataStore/homeStore";
import { uploadFilesAndGetUrl } from "../../helpers/upload";


const Cokitchens = ({ alert }) => {
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [description, setDescription] = useState("");

  const [imageDisplay, setImageDisplay] = useState(null);
  const [image, setImage] = useState(null);

  const [showSide, setShowSide] = useState(true);
  const [showTagDrop, setShowTagDrop] = useState(false);
  const [showDietaryDrop, setShowDietaryDrop] = useState(false);
  const [showAllergenDrop, setShowAllergenDrop] = useState(false);
  const [showDescriptionDrop, setShowDescriptionDrop] = useState(false);
  const [showBusinessDrop, setShowBusinessDrop] = useState(false);

  const [pageWidth, setPageWidth] = useState("");

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [dietaryTags, setDietaryTags] = useState([]);
  const [dietarySuggestions, setDietarySuggestions] = useState([]);

  const [allergenTags, setAllergenTags] = useState([]);
  const [allergenSuggestions, setAllergenSuggestions] = useState([]);

  const [descriptionTags, setDescriptionTags] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);

  const [businessTags, setBusinessTags] = useState([]);
  const [businessSuggestions, setBusinessSuggestions] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [form, setForm] = useState({
    name: "",
    lat: "",
    lng: "",
    address: "",
    customer_service_phone_number: [""],
    frontdesk_phone_number: [""],
    frontdesk_whatsapp: [""],
    customer_service_whatsapp: [""],
  });
  const [address, setAddress] = useState("");
  const [updateForm, setUpdateForm] = useState({
    cokitchen_id:"",
    name: "",
    lat: "",
    lng: "",
    address: "",
    customer_service_phone_number: [""],
    frontdesk_phone_number: [""],
    frontdesk_whatsapp: [""],
    customer_service_whatsapp: [""],
  });
  const [currentAddress, setCurrentAddress] = useState([""]);
  const [customerServiceNumber, setCustomerServiceNumber] = useState([""]);
  const [frontDeskPhoneNumber, setFrontDeskPhoneNumber] = useState([""]);
  const [frontDeskWhatsapp, setFrontDeskWhatsapp] = useState([""]);
  const [customerServiceWhatsapp, setCustomerServiceWhatsapp] = useState([""]);
  const [customerServiceNumberCopy, setCustomerServiceNumberCopy] = useState([]);
  const [frontDeskPhoneNumberCopy, setFrontDeskPhoneNumberCopy] = useState([]);
  const [frontDeskWhatsappCopy, setFrontDeskWhatsappCopy] = useState([]);
  const [customerServiceWhatsappCopy, setCustomerServiceWhatsappCopy] =useState([]);
  const [newAddress, setNewAddress] = useState("");

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);


  useEffect(() => {
    if (form?.address) {
      geocodeByAddress(form?.address)
        .then((results) => getLatLng(results[0]))
        .then((ress) => {
          setForm({ ...form, lat: String(ress?.lat), lng: String(ress?.lng) });
         
        });
    }
  }, [form?.address]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;
    if (!BrandStore.dataCached) {
      await BrandStore.getBrands(alert);
    }

    if (!CommonStore.dataCached) {
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCkLoading(false);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands: BrandStore.brands,
      name: "All users (all locations)",
    });
    setBrands(BrandStore.brands);
    setAllTags();
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = currentCokitchen.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({ ...curr[i], label: curr[i].name, value: curr[i].id });
    }
    setCurrentBrands(options);
    let customerService = currentCokitchen?.customer_service_phone_number || [
      "",
    ];
  
    setCustomerServiceNumberCopy(customerService);
    let whatsappNumber = currentCokitchen.customer_service_whatsapp || [""];
    setCustomerServiceWhatsappCopy(whatsappNumber);
    let frontdeskWhatsapp = currentCokitchen.frontdesk_whatsapp || [""];
    setFrontDeskWhatsappCopy(frontdeskWhatsapp);
    let frontdeskNumber = currentCokitchen.frontdesk_phone_number || [""];
    setFrontDeskPhoneNumberCopy(frontdeskNumber);
    let currentaddress = currentCokitchen.address;
    
    setCurrentAddress(currentaddress);
    console.log(currentAddress);


       setUpdateForm({
         cokitchen_id: currentCokitchen?.id,
         name: currentCokitchen?.name,
         lat: currentCokitchen?.lat,
         lng: currentCokitchen?.lng,
         address: currentCokitchen?.address,
         customer_service_phone_number:
           currentCokitchen?.customer_service_phone_number,
         frontdesk_phone_number: currentCokitchen.frontdesk_phone_number,
         frontdesk_whatsapp: currentCokitchen.frontdesk_whatsapp,
         customer_service_whatsapp: currentCokitchen.customer_service_whatsapp,
       });

  }, [currentCokitchen]);

  // Set brand data based on current brand
  useEffect(() => {
    let l;
    let mealOptions = [];
    let mealCopy = brand.meals || [];
    for (l = 0; l < mealCopy.length; l++) {
      mealOptions.push({
        ...mealCopy[l],
        label: mealCopy[l].name,
        value: mealCopy[l].id,
      });
    }
    setMeals(mealOptions);
  }, [brand]);

  // Set meal data based on current meal
  useEffect(() => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let keyCopy = meal.meal_keywords || [];
    let descCopy = meal.meal_descriptive_metadatas || [];
    let bussCopy = meal.meal_business_metadatas || [];
    let dietCopy = meal.meal_dietary_metadatas || [];
    let allergyCopy = meal.meal_allergy_metadatas || [];
    let descriptionCopy = meal.description || "";
  

    setDescription(descriptionCopy);
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        ...keyCopy[i],
        label: keyCopy[i].name,
        value: keyCopy[i].id,
      });
    }
    setTags(keyOptions);

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        ...dietCopy[j],
        label: dietCopy[j].name,
        value: dietCopy[j].id,
      });
    }
    setDietaryTags(dietOptions);

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        ...allergyCopy[k],
        label: allergyCopy[k].name,
        value: allergyCopy[k].id,
      });
    }

    setAllergenTags(allergyOptions);

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        ...descCopy[l],
        label: descCopy[l].name,
        value: descCopy[l].id,
      });
    }
    setDescriptionTags(descOptions);

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        ...bussCopy[m],
        label: bussCopy[m].name,
        value: bussCopy[m].id,
      });
    }
    setBusinessTags(bussOptions);
  }, [meal]);

  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  // Sort tags
  const setAllTags = () => {
    let { formatedTags } = TagsStore;
    let i;
    for (i = 0; i < formatedTags.length; i++) {
      if (formatedTags[i].placeholder === "meal_keyword") {
        setSuggestions(formatedTags[i].tags);
      } else if (formatedTags[i].placeholder === "meal_dietary_metadata") {
        setDietarySuggestions(formatedTags[i].tags);
      } else if (formatedTags[i].placeholder === "meal_allergy_metadata") {
        setAllergenSuggestions(formatedTags[i].tags);
      } else if (formatedTags[i].placeholder === "meal_descriptive_metadata") {
        setDescriptionSuggestions(formatedTags[i].tags);
      } else if (formatedTags[i].placeholder === "meal_business_metadata") {
        setBusinessSuggestions(formatedTags[i].tags);
      }
    }
  };

  // Format tags to be submitted
  const formatTags = () => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let allOptions = {};
    let keyCopy = tags;
    let descCopy = descriptionTags;
    let bussCopy = businessTags;
    let dietCopy = dietaryTags;
    let allergyCopy = allergenTags;

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        name: allergyCopy[k].name,
        id: allergyCopy[k].id,
      });
    }
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        name: keyCopy[i].name,
        id: keyCopy[i].id,
      });
    }

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        name: dietCopy[j].name,
        id: dietCopy[j].id,
      });
    }

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        name: descCopy[l].name,
        id: descCopy[l].id,
      });
    }

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        name: bussCopy[m].name,
        id: bussCopy[m].id,
      });
    }

    allOptions = {
      key: keyOptions,
      desc: descOptions,
      buss: bussOptions,
      diet: dietOptions,
      allergy: allergyOptions,
    };

    return allOptions;
  };
  //Update Cokitchen


  const handleMultiInput = (e, i, setField, field, item) =>{
  let fieldCopy = [...field];
  fieldCopy[i]= e
  setField([...fieldCopy])
  }

  useEffect(() => {
    setUpdateForm({
      ...updateForm,
      cokitchen_id: currentCokitchen?.id,
      name: currentCokitchen?.name,
      customer_service_phone_number: customerServiceNumberCopy,
      frontdesk_phone_number: frontDeskPhoneNumberCopy,
      frontdesk_whatsapp: frontDeskWhatsappCopy,
      customer_service_whatsapp: customerServiceWhatsappCopy,
    });
  }, [
    frontDeskWhatsappCopy,
    frontDeskPhoneNumberCopy,
    customerServiceWhatsappCopy,
    customerServiceNumberCopy,
  ]);


   useEffect(() => {
     setForm({
       ...form,
       customer_service_phone_number: customerServiceNumber,
       frontdesk_phone_number: frontDeskPhoneNumber,
       frontdesk_whatsapp: frontDeskWhatsapp,
       customer_service_whatsapp: customerServiceWhatsapp,
     });
   }, [
     customerServiceNumber,
     frontDeskPhoneNumber,
     frontDeskWhatsapp,
     customerServiceWhatsapp,
   ]);

  useEffect(() => {
   if (newAddress) {
     geocodeByAddress(updateForm?.address)
       .then((results) => getLatLng(results[0]))
       .then(({ lat, lng }) => {
         setUpdateForm({ ...updateForm, lat: String(lat), lng: String(lng) });
       });
   }
  }, [updateForm?.address]);
   
  //Update Cokitchen
  const cokitchenUpdate = async(e) => {
    e.preventDefault()
    const { updateCokitchens, getCokitchens } = CommonStore;
   
    console.log(updateForm);
     let body =updateForm 
     console.log(body)
    if (!body?.address) {
      alert.show("Address is required!", {
        type: "info",
      });
    } else {
      try {
        setAdding(true);

        let res;
      res  = await updateCokitchens(alert, body);
        
        if (res) {
          setAdding(false);
          setShowAddForm(false);

          alert.show("Fetching updates...", {
            type: "info",
          });
          setCkLoading(true);
         await getCokitchens(alert, false);
          setCokitchens(CommonStore.cokitchens);
          setCkLoading(false);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();

        setCokitchens(HomeStore.posts);

        // setCurrentCokitchen({
        //   cokitchen_home_page_posts: sortAllPosts(),
        //   name: "All users (all locations)",
        // });
      }
    }
  };




  // Update meal
  const mealUpdate = async (e) => {
    e.preventDefault();
    const { updateMeal, getBrands, brands } = BrandStore;
    if (!brand) {
      alert.show("Please select a brand to update it's meal", {
        type: "info",
      });
    } else if (!meal) {
      alert.show("Please select a meal to update", {
        type: "info",
      });
    } else {
      setSubmitting(true);
      let url;
      if (image) {
        let url;
        if (image) {
          const imageUrls = await uploadFilesAndGetUrl([image])
          setImageDisplay(imageUrls[0]);
          url = imageUrls[0]
        }
      }
      const allTags = await formatTags();
      await updateMeal(alert, {
        meal_id: meal.id,
        //  summary: brandSummary,
        description: description,
        images: [url || imageDisplay],
        meal_keywords: allTags.key,
        meal_descriptive_metadatas: allTags.desc,
        meal_business_metadatas: allTags.buss,
        meal_dietary_metadatas: allTags.diet,
        meal_allergy_metadatas: allTags.allergy,
      });
      await getBrands(alert);
      setBrands(brands);
      setSubmitting(false);
    }
  };
  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  const handleDelete = (state, index, setState) => {
    const allFields = [...state];
    allFields.splice(index, 1);
    setState(allFields);
  };

  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  // Submit add form
  const formSubmit = async () => {
    const { getCokitchens, postCokitchens } = CommonStore;
    let body = showAddForm ? form : {};
    if (!body?.address) {
      alert.show("Address is required!", {
        type: "info",
      });
    } else {
      try {
        setAdding(true);

        let res;
        if (showAddForm) {

          console.log(body)
       res = await postCokitchens(alert, body);
        }

        if (res) {
          setAdding(false);
          setShowAddForm(false);

          alert.show("Fetching updates...", {
            type: "info",
          });
          setCkLoading(true);
         await getCokitchens(alert, false);
          setCokitchens(CommonStore.cokitchens);
          setCkLoading(false);
            let customerService = cokitchens.customer_service_phone_number || "";
            setCustomerServiceNumberCopy(customerService);
            let whatsappNumber = cokitchens.customer_service_whatsapp || "";
setCustomerServiceWhatsappCopy(whatsappNumber);
            let frontdeskWhatsapp = cokitchens.frontdesk_whatsapp || "";
setFrontDeskWhatsappCopy(frontdeskWhatsapp);
             let frontdeskNumber = cokitchens.frontdesk_phone_number || "";
           setFrontDeskPhoneNumberCopy(frontdeskNumber); 
            
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();

        setCokitchens(HomeStore.posts);

        // setCurrentCokitchen({
        //   cokitchen_home_page_posts: sortAllPosts(),
        //   name: "All users (all locations)",
        // });
      }
    }
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab cokitchens="active_nav_item" />

            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">
                  Cokitchens ({cokitchens?.length || "-"})
                </h6>
                <div className="flex_row_center">
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="New Cokitchen"
                    onClick={() => {
                      setForm({
                        name: "",
                        lat: "",
                        lng: "",
                        address: "",

                        customer_service_phone_number: [""],
                        frontdesk_phone_number: [""],
                        frontdesk_whatsapp: [""],
                        customer_service_whatsapp: [""],

                      });

                      setShowAddForm(!showAddForm);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                      setCurrentCokitchen({
                        brands: brands,
                        name: "All users (all locations)",
                      })
                    }
                    activeItem={
                      currentCokitchen?.name === "All users (all locations)"
                    }
                  />
                ) : null}

                {cokitchens?.map((item, i) => {
                  return (
                    <NavItem
                      navText={item?.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen?.name === item?.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container mb_30">
              <form onSubmit={(e) => cokitchenUpdate(e)}>
                <Input
                  placeholder="Enter cokitchen name"
                  value={currentCokitchen?.name}
                  onChange={(e) => {
                    setUpdateForm({ ...updateForm, name: e });
                  }}
                  required
                  containerClass="mb_15"
                  withLabel
                  label="Cokitchen name"
                />
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  selectProps={{
                    value: updateForm?.address,
                    onChange: (e) => {
                      setUpdateForm({ ...updateForm, address: e?.label });
                      setNewAddress(true)
                     
                    },
                    placeholder:updateForm.address,
                    required: true,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    },
                  }}
                />

                <div>
                  <Button
                    withIcon
                    type="button"
                    className="mt_35"
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Add customer service phone number"
                    onClick={() => {
                      setCustomerServiceNumberCopy([
                        ...customerServiceNumberCopy,
                        "",
                      ]);
                    }}
                  />
                  {customerServiceNumberCopy.map((item, index) => (
                    <div key={index}>
                      <Input
                        placeholder="Enter customer service phone number"
                        value={item || ""}
                        containerClass="mt_35"
                        onChange={(e) => {
                          handleMultiInput(
                            e,
                            index,
                            setCustomerServiceNumberCopy,
                            customerServiceNumberCopy,
                            "name"
                          );
                        }}
                        name="customer_service_phone_number"
                        required
                        withLabel
                        label="Customer service phone number"
                      />
                      {customerServiceNumberCopy.length > 1 && (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(
                              customerServiceNumberCopy,
                              index,
                              setCustomerServiceNumberCopy
                            );
                          }}
                          className="cursor_pointer"
                        />
                      )}
                    </div>
                  ))}
                </div>

                <div>
                  <Button
                    withIcon
                    type="button"
                    className="mt_25"
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Add customer service whatsapp number"
                    onClick={() => {
                      setCustomerServiceWhatsappCopy([
                        ...customerServiceWhatsappCopy,
                        "",
                      ]);
                    }}
                  />
                  {customerServiceWhatsappCopy.map((item, index) => (
                    <div>
                      <Input
                        placeholder="Enter customer service whatsapp number"
                        value={item || ""}
                        onChange={(e) => {
                          handleMultiInput(
                            e,
                            index,
                            setCustomerServiceWhatsappCopy,
                            customerServiceWhatsappCopy,
                            "name"
                          );
                        
                        }}
                        name="whatsapp_phone_number"
                        required
                        containerClass="mt_25"
                        withLabel
                        label="Customer Service Whatsapp"
                      />
                      {customerServiceWhatsappCopy.length > 1 && (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(
                              customerServiceWhatsappCopy,
                              index,
                              setCustomerServiceWhatsappCopy
                            );
                          }}
                          className="cursor_pointer"
                        />
                      )}
                    </div>
                  ))}
                </div>

                <div>
                  <Button
                    withIcon
                    type="button"
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Add frontdesk Phone number"
                    className="mt_25"
                    onClick={() => {
                      setFrontDeskPhoneNumberCopy([
                        ...frontDeskPhoneNumberCopy,
                        "",
                      ]);
                    }}
                  />

                  {frontDeskPhoneNumberCopy.map((item, index) => (
                    <div>
                      <Input
                        placeholder="Enter frontdesk Phone number"
                        value={item || ""}
                        onChange={(e) => {
                          handleMultiInput(
                            e,
                            index,
                            setFrontDeskPhoneNumberCopy,
                            frontDeskPhoneNumberCopy,
                            "name"
                          );
                         
                        }}
                        name="Frontdesk Phone number"
                        required
                        containerClass="mt_25"
                        withLabel
                        label="Frontdesk Phone number"
                      />
                      {frontDeskPhoneNumberCopy.length > 1 && (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(
                              frontDeskPhoneNumberCopy,
                              index,
                              setFrontDeskPhoneNumberCopy
                            );
                          }}
                          className="cursor_pointer"
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <Button
                    withIcon
                    type="button"
                    className="mt_25"
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Add front desk whatsapp"
                    onClick={() => {
                      setFrontDeskWhatsappCopy([...frontDeskWhatsappCopy, ""]);
                    }}
                  />

                  {frontDeskWhatsappCopy.map((item, index) => (
                    <div>
                      <Input
                        placeholder="Enter frontdesk Whatsapp"
                        value={item || ""}
                        onChange={(e) => {
                          handleMultiInput(
                            e,
                            index,
                            setFrontDeskWhatsappCopy,
                            frontDeskWhatsappCopy,
                            "name"
                          );
                         
                        }}
                        name="frontdesk_whatsapp"
                        required
                        containerClass="mt_25"
                        withLabel
                        className="mb_25"
                        label="Frontdesk Whatsapp"
                      />

                      {frontDeskWhatsappCopy.length > 1 && (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(
                              frontDeskWhatsappCopy,
                              index,
                              setFrontDeskWhatsappCopy
                            );
                          }}
                          className="cursor_pointer"
                        />
                      )}
                    </div>
                  ))}
                </div>
                <Button text="Update Cokitchen" type="submit" />

              </form>
            </section>
          </section>
        </section>
      </div>

      {/* Add post modal */}
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        loading={adding}
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={adding ? "Creating cokitchen..." : "Create cokitchen"}
        title="Add New Cokitchen"
        customContent={
          <div>
            <Input
              placeholder="Enter cokitchen name"
              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e });
              }}
              required
              containerClass="mb_15"
              withLabel
              label="Cokitchen name"
            />
            <div className="mb_15 ">
              <p className="mb_15 normal_17px capitalize">Cokitchen address</p>

              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                selectProps={{
                
                  value: address,
                  onChange: (e) => {
                    setForm({ ...form, address: e?.label });
                    setAddress(e);
                  },
                  placeholder: "Search address",
                  required: true,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  },
                }}
              />
            </div>

            <div>
              <Button
                withIcon
                type="button"
                icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
                text="Add customer service phone number"
                className="mt_35"
                onClick={() => {
                  setCustomerServiceNumber([...customerServiceNumber, ""]);
                }}
              />
              {customerServiceNumber.map((item, index) => (
                <div key={index}>
                  <Input
                    placeholder="Enter customer service phone number"
                    value={item || ""}
                    onChange={(e) => {
                      handleMultiInput(
                        e,
                        index,
                        setCustomerServiceNumber,
                        customerServiceNumber,
                        "name"
                      );
                      setForm({
                        ...form,
                        customer_service_phone_number: customerServiceNumber,
                      });
                    }}
                    name="customer_service_phone_number"
                    required
                    containerClass="mt_25 "
                    withLabel
                    label="Customer service phone number"
                  />

                  {customerServiceNumber.length > 1 && (
                    <IoMdCloseCircleOutline
                      fill="#B9B9B9"
                      width="14px"
                      height="14px"
                      onClick={() => {
                        handleDelete(
                          customerServiceNumber,
                          index,
                          setCustomerServiceNumber
                        );
                      }}
                      className="cursor_pointer"
                    />
                  )}
                </div>
              ))}
            </div>

            <div>
              <Button
                withIcon
                icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}

                text="Add customer service whatsapp"
                type="button"
                className="mt_25"
                onClick={() => {
                  setCustomerServiceWhatsapp([...customerServiceWhatsapp, ""]);

                }}
              />
              {customerServiceWhatsapp.map((item, index) => (
                <div>
                  <Input

                    placeholder="Enter customer service whatsapp"

                    value={item || ""}
                    className="mt-25"
                    onChange={(e) => {
                      handleMultiInput(
                        e,
                        index,
                        setCustomerServiceWhatsapp,
                        customerServiceWhatsapp,
                        "name"
                      );
                      setForm({
                        ...form,
                        customer_service_whatsapp: customerServiceWhatsapp,
                      });
                    }}
                    name="whatsapp_phone_number"
                    required
                    containerClass="mt_25"
                    withLabel

                    label="Customer service whatsapp"
                  />
                  {customerServiceWhatsapp.length > 1 && (
                    <IoMdCloseCircleOutline
                      fill="#B9B9B9"
                      width="14px"
                      height="14px"
                      onClick={() => {
                        handleDelete(
                          customerServiceWhatsapp,
                          index,
                          setCustomerServiceWhatsapp
                        );
                      }}
                      className="cursor_pointer"
                    />
                  )}
                </div>
              ))}
            </div>

            <div>
              <Button
                withIcon
                type="button"
                className="mt_25"
                icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
                text="Add front desk phone number"
                onClick={() => {
                  setFrontDeskPhoneNumber([...frontDeskPhoneNumber, ""]);
                }}
              />

              {frontDeskPhoneNumber.map((item, index) => (
                <div>
                  <Input
                    placeholder="Enter frontdesk Phone number"
                    value={item || ""}
                    onChange={(e) => {
                      handleMultiInput(
                        e,
                        index,
                        setFrontDeskPhoneNumber,
                        frontDeskPhoneNumber,
                        "name"
                      );
                      setForm({
                        ...form,
                        frontdesk_phone_number: frontDeskPhoneNumber,
                      });
                    }}
                    name="Frontdesk Phone number"
                    required
                    containerClass="mt_25"
                    withLabel
                    label="Frontdesk Phone number"
                  />
                  {frontDeskPhoneNumber.length > 1 && (
                    <IoMdCloseCircleOutline
                      fill="#B9B9B9"
                      width="14px"
                      height="14px"
                      onClick={() => {
                        handleDelete(
                          frontDeskPhoneNumber,
                          index,
                          setFrontDeskPhoneNumber
                        );
                      }}
                      className="cursor_pointer"
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              <Button
                withIcon
                type="button"
                className="mt_25"
                icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
                text="Add frontdesk whatsapp"
                onClick={() => {
                  setFrontDeskWhatsapp([...frontDeskWhatsapp, ""]);
                }}
              />
              {frontDeskWhatsapp.map((item, index) => (
                <div>
                  <Input
                    placeholder="Frontdesk Whatsapp"
                    value={item || ""}
                    onChange={(e) => {
                      handleMultiInput(
                        e,
                        index,
                        setFrontDeskWhatsapp,
                        frontDeskWhatsapp,
                        "name"
                      );
                      setForm({
                        ...form,
                        frontdesk_whatsapp: frontDeskWhatsapp,
                      });
                      console.log(form);
                    }}
                    name="frontdesk_whatsapp"
                    required
                    containerClass="mt_25"
                    withLabel
                    label="Frontdesk Whatsapp"
                  />
                  
                </div>
              ))}
            </div>
          </div>
        }
      />
    </main>
  );
};
export default withAlert()(Cokitchens);