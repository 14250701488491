import React, { useState, useEffect } from 'react'
import './styles.scss'
import Header from '../../components/header'
import Tab from '../../components/tabs'
import TextCard from '../../components/textCard'
import NavItem from '../../components/sideNavItem'
import { LocationIcon } from '../../assets/icons/icons'
import Modal from '../../components/modal'
import { DateRangePicker } from 'react-date-range'
import VoucherStore from '../../stores/dataStore/voucherStore'
import PageLoading from '../../components/loader'
import { withAlert } from 'react-alert'
import UserStore from '../../stores/userStore/user'
import Prompt from '../../components/modal/prompt'
import { Button } from '../../components/buttons'
import Input from '../../components/inputs'
import { ReactSortable } from 'react-sortablejs'
import Select from '../../components/select'
import axios from 'axios'
import ImageFile from '../../components/imageFile'
import IconInput from '../../components/inputs/iconInput'
import { Clock, DateIcon } from '../../assets/icons/icons'
import moment from 'moment'
import { makeCode } from '../../helpers/makeCode'
import {FaInfo} from "react-icons/fa"
import { uploadFilesAndGetUrl } from '../../helpers/upload'


const Vouchers = ({ alert }) => {
  const [selectedBrands, setSelectedBrands] = useState([])
  const [showDateRange, setShowDateRange] = useState(false)
  const [vouchers, setVouchers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)
  // const [selectedRatings, setSelectedRatings] = useState([]);
  const locations = [
    { name: 'All users (all locations)' },
    { name: 'Lekki Phase 1 Kitchen' },
    { name: 'Oniru Kitchen' },
    { name: 'Ikeja Kitchen' }
  ]

  const [currentLocation, setCurrentLocation] = useState(
    'All users (all locations)'
  )

  const [showSide, setShowSide] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const [pageWidth, setPageWidth] = useState('')
  const [currentData, setCurrentData] = useState('')
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  })

  const [voucherForm, setvoucherForm] = useState({
    name: '',
    description: '',
    max_usage: '',
    amount: '',
    to_expire_time: '',
    users: [],
    images: [],
    count:1
  })
  const [submitting, setSubmitting] = useState(null)
  const [rearrangeDisabled, setrRarrangeDisabled] = useState(false)
  const [imageDisplay, setImageDisplay] = useState(null)
  const [image, setImage] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [expDate, setExpDate] = useState('')
  const [expTime, setExpTime] = useState('')
  useEffect(() => {
    setPageWidth(window.innerWidth)
    if (pageWidth <= 628) {
      setShowSide(false)
    } else {
      setShowSide(true)
    }
  }, [pageWidth])
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [pageWidth])

  // load data from store
  useEffect(async () => {
    setLoading(true)
    setLoadingUsers(true)
    if (!VoucherStore.dataCached) {
      await VoucherStore.getVouchers(alert)
      setLoading(false)
      setVouchers(VoucherStore.vouchers)
    }
    if (!UserStore.dataCached) {
      await UserStore.getUsers(alert, false)
      setLoadingUsers(false)
    }
    setLoading(false)
    setLoadingUsers(false)
    setVouchers(VoucherStore.vouchers)
    setUsersOptions()
  }, [])

  const formSubmit = async () => {
    const { postVouchers, getVouchers } = VoucherStore
    // if (!image) {
    //   alert.show(`Please upload an image!`, {
    //     type: "info",
    //   });
    // } else {
 
    try {
      setSubmitting(true)
      const imageUrls = await uploadFilesAndGetUrl([image], true)
      const url = imageUrls[0]
      let body = voucherForm
      if (body?.users?.length > 1) {
        delete body.voucher_code
      }
      body.images = [url]
      body.to_expire_time = moment(
        expDate + ' ' + moment(expTime).format('HH:mm:ss')
      ).format()
      
      const res = await postVouchers(alert, body)

        if (res && res.data) {
          await getVouchers(alert)
          setVouchers(VoucherStore.vouchers)
          setShowCreateModal(false)
        }

      setSubmitting(false)
    } catch (error) {
      const errMsg =
        error && (error.response || error.message || error.toString() || error)
      alert.show(
        (errMsg && errMsg.data && errMsg.data.message) ||
          errMsg ||
          'An error has occured!!',
        {
          type: 'error'
        }
      )
    } finally {
      setSubmitting(false)
      resetForm()
    }
  }
  const resetForm = () => {
    setvoucherForm({
      name: '',
      description: '',
      max_usage: '',
      amount: '',
      to_expire_time: '',
      users: [],
      images: [],
      count:1
    })
  }
 

  // Sort users options for react select
  const setUsersOptions = () => {
    let opts = UserStore.users
    let options = []
    let i
    for (i = 0; i < opts.length; i++) {
      options.push({
        ...opts[i],
        label: opts[i].first_name + ' ' + opts[i].last_name,
        value: opts[i].id
      })
    }
    setUsers(options)
  }

  const onCardSelect = i => {
    let newArr = [...selectedBrands, i]
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter(day => day !== i)
    }
    setSelectedBrands(newArr)
  }

  const handleSelect = ranges => {
    setSelectionRange(ranges.selection)
  }
  const onImageChange = async e => {
    setImage(e)
    e && setImageDisplay(URL.createObjectURL(e))
  }

  // Set the defaults for preselected brands
  const setSelectDefaults = items => {
    let options = []
    let i

    if (items && items.length && items.length > 0) {
      for (i = 0; i < items.length; i++) {
        options.push({ label: items[i].name, value: items[i].id })
      }
    }

    return options
  }

  return (
    <main className='main_container'>
      <div className='main_container_content'>
        <Header />

        <section className='main_section'>
          <div className='top_fixed'>
            <Tab vouchers='active_nav_item ' />
            <div className='border_bottom_thick'>
              <div className='flex_row_between title_bar'>
                <h6 className='bold_24px'>Vouchers</h6>
              </div>
            </div>
          </div>
          <div className='top_section'>
            <div className='mobile_visible px_50 flex_row_between'>
              {pageWidth <= 628 ? (
                <button
                  className='mr_25 cursor_pointer'
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={'#FF0000'}
                    width='18px'
                    height='18px'
                    className={showSide ? 'rotate_90 ease' : 'no_rotate ease'}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className='content_section'>
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {locations.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + 'name'}
                      onClick={() => setCurrentLocation(item.name)}
                      activeItem={currentLocation === item.name}
                    />
                  )
                })}
              </aside>
            ) : null}
            <section
              className='flex_column_left width_full'
              style={{ width: '100%' }}
            >
              <div className='mb_25 flex_row_top width_full'>
                {/* <div className="flex_column_left">
                  <a
                    href="#/"
                    //   onClick={() => {
                    //     setPostDragDisabled(!postDragDisabled);
                    //   }}
                  >
                    <p
                      className="bold_18_px mb_15"
                      onClick={() => setrRarrangeDisabled(!rearrangeDisabled)}
                    >
                      Re-arrange faqs
                    </p>
                  </a>
                  <Button text="Save changes" />
                </div> */}

                <Button
                  text='Create Voucher'
                  onClick={() => {
                    setvoucherForm({
                      ...voucherForm,
                      voucher_code: makeCode(5)
                    })
                    setShowCreateModal(true)
                  }}
                />
              </div>

              <div className='flex_row_top width_full mb_35 tab_column'>
                <label className='bold_18_px'>Vouchers</label>
                {/* <div className="flex_row">
                  <label
                    className="normal_18px cursor_pointer mr_30"
                    // onClick={() => {
                    //   ratingsHidden ? setRatings(ratingsCopy) : setRatings([]);
                    //   setRatingsHidden(!ratingsHidden);
                    // }}
                  >
                    Hide selected
                  </label>
                  <label className="normal_18px cursor_pointer">
                    Filter: {"All ratings"}
                  </label>
                </div> */}

                {showDateRange && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                )}
              </div>
              {!loading && !vouchers ? (
                <label className='bold_24px gray'>
                  There are no vouchers to show
                </label>
              ) : null}

              <ReactSortable
                list={vouchers}
                setList={setVouchers}
                className='width_full'
                animation={300}
                delayOnTouchStart={true}
                delay={1.5}
                disabled={rearrangeDisabled}
              >
                {vouchers &&
                  vouchers.map((item, i) => {
                    return (
                      <TextCard
                        key={item && item.id}
                        cardName={item && item.name}
                        cardLabel={item && item.description}
                        cardTime={`expires on ${moment(
                          item.to_expire_time
                        ).format('MMMM Do YYYY, h:mm:ss a')}`}
                        onChange={() => onCardSelect(i)}
                        onViewClick={() => {
                          setCurrentData(item)

                          setShowModal(true)
                        }}
                        viewText='Edit Voucher >'
                        withImage
                        image={item && item.images && item.images[0]}
                        className='image_text_card'
                        cardBody={
                          item && item.voucher_code + ' | ' + '₦' + item.amount
                        }
                      />
                    )
                  })}
              </ReactSortable>
              <PageLoading loading={loading} />
            </section>
          </section>
        </section>
      </div>
      <Modal
        title='Edit Voucher'
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        customContent={
          <div>
            <Input
              placeholder='voucher Name'
              value={currentData.name}
              onChange={e => setCurrentData({ ...currentData, name: e })}
              required
              containerClass='mb_15 '
            />
            <textarea
              className='text_area text_area_short mb_15'
              placeholder='Voucher description '
              value={currentData.description}
              onChange={e =>
                setCurrentData({ ...currentData, description: e.target.value })
              }
            />

            <Input
              placeholder='Voucher code'
              value={currentData.voucher_code}
              containerClass='mb_15 '
              onChange={e =>
                setCurrentData({ ...currentData, voucher_code: e.target.value })
              }
            />

            <Input
              placeholder='Max usage'
              value={currentData.max_usage}
              onChange={e =>
                setCurrentData({ ...currentData, max_usage: parseInt(e) })
              }
              required
              containerClass='mb_15 '
              type='number'
            />

            <Input
              placeholder='Amount'
              value={currentData.amount}
              onChange={e => setCurrentData({ ...currentData, amount: e })}
              required
              containerClass='mb_15 '
            />
            <ImageFile
              onChange={e => {
                onImageChange(e.target.files[0])
              }}
              src={currentData && currentData.images && currentData.images[0]}
              text='Upload voucher Image'
              className='mb_15'
            />
            
            <Select
              placeholder='Select customers'
              defaultValue={{
                value: currentData && currentData.user && currentData.user.id,
                label:
                  currentData &&
                  currentData.user &&
                  currentData.user.first_name + ' ' + currentData.user.last_name
              }}
              handleChange={e => {
                let i
                let copy = []
                for (i = 0; i < e.length; i++) {
                  copy.push(e[i].id)
                }
                setCurrentData({ ...currentData, users: copy })
              }}
              containerClass='width_300'
              options={users}
            />
           

            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  required
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry date:'
                  dateValue={moment(currentData.to_expire_time).format(
                    'YYYY-MM-DD'
                  )}
                  onDateChange={e =>
                    setExpDate(moment(new Date(e)).format('YYYY-MM-DD'))
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                />
              </div>
              <div className='ml_15 width_full'>
                <IconInput
                  required
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry time:'
                  dateValue={new Date(currentData.to_expire_time)}
                  onDateChange={e => setExpTime(new Date(e))}
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>
          </div>
        }
        customClass='feedback_modal'
        onSubmit={e => {
          e.preventDefault()
        }}
        saveText={submitting ? 'Updating...' : 'Update Voucher'}
        loading={submitting}
        withDel
        deleteText='Delete Voucher'
        delClick={() => setShowPrompt(true)}
      />

      <Modal
        title='Create Voucher'
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(!showCreateModal)}
        customContent={
          <div>
            <Input
              placeholder='voucher Name'
              value={voucherForm.name}
              onChange={e => setvoucherForm({ ...voucherForm, name: e })}
              required
              containerClass='mb_15 '
            />
            <textarea
              className='text_area text_area_short mb_15'
              placeholder='Voucher description '
              value={voucherForm.description}
              onChange={e =>
                setvoucherForm({ ...voucherForm, description: e.target.value })
              }
            />

           {voucherForm?.users?.length > 1?null: <Input
              placeholder='Voucher code'
              value={voucherForm.voucher_code}
              containerClass='mb_15 '
              onChange={e =>
                setvoucherForm({ ...voucherForm, voucher_code: e })
              }
            />}

            <Input
              placeholder='Max usage'
              value={voucherForm.max_usage}
              onChange={e =>
                setvoucherForm({ ...voucherForm, max_usage: parseInt(e) })
              }
              required
              containerClass='mb_15 '
              type='number'
            />

            <Input
              placeholder='Amount'
              value={voucherForm.amount}
              onChange={e => setvoucherForm({ ...voucherForm, amount: e })}
              required
              containerClass='mb_15 '
            />
            <ImageFile
              onChange={e => {
                onImageChange(e.target.files[0])
              }}
              src={imageDisplay}
              text='Upload voucher Image'
              className='mb_15'
            />
            <div className='flex_row_top tab_column'>
            <div className='mr_15 width_full'>
              
            <label className="normal_16px ">
							Select customers
						</label>
            <Select
              isLoading={loadingUsers}
              placeholder='Select customers'
              defaultValue={setSelectDefaults([])}
            
              //value={voucherForm?.users}
              handleChange={e => {
                let i
                let copy = []
                for (i = 0; i < e.length; i++) {
                  copy.push(e[i].id)
                }
                setvoucherForm({ ...voucherForm, users: copy })
              }}
              containerClass='width_full'
              options={users}
              isMulti
            />
            </div>
            <div className='ml_15 width_full mt-4'>
            <div className="info_important_cokitchen bold mb_25 ">
                    <div className="flex_row">
                    <h5 className=""><FaInfo width="20px" height="40px" /></h5> 
                  <p>
                 This signifies the amount of voucher codes to be created
                  </p>
                    </div>
                
                </div>
            <Input
                label="Count"
                placeholder="Count"
                value={voucherForm.count}
                onChange={(e) => {
                 setvoucherForm({...voucherForm, count:parseInt(e)})
                 
                }}
                required
                containerClass="mb_15 width_full"
                type="number"
                min="1"
              />
            </div>
        

            </div>
          
            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  required
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry date:'
                  dateValue={expDate}
                  onDateChange={e =>
                    setExpDate(moment(new Date(e)).format('YYYY-MM-DD'))
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                />
              </div>
              <div className='ml_15 width_full'>
                <IconInput
                  required
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry time:'
                  dateValue={new Date(expTime)}
                  onDateChange={e => setExpTime(new Date(e))}
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>
          </div>
        }
        customClass='feedback_modal'
        onSubmit={e => {
          e.preventDefault()
          formSubmit()
        }}
        saveText={submitting ? 'Creating...' : 'Create Voucher'}
        loading={submitting}
      />

      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={e => {
          e.preventDefault()
        }}
        saveText='Cancel'
        loading={submitting}
        title='Are you sure you want to delete this Voucher?'
        deleteText={submitting ? 'Deleting...' : 'Delete'}
      />
    </main>
  )
}
export default withAlert()(Vouchers)
