export const GET_ALL_KEYWORDS_URL = "marketing/keywords";
export const TAG_URLS = [
	{
		create: "marketing/create-meal-allergy-metadata",
		delete: "marketing/delete-meal-allergy-metadata",
	},

	{
		create: "marketing/create-meal-business-metadata",
		delete: "marketing/delete-meal-business-metadata",
	},
	{
		create: "marketing/create-meal-descriptive-metadata",
		delete: "marketing/delete-meal-descriptive-metadata",
	},
	{
		create: "marketing/create-meal-dietary-metadata",
		delete: "marketing/delete-meal-dietary-metadata",
	},
	// { create: "marketing/create-meal-tag", delete: "marketing/delete-meal-tag" },
	{
		create: "marketing/create-meal-keyword",
		delete: "marketing/delete-meal-keyword",
	},
	{
		create: "marketing/create-brand-keyword",
		delete: "marketing/delete-brand-keyword",
	},
	// {
	//   create: "marketing/create-brand-tag",
	//   delete: "marketing/delete-brand-tag",
	// },
	{
		create: "marketing/create-brand-descriptive-metadata",
		delete: "marketing/delete-brand-descriptive-metadata",
	},
	{
		create: "marketing/create-brand-business-metadata",
		delete: "marketing/delete-brand-business-metadata",
	},
];
// Posts
export const CREATE_POSTS_URL = "marketing/post";
export const GET_POSTS_URL = "internal/cokitchen/home-page-header";
export const UPDATE_POSTS_ARRANGEMENT_URL = "marketing/home-page-header";

// Users
export const GET_USERS_URL = "marketing/user";
// Feedback
export const GET_APP_FEEDBACK_URL = "marketing/app-feedback";
// meal allergy
export const MEAL_ALLERGY_URL = "marketing/keyword";
// faqs
export const FAQ_URL = "marketing/faq";
export const FAQ_ARRANGEMENT_URL = "marketing/faq-arrangement";
// brand info
export const BRAND_INFO_URL = "marketing/brand";
export const GET_BRAND_WORKING_HOUR_URL = "marketing/brand/working-hours/";
export const POST_BRAND_WORKING_HOUR_URL = "marketing/brand/working-hours";
// Meal info
export const DELETE_ADDON_CATEGORIES = "marketing/del-meal-addon-category";
export const MEAL_INFO_URL = "marketing/meal";
export const MULTIPLE_MEAL_URL = "marketing/create-meal-for-multiple-meal-category-ids-and-brand-ids";
export const GET_MEAL_WORKING_HOUR_URL = "marketing/meal/working-hours/";
export const CREATE_MULTIPLE_ADDONS ="marketing/create-multiple-addons-existing-categories";
export const POST_MEAL_WORKING_HOUR_URL = "marketing/meal/working-hours";
export const DUPLICATE_ADDON_CATEGORIES = "marketing/meal/meal-addon-category/duplicate"
// Shop Feed

export const SHOP_FEED_URL = "marketing/feed";
export const EDIT_SHOP_FEED = "marketing/feed/";

// Cokitchens
export const COKITCHENS_URL = "marketing/cokitchen";
export const GET_BRANDS_URL = "internal/brand";
export const GET_COKITCHENS_URL = "internal/cokitchen";
export const GET_COKITCHENS_  = "internal/cokitchen/specific";

// Polygons Locations
export const COKITCHEN_POLYGONS_URL = "marketing/cokitchen-polygon";
export const COKITCHEN_DELIVERY_SURGE_URL = "marketing/surge";
// Explore
export const GET_EXPLORE_KEYWORD = "internal/cokitchen-explore-keyword";
export const EXPLORE_KEYWORD_URL = "marketing/cokitchen-explore-keyword";
// Meal
export const GET_MEAL_URL = "internal/meal";
export const DELETE_MEAL_URL = "marketing/meal";
export const GET_MEAL_CATEGORY_URL = "internal/cokitchen/brand/meal-addons";
export const UPDATE_MEAL_CATEGORY_URL = "marketing/meal-category";
export const GET_MEALS_ = "internal/brand/meals/";

export const GET_MEAL_CATEGORY_SELECTION_TYPE_URL =
	"internal/meal-category-selection-type";
export const GET_MEAL_CATEGORIES_URL = "internal/meal-category";
export const SUPER_MEAL_CATEGORY_URL = "marketing/super-meal-category";
export const REARRANGE_MEAL_CATEGORY_URL = "marketing/meal-category/position";
export const REARRANGE_MEAL_URL = "marketing/meal/position";
export const CREATE_BULK_MEALS_URL = "marketing/meal/bulk-creation";

export const DUPLICATE_ADDON_CATEGORY= "marketing/meal-addon-category/duplicate"
export const GET_ADDONS_CATEGORIES = "internal/getMealAddonCategoriesForMultipleMeals"
export const CREATE_CATEGORIES_ADDONS = "marketing/create-multiple-addons-and-categories"

// Deals
export const GET_DEAL_TYPES_URL = "internal/deal-types";
export const GET_DEALS_URL = "internal/cokitchen/deal";
export const DEALS_URL = "marketing/deal";

// Vouchers
export const GET_VOUCHERS = "marketing/voucher";
export const CREATE_VOUCHERS = "marketing/voucher";

// Notification
export const CREATE_NOTIFICATION = "marketing/notification";
export const GET_SCHEDULE_NOTIFICATION = "marketing/get-schedule-notification";
export const CREATE_SCHEDULE_NOTIFICATION =
	"marketing/create-schedule-notification";
export const DELETE_SCHEDULE_NOTIFICATION =
	"marketing/delete-schedule-notification";
export const UPDATE_SCHEDULE_NOTIFICATION =
	"marketing/update-schedule-notification";

// Reviews
export const GET_BRAND_REVIEWS = "marketing/brand/reviews/";

// App Versions
export const GET_APP_VERSIONS = "internal/versions";
export const CREATE_APP_VERSIONS = "marketing/versions";
