import React, { useState, useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {
	TileLayer,
	FeatureGroup,
	Polygon,
	Marker,
	MapContainer,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import osm from "./osm-providers";
import L from "leaflet";
import { POLYGON_COLORS } from "../../helpers/constants";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	iconUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	shadowUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const AppMap = ({
	lat = "6.461928747152594",
	lng = "3.557585092620834",
	setCord,
	setCords,
	withRect,
	withMarker,
	color,
	positions = [
		[6.461928747152594, 3.557585092620834],
		[6.47122476961908, 3.558840366439804],
		[6.4704572146071815, 3.548293920593246],
		[6.470350609652184, 3.5439648352432096],
	],
	rectPolygons = [
		[
			[6.461928747152594, 3.557585092620834],
			[6.47122476961908, 3.558840366439804],
			[6.4704572146071815, 3.548293920593246],
			[6.470350609652184, 3.5439648352432096],
		],
	],
	withAllRects,
}) => {
	const [center, setCenter] = useState({ lat: lat, lng: lng });
	const ZOOM_LEVEL = 12;
	const mapRef = useRef();

	const _created = (e) => {
		setCord && setCords(e.layer._latlngs);
	};

	const _edited = (e) => {
		const layers = e?.layers?._layers
		if (Object.keys(layers).length === 1)
			Object.values(layers).map((item) => {
				setCords(item?._latlngs[0])
			})
	};
	useEffect(() => {
		setCenter({ lat: lat, lng: lng });
	}, []);

	return (
		<MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
			<FeatureGroup >
				<EditControl
					position="topright"
					onCreated={_created}
					draw={{
						rectangle: false,
						circle: false,
						circlemarker: false,
						marker: false,
						polyline: true,
						polygon: false,
					}}
					onEdited={_edited}
				/>
				{withRect && !withAllRects ? <Polygon positions={positions} /> : null}

				{withRect && withAllRects
					? rectPolygons?.map((item, i) => {
						return (
							<Polygon positions={item && item.polygon} key={i + "map"} color={POLYGON_COLORS[i]?.color_code} fillColor={POLYGON_COLORS[i]?.color_code} fillOpacity={.4} dashArray={2} weight={2} />
						);
					})
					: null}
			</FeatureGroup>
			<TileLayer
				url={osm.maptiler.url}
				attribution={osm.maptiler.attribution}
			/>
			{withMarker ? (
				<Marker position={[lat, lng]}>
					<span>Cokitchen Lekki</span>
				</Marker>
			) : null}
		</MapContainer>
	);
};

export default AppMap;
