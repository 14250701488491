export const CSV_TITLES_NEW = {
  numberString: "ItemNumber",
  nameString: "Item Name",
  prepString: "Prepration Time (mins)",
  categoryString: "Category Name",
  rateString: "Base Rate (inclusive of tax)",
  descString: "Item Description",
  newString: "Meal is new",
  brandString: "brand",
  amountString: "Amount",
  summaryString: "summary",
  imageString: "Image",
  calorieString: "Calories",
  addonString: "Meal is addon",
  addonsString: "addons",
  parentString: "Meal parents and addon categories",
  parentAltString: "Parent Meals",
  addonCategoryString: "Addon Category",
  addonNameString: "Addon Name",
  addonPriceString: "Addon Price",
};