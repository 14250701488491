import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import { Button } from "../../components/buttons";
import Select from "../../components/select";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { FaInfo } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { CheckBox } from "../../components/checkBoxes";
import MealLayout from "../../components/layout";
import Loader from "react-loader-spinner";

const DeleteAddonCat = ({ alert }) => {
  const [categoryForm, setCategoryForm] = useState({
    meal_parent_ids: [],
    meal_addon_cateory_ids: [],
  });
  const [brandsAddonMeals, setBrandsAddonMeals] = useState([]);
  const [brandsAddon, setBrandsAddon] = useState([]);
  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  const [ckLoading, setCkLoading] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [brands, setBrands] = useState(null);
  const [isAddon, setISAddon] = useState(true);
  const [SelectedParentMeals, setSelectedParentMeals] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [mealCategoryArray, setMealCategoryArray] = useState([]);
  const [pageWidth, setPageWidth] = useState("");
  const [showSide, setShowSide] = useState(true);
  const [brand, setBrand] = useState("");
  const [mealCategoryModal, showMealCategoryModal] = useState(false);
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(
    "delete addon category"
  );
  const [cokitchens, setCokitchens] = useState("");
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [showNew, setShowNew] = useState("new");
  const [selectBrand, setSelectBrand] = useState(true);
  const [addonCategories, setAddonCategories] = useState([]);
  const [selectedAddonCategories, setSelectedAddonCategories] = useState([]);
  const [selectedAddonCategory, setSelectedAddonCategory] = useState([]);
  const [mealsLoading, setMealsLoading] = useState(false);
  const [loadingAddonCategory, setLoadingAddonCategory] = useState(false);
  const [selectNew, setSelectNew] = useState(false);
  const [allMeals, setAllMeals] = useState([]);
  const [allAddons, setAllAddons] = useState([]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (
      !BrandStore.dataAltCached &&
      !MealCategoryStore.dataCached &&
      !CommonStore.dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        CommonStore.getCokitchensOnly(alert),
        MealCategoryStore.getMealCategorySelection(alert),
      ]);
    } else {
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt);
      }

      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchensOnly(alert);
        setCokitchens(CommonStore.cokitchens);
        setCkLoading(false);
      }

      if (!MealCategoryStore.dataCached) {
        await Promise.all([MealCategoryStore.getMealCategorySelection(alert)]);
      }
    }

    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });
    setBrands(BrandStore.brandsAlt);
    setBrandsAddon(MealCategoryStore.mealCategories);
    setMealCategoryTypesOptions();
    mergeMealCategories();
  }, []);

  const mergeMealCategories = () => {
    let i;
    let options = [];
    let curr = MealCategoryStore.mealCategories || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.meals];
    }
    setBrandsAddonMeals(options);
  };
  const setMealCategoryTypesOptions = async () => {
    let opts = MealCategoryStore.mealCategoriesSelection;

    setmealCategorySelections(opts);
  };

  const handleBranchCHeckBox = (id) => {
    isChecked?.includes(id)
      ? setIsChecked(isChecked?.filter((item) => item !== id))
      : setIsChecked([id]);
  };
  const getMeals = async (item) => {
    const { getMealsOnly, getAddonsOnly } = BrandStore;
    setMealsLoading(true);
    await getMealsOnly(alert, item.id);
    setMealsLoading(false);

    setAllMeals(BrandStore.mealsOnly);
  };
  const getAddonCategories = async (data) => {
    let parentMeals = [...categoryForm.meal_parent_ids];
    const { getAddonCategories } = BrandStore;
    setLoadingAddonCategory(true);
    let body =[]
    data.map((item)=> body.push(item?.id))
   
    await getAddonCategories(alert, {meal_ids: body});
    let addonCat = [...BrandStore.mealAddonCategories];
    let addonCatOptions = [];

    addonCat.map((item) =>
      parentMeals.map((itm) => {
        if (item.meal_parent_id === itm.id) {
          let body = {
            name: `${item.name} - ${itm.name}`,
            id: item.id,
          };
          addonCatOptions.push(body);
        }
      })
    );
    setAddonCategories(addonCatOptions);

    setLoadingAddonCategory(false);
    setAllMeals(BrandStore.mealsOnly);
  };
  const deleteAddonCategories = async (item) => {
    const { deleteAddonCategories } = BrandStore;
     if (SelectedParentMeals.length===0) {
      alert.show("Parent meal required", {
        type: "info",
      });
  }
    else if (categoryForm.meal_parent_ids.length===0) {
      alert.show("Addon category required", {
        type: "info",
      });
  }

else{
  setDeleteLoading(true);
  let body = { meal_addon_category_ids: selectedAddonCategories.copy };
  await deleteAddonCategories(alert, body);
  setAddonCategories(BrandStore.mealAddonCategories);
  setDeleteLoading(false);
  setCategoryForm({
    ...categoryForm,
    meal_parent_ids: [],
  });
  setSelectedParentMeals()
setSelectedAddonCategories([]);
setSelectedAddonCategory();

  
  
  setIsChecked([""]);
}


   
  };

  return (
    <MealLayout
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      headerTitle={"Delete Meal Addon"}
      content={"Add Meals to brands"}
      handleChange={setCurrentCokitchen}
      pageLoading={ckLoading}
      loading={loading}
      options={cokitchens}
      children={
        <Fragment>
          {" "}
          <section className="width_full new_addon_container">
            <div className="radio_class width_full radio_flex">
              {ckLoading ? (
                <div className="mb_25">
                  <Loader
                    type="ThreeDots"
                    color="#ff000060"
                    height={10}
                    width={50}
                  />
                </div>
              ) : (
                <div className="">
                   <div className="info_important bold">
       <div className="flex_row">          <h5 className=""><FaInfo width="30px" height="40px" /></h5> 
                  <p>
                    Select brand where you want to delete addon category from
                  </p>
                  </div>
                </div>
                <div className="radio_class width_full radio_flex">
                  {brands
                    ?.filter(
                      (item) =>
                        item?.cokitchen_id === form || item.item_type === "FOOD"
                    )
                    .map((item, i) => {
                      let category = [...mealCategoryArray];
                      let curr = item;
                      return (
                        <div>
                          <CheckBox
                            id={i}
                            checked={isChecked.includes(item?.id)}
                            onClick={(e) => {
                              showMealCategoryModal(true);
                              setCategoryForm({
                                ...categoryForm,
                                brand_id: item,
                              });
                              handleBranchCHeckBox(item?.id);
                              getMeals(item);
                              setCategoryForm({
                                ...categoryForm,
                                meal_parent_ids: [],
                              });
                              setSelectedParentMeals([]);
                            }}
                            label={item.name}
                          />

                          {category
                            .filter((item) => item.brand_id === curr.id)
                            ?.map((item) => (
                              <div
                                className=""
                                onClick={() => {
                                  showMealCategoryModal(true);
                                  setForm({
                                    ...form,
                                    brand_id: curr,
                                  });
                                }}
                              >
                                <h6 className="meal_category_link">
                                  {item.name}
                                </h6>
                              </div>
                            ))}
                        </div>
                      );
                    })}
                </div>
                </div>
              )}
            </div>

            <div>
              {isChecked.length === 0 ? null : (
                <div className="flex_row mt_35">
                     <Tippy
                    style={{zIndex:"100"}}
            content={`Select meal that addons will be deleted from`}
          >
            <div style={{ marginRight: "5px" }}>
              <AiFillQuestionCircle />
            </div>
          </Tippy>
          <Select
                  abel="Select parent meal"
                  placeholder="Select parent meal"
                  value={SelectedParentMeals || []}
                  isMulti
                  handleChange={(e) => {
                    let i;
                    let copy = [];
                    for (i = 0; i < e.length; i++) {
                      copy.push(e[i]);
                    }
                    setCategoryForm({
                      ...categoryForm,
                      meal_parent_ids: copy,
                    });
                    setSelectedParentMeals(e);
                    getAddonCategories(copy);
                  }}
                  containerClass=""
                  options={allMeals}
                  name="name"
                  id="id"
                  isLoading={ckLoading || mealsLoading}
                />

                </div>
             
              )}

              {addonCategories.length === 0 && isChecked.length === 0  ? null : (
                <div >
                  <div className="flex_row mt_25">
                     <Tippy
                    style={{zIndex:"100"}}
            content={`Select addon categories you want to delete`}
          >
            <div style={{ marginRight: "5px" }}>
              <AiFillQuestionCircle />
            </div>
          </Tippy>
                  <Select
                    label="Select addon category"
                    placeholder="Select addon category to be deleted"
                    value={selectedAddonCategory || []}
                    isMulti
                    handleChange={(e) => {
                      let i;
                      let copy = [];
                      for (i = 0; i < e.length; i++) {
                        copy.push(e[i].id);
                      }
                      setSelectedAddonCategories({
                        ...selectedAddonCategories,
                        copy,
                      });
                      setSelectedAddonCategory(e);
                    }}
           
                    options={addonCategories}
                    name="name"
                    id="id"
                    isLoading={loadingAddonCategory || ckLoading}
                  />
                      </div>
                  <div className="">
                    <Button
                      text="Delete Addon Category"
                      onClick={() => {
                        deleteAddonCategories();
                      }}
                      loading={deleteLoading || ckLoading}
                      className="mb_30"
                    />
                  </div>
            
                </div>
              )}
            </div>
          </section>
        </Fragment>
      }
      backArrow
    />
  );
};
export default withAlert()(DeleteAddonCat);
