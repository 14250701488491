import React, { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { Clock, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Select from "../../components/select";
import { Tags } from "../../components/appTag";
import Loader from "react-loader-spinner";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import axios from "axios";
import Tippy from "@tippyjs/react";
import { observer } from "mobx-react";
import { AiFillQuestionCircle } from "react-icons/ai";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";
import NewNavItem from "../../components/newNavItem";
import PageLoading from "../../components/loader";
import {
  ADD_MEAL,
  ADD_MEAL_CSV,
  ITEM_INFO,
  ADD_MEAL_ADDON,
} from "../../router/router";
import { useHistory } from "react-router-dom";
import IconInput from "../../components/inputs/iconInput";
import moment from "moment";
import Prompt from "../../components/modal/prompt";
import CsvParser from "../../components/csvParser";
import csvToJson from "convert-csv-to-json";
import MealsList from "../../components/mealsList";
import CsvParserNew from "../../components/csvParserNew";
import { CSV_TITLES_NEW } from "../../components/csvParserNew/utils";
import MealLayout from "../../components/layout";
import { Fragment } from "react";
import RadioGroup from "../../components/inputs/RadioGroup";
import { uploadFilesAndGetUrl } from "../../helpers/upload";

const ItemInfo = ({ alert }) => {

  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  const [imageDisplay, setImageDisplay] = useState(null);
  const [csvFile, setCsvFile] = useState(null);

  const [image, setImage] = useState(null);
  const [mealActive, setMealActive] = useState(false);
  const [mealNew, setMealNew] = useState(false);
  const [mealCategory, setMealCategory] = useState(false);

  const [showSide, setShowSide] = useState(true);
  const [showTagDrop, setShowTagDrop] = useState(false);
  const [showDietaryDrop, setShowDietaryDrop] = useState(false);
  const [showAllergenDrop, setShowAllergenDrop] = useState(false);
  const [showDescriptionDrop, setShowDescriptionDrop] = useState(false);
  const [showBusinessDrop, setShowBusinessDrop] = useState(false);
  const [addons, setAddons] = useState(false);

  const [pageWidth, setPageWidth] = useState("");

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [dietaryTags, setDietaryTags] = useState([]);
  const [dietarySuggestions, setDietarySuggestions] = useState([]);

  const [allergenTags, setAllergenTags] = useState([]);
  const [allergenSuggestions, setAllergenSuggestions] = useState([]);

  const [descriptionTags, setDescriptionTags] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);

  const [businessTags, setBusinessTags] = useState([]);
  const [businessSuggestions, setBusinessSuggestions] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [checkedMeals, setCheckedMeals] = useState([]);
  const [csvAddonData, setCsvAddonData] = useState([]);
  const [checkedAddonMeals, setCheckedAddonMeals] = useState([]);
  const [titles, setTitles] = useState([]);
  const [titlesAddon, setTitlesAddon] = useState([]);

  const [csvMeals, setCsvMeals] = useState([]);
  const [csvAddons, setCsvAddons] = useState([]);
  const [checkedNewMeals, setCheckedNewMeals] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [form, setForm] = useState({
    name: "",
    meal_category_id: "",
    amount: "",
    brand_id: "",
    is_addon: false,
    preparation_time: "",
    description: "",
    summary: "",
    images: [],
    available_no: 0,
    calories:"",
    meal_keywords: [],
    meal_descriptive_metadatas: [],
    meal_business_metadatas: [],
    meal_dietary_metadatas: [],
    meal_allergy_metadatas: [],
  });
  const [adding, setAdding] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [newPhotoDisplay, setNewPhotoDisplay] = useState(null);
  const [altLoading, setAltLoading] = useState(false);
  const [isAddonCsv, setIsAddonCsv] = useState(false);

  const [showMeal, setShowMeal] = useState("brand");

  const [currentLocation, setCurrentLocation] = useState("edit meal");

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    let { formatTags, dataCached } = TagsStore;
    setLoading(true);
    setCkLoading(true);
    if (
      !BrandStore.dataAltCached ||
      !MealCategoryStore.dataCached ||
      !BrandStore.dataCached ||
      !CommonStore.dataCached ||
      !dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        CommonStore.getCokitchens(alert),
      ]);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });
    setBrands(BrandStore.brandsAlt);
    setAllTags();
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = currentCokitchen.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name,
        value: curr[i].id,
        index: i,
      });
    }
    setCurrentBrands(options?.filter((item) => item?.item_type === "FOOD"));
  }, [currentCokitchen]);

  // Set brand data based on current brand

  // Set meal data based on current meal
  useEffect(() => {
    setAllTags();
    let i, j, k, l, m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let keyCopy = meal?.meal_keywords || [];
    let descCopy = meal?.meal_descriptive_metadatas || [];
    let bussCopy = meal?.meal_business_metadatas || [];
    let dietCopy = meal?.meal_dietary_metadatas || [];
    let allergyCopy = meal?.meal_allergy_metadatas || [];
    let imagesCopy = (meal && meal?.images && meal?.images[0]) || "";
    let activeCopy = (meal && meal?.active) || false;
    let newCopy = (meal && meal?.new) || false;
    let mealCategoryCopy = (meal && meal?.meal_category_id) || "";

    let nameCopy = (meal && meal?.label) || "";
    let amountCopy = (meal && meal?.amount) || "";
    let preparationTimeCopy = (meal && meal?.preparation_time) || "";
    let descriptionCopy = (meal && meal?.description) || "";
    let summaryCopy = (meal && meal?.summary) || "";
    let availableNoCopy = (meal && meal?.available_no)
    let caloriesCopy = meal && meal?.calories;
    setImageDisplay(imagesCopy);
    setMealActive(activeCopy);
    setMealNew(newCopy);
    setMealCategory(mealCategoryCopy);
    setForm({
      ...form,
      name: nameCopy,
      amount: amountCopy,
      available_no: availableNoCopy,
      calories:caloriesCopy,
      preparation_time: preparationTimeCopy,
      description: descriptionCopy,
      summary: summaryCopy,
      new: meal?.new || false,
    });
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        ...keyCopy[i],
        label: keyCopy[i].name,
        value: keyCopy[i].id,
      });
    }
    setTags(keyOptions);

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        ...dietCopy[j],
        label: dietCopy[j].name,
        value: dietCopy[j].id,
      });
    }
    setDietaryTags(dietOptions);

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        ...allergyCopy[k],
        label: allergyCopy[k].name,
        value: allergyCopy[k].id,
      });
    }

    setAllergenTags(allergyOptions);

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        ...descCopy[l],
        label: descCopy[l].name,
        value: descCopy[l].id,
      });
    }
    setDescriptionTags(descOptions);

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        ...bussCopy[m],
        label: bussCopy[m].name,
        value: bussCopy[m].id,
      });
    }
    setBusinessTags(bussOptions);
  }, [meal]);


  //  Load Current working hours based on current meal

 




  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  // Sort tags
  const setAllTags = () => {
    let { tags } = TagsStore;
    let i;
    const tagsCopy = JSON.parse(JSON.stringify(tags));
    let curr =
      tagsCopy.meal_keyword
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setSuggestions(curr);
    let currDietary =
      tagsCopy.meal_dietary_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setDietarySuggestions(currDietary);
    let currAllergen =
      tagsCopy.meal_allergy_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setAllergenSuggestions(currAllergen);
    let currDescriptive =
      tagsCopy.meal_descriptive_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setDescriptionSuggestions(currDescriptive);
    let currBuss =
      tagsCopy.meal_business_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setBusinessSuggestions(currBuss);
  };

  // Format tags to be submitted
  const formatTags = () => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let allOptions = {};
    let keyCopy = tags;
    let descCopy = descriptionTags;
    let bussCopy = businessTags;
    let dietCopy = dietaryTags;
    let allergyCopy = allergenTags;

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        name: allergyCopy[k].name,
        id: allergyCopy[k].id,
      });
    }
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        name: keyCopy[i].name,
        id: keyCopy[i].id,
      });
    }

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        name: dietCopy[j].name,
        id: dietCopy[j].id,
      });
    }

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        name: descCopy[l].name,
        id: descCopy[l].id,
      });
    }

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        name: bussCopy[m].name,
        id: bussCopy[m].id,
      });
    }

    allOptions = {
      key: keyOptions,
      desc: descOptions,
      buss: bussOptions,
      diet: dietOptions,
      allergy: allergyOptions,
    };

    return allOptions;
  };

  // Sort working hours format for the api request body
    // Update meal
  const mealUpdate = async (e) => {
    e.preventDefault();
    const { updateMeal } = BrandStore;
    if (!brand) {
      alert.show("Please select a brand to update it's meal", {
        type: "info",
      });
    } else if (!meal) {
      alert.show("Please select a meal to update", {
        type: "info",
      });
    } else {
      setSubmitting(true);
      let url;
      if (image) {
        const imageUrls = await uploadFilesAndGetUrl([image], true)
        setImageDisplay(imageUrls[0]);
        url = imageUrls[0]
      }
      const allTags = await formatTags();
      let payload = {
        meal_id: meal.id,
        active: mealActive,
        new: mealNew,
        meal_category_id: mealCategory,
        images: url || imageDisplay ? [url || imageDisplay] : [],
        meal_keywords: allTags.key,
        meal_descriptive_metadatas: allTags.desc,
        meal_business_metadatas: allTags.buss,
        meal_dietary_metadatas: allTags.diet,
        meal_allergy_metadatas: allTags.allergy,
        name: form?.name?.replace(" 📸", ""),
        amount: form?.amount,
        preparation_time: form?.preparation_time,
        available_no: form?.available_no,
        calories:form?.calories,
        description: form?.description,
        summary: form?.summary,
      };

      Object.keys(payload).forEach((key, index) => {
        if (
          payload[key] === "" ||
          payload[key] == {} ||
          payload[key] == undefined
        ) {
          if (key !== "new" || key !== "active") {
            delete payload[key];
          }
        }
      });

      await updateMeal(alert, payload);
     
      setSubmitting(false);
      alert.show("Fetching updates...", {
        type: "info",
      });
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCurrentCokitchen({
        brands:
          CommonStore?.cokitchens &&
          CommonStore?.cokitchens[0] &&
          CommonStore?.cokitchens[0].brands,
        name: "All users (all locations)",
      });
      setCurrentBrands(
        CommonStore?.cokitchens &&
          CommonStore?.cokitchens[0] &&
          CommonStore?.cokitchens[0].brands
      );
      // let mealOptions = [];
      // let mealCopy =
      //   (CommonStore?.cokitchens &&
      //     CommonStore?.cokitchens[0] &&
      //     CommonStore?.cokitchens[0].brands &&
      //     CommonStore?.cokitchens[0].brands[brand.index] &&
      //     CommonStore?.cokitchens[0].brands[brand.index].meals) ||
      //   [];
      // for (let l = 0; l < mealCopy.length; l++) {
      //   mealOptions.push({
      //     ...mealCopy[l],
      //     label: mealCopy[l].images[0]
      //       ? mealCopy[l].name + " 📸"
      //       : mealCopy[l].name,
      //     value: mealCopy[l].id,
      //   });
      // }

      //     setMeals(mealOptions);
      setAllTags();
    }
  };
  const changeBrand = (e) => {
    setBrand(e);
    setCurrentBrandIndex(e.index);
    const { getMealsOnly, getAddonsOnly } = BrandStore;
    setBrandsLoading(true);
    const getCurrentMeals = async () => {
      await getMealsOnly(alert, e?.id);
      await getAddonsOnly(alert, e?.id);

      setBrandsLoading(false);

      let mealOptions = [];
      let mealCopy = BrandStore.mealsOnly;
      console.log(BrandStore.addonsOnly);
      for (let l = 0; l < mealCopy?.length; l++) {
        mealOptions.push({
          ...mealCopy[l],
          label: mealCopy[l].name,
          name: mealCopy[l]?.images[0]
            ? mealCopy[l].name + " 📸"
            : mealCopy[l].name,
          value: mealCopy[l].id,
          is_addon: mealCopy[l].is_addon,
        });
      }
      let addonOptions = [];
      let addonCopy = BrandStore.addonsOnly;
      for (let l = 0; l < addonCopy?.length; l++) {
        addonOptions.push({
          ...addonCopy[l],
          label: addonCopy[l].name,
          name: addonCopy[l]?.images[0]
            ? addonCopy[l].name + " 📸"
            : addonCopy[l].name,
          value: addonCopy[l].id,
          is_addon: addonCopy[l].is_addon,
        });
      }

      setMeals(mealOptions.filter((item) => item.is_addon === false));

      console.log(addonOptions);
      setAddons(addonOptions);
    };
    getCurrentMeals();
    setAllTags();

    setMeal("");
    setImageDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
  };
  const onFileChange = (e) => {
    setCsvFile(e);
    let csvF = URL.createObjectURL(e);
    let json = csvToJson.getJsonFromCsv(csvF);
    for (let i = 0; i < json.length; i++) {}
  };
  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const createNewMeals = async () => {
    const { createBulkMeal } = BrandStore;
    let body = JSON.parse(JSON.stringify(checkedNewMeals));
    body = body?.map((item) => {
      let bodyAddons = item[CSV_TITLES_NEW.addonsString]?.map((ele) => {
        return {
          meal_category_name: ele[CSV_TITLES_NEW.addonCategoryString],
          name: ele[CSV_TITLES_NEW.addonNameString],
          amount: ele[CSV_TITLES_NEW.addonPriceString],
        };
      });

      return {
        name: item[CSV_TITLES_NEW.nameString],
        description: item[CSV_TITLES_NEW.descString],
        preparation_time: item[CSV_TITLES_NEW.prepString],
        amount: item[CSV_TITLES_NEW.rateString],
        meal_category_name: item[CSV_TITLES_NEW.categoryString],
        addons: bodyAddons,
        brand_id: brand?.id,
        item_type: "FOOD",
      };
    });

    if (!brand) {
      alert.show("Select a brand for the new meals!", {
        type: "info",
      });
    } else if (!csvMeals[0]) {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    } else if (!csvAddons[0]) {
      alert.show("Please upload addon mapping!", {
        type: "info",
      });
    } else {
      // console.log("body",body,"checkedNewMeals",checkedNewMeals)
      setAddingNew(true);
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
      await createBulkMeal(alert, { meals: body });
      setAddingNew(false);
      setShowPrompt(false);
    }
  };

  // Submit add form
  const formSubmit = async () => {
    const { getBrands, createMeal } = BrandStore;
    let body = form;
    if (!body?.cokitchen_id) {
      alert.show("Cokitchen is required!", {
        type: "info",
      });
    } else {
      try {
        setAdding(true);

        let res;

        res = await createMeal(alert, body);

        if (res) {
          setAdding(false);
          setShowCreateModal(false);

          alert.show("Fetching updates...", {
            type: "info",
          });

          await getBrands(alert, false);
          await CommonStore.getCokitchens(alert);
          setCokitchens(CommonStore.cokitchens);
          await BrandStore.getBrandsAlt(alert);
          setBrands(BrandStore.brandsAlt);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();
      }
    }
  };

  const deleteMeal = async () => {
    const { deleteMeals } = BrandStore;
    setDeleting(true);
    await deleteMeals(meal?.id, alert);
    setDeleting(false);
    setShowPrompt(false);
  };

  const onChange = (e) => {
    setShowMeal(e.target.value);
    setForm({
      name: "",
      meal_category_id: "",
      amount: "",
      brand_id: "",
      is_addon: false,
      preparation_time: "",
      description: "",
      available_no:"",
      summary: "",
      images: [],
      meal_keywords: [],
      meal_descriptive_metadatas: [],
      meal_business_metadatas: [],
      meal_dietary_metadatas: [],
      meal_allergy_metadatas: [],
    });
    setShowTagDrop(false);
    setShowDietaryDrop(false);
    setShowAllergenDrop(false);
    setShowDescriptionDrop(false);
    setShowBusinessDrop(false);
  };
  return (
    <MealLayout
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      headerTitle={"Edit Meal"}
      content={
        "Meal Info contains editable information on the meals for each brand. To edit the meals under each brand, choose the brand from the topmost select and pick the meal to be edited from the second select.Meals with the photo emoji 📸 already have images"
      }
      handleChange={setCurrentCokitchen}
      pageLoading={ckLoading}
      loading={loading}
      options={cokitchens}
      children={
        <Fragment>
          <section className="cards_container px_50 seond_width_desktop_2 mb_30">
            <div className="mb_30">
              <RadioGroup
                name={"meal"}
                selectedOption={showMeal}
                options={[
                  { title: "Meals", value: "brand" },
                  { title: "Addons", value: "addons" },
                ]}
                onChange={onChange}
              />
            </div>
            <form
              onSubmit={(e) => mealUpdate(e)}
              className="flex_column_center"
            >
              <Select
                placeholder="Select brand"
                defaultValue={brand}
                handleChange={(e) => {
                  changeBrand(e);
                }}
                isLoading={ckLoading}
                containerClass="width_full"
                options={currentBrands}
                name="name"
                id="id"
              />

              {showMeal === "brand" ? (
                <>
                  <Select
                    placeholder="Select meal"
                    value={meal.is_addon ? "" : meal}
                    handleChange={(e) => setMeal(e)}
                    containerClass="width_full"
                    options={meals}
                    name="name"
                    id="id"
                    isLoading={brandsLoading || ckLoading}
                    isDisabled={brandsLoading}
                  />{" "}
                  <Select
                    placeholder="Select meal category"
                    handleChange={(e) => {
                      setMealCategory(e?.id);
                    }}
                    containerClass="width_full"
                    options={
                      brands?.find((item) => item?.id === brand?.id)
                        ?.meal_categories
                    }
                    value={
                      brands
                        ?.find((item) => item?.id === brand?.id)
                        ?.meal_categories?.find(
                          (itm) => itm?.id === meal?.meal_category_id
                        )?.id
                        ? {
                            id: brands
                              ?.find((item) => item?.id === brand?.id)
                              ?.meal_categories?.find(
                                (itm) => itm?.id === meal?.meal_category_id
                              )?.id,

                            name: brands
                              ?.find((item) => item?.id === brand?.id)
                              ?.meal_categories?.find(
                                (itm) => itm?.id === meal?.meal_category_id
                              )?.name,
                          }
                        : ""
                    }
                    name="name"
                    id="id"
                    isLoading={ckLoading}
                  />
                </>
              ) : (
                <Select
                  placeholder="Select meal addon"
                  value={meal.is_addon ? meal : ""}
                  handleChange={(e) => setMeal(e)}
                  containerClass={"width_full"}
                  options={addons}
                  name="name"
                  id="id"
                  isLoading={brandsLoading || ckLoading}
                  isDisabled={brandsLoading}
                />
              )}

              <Input
                placeholder="Enter meal name"
                value={form.name}
                onChange={(e) => {
                  setForm({ ...form, name: e });
                }}
                required
                containerClass="mb_15 width_full"
                label="Meal name"
              />

              <Input
                placeholder="Enter meal amount"
                value={form.amount}
                onChange={(e) => {
                  setForm({ ...form, amount: e });
                }}
                required
                containerClass="mb_15 width_full"
                type="number"
                label="Meal amount"
              />

              <Input
                placeholder="Enter meal preparation time"
                value={form.preparation_time}
                onChange={(e) => {
                  setForm({ ...form, preparation_time: e });
                }}
                required
                containerClass="mb_15 width_full"
                label="Meal preparation time (minutes)"
              />
              <Input
                placeholder="Quantity available"
                value={form.available_no}
                onChange={(e) => {
                  setForm({ ...form, available_no: e });
                }}
                type="number"
                containerClass="mb_15 width_full"
                label="Quantity available (leave blank if infinite)"
              />
              <Input
                placeholder="Calories"
                value={form.calories}
                onChange={(e) => {
                  setForm({ ...form, calories: e });
                }}
                type="number"
                containerClass="mb_15 width_full"
                label="Calories"
              />

              <div className="flex_row_left width_full mb_35">
                <div className="flex_column_left mr_25">
                  <ImageFile
                    onChange={(e) => {
                      onImageChange(e.target.files[0]);
                    }}
                    src={imageDisplay}
                    text="Upload Image"
                  />
                  <label className="gray_label mt_10">Main brand image:</label>
                </div>
              </div>

              {meal?.name && (
                <div className="flex_row_left mb_25 width_full">
                  <CheckBox
                    id="turn_on_off"
                    label={
                      (mealActive ? "Turn-off" : "Turn-on") + " " + meal?.label
                    }
                    checked={mealActive}
                    onClick={() => {
                      setMealActive(!mealActive);
                    }}
                  />
                </div>
              )}

              {meal?.name && (
                <div className="flex_row_left mb_25 width_full">
                  <CheckBox
                    id="is_new"
                    label={meal?.label + " is new"}
                    checked={mealNew}
                    onClick={() => {
                      setMealNew(!mealNew);
                    }}
                  />
                </div>
              )}
   


              {/* <textarea


                      className="text_area text_area_short mb_35"
                      placeholder="meal description "
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    /> */}

              <div className="flex_row_center">
                <Button
                  text={submitting ? "Updating..." : "Update meal info"}
                  type="submit"
                  loading={submitting}
                />

                <Button
                  text={"Delete meal"}
                  className="ml_15"
                  loading={false}
                  isRed
                  type="button"
                  onClick={() => setShowPrompt(true)}
                />
              </div>
            </form>
          </section>
          <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
            <h6 className="bold_18_px mb_35">Meal Tags & Metadata</h6>
            <Tags
              title="Keywords"
              showTagDrop={showTagDrop}
              setShowTagDrop={setShowTagDrop}
              suggestions={suggestions}
              onAddition={onAddition}
              tags={tags}
              onDelete={onDelete}
              setTags={setTags}
              setSuggestions={setSuggestions}
            />
            <Tags
              title="Dietary information"
              showTagDrop={showDietaryDrop}
              setShowTagDrop={setShowDietaryDrop}
              suggestions={dietarySuggestions}
              onAddition={onAddition}
              tags={dietaryTags}
              onDelete={onDelete}
              setTags={setDietaryTags}
              setSuggestions={setDietarySuggestions}
            />
            <Tags
              title="Allergen information"
              showTagDrop={showAllergenDrop}
              setShowTagDrop={setShowAllergenDrop}
              suggestions={allergenSuggestions}
              onAddition={onAddition}
              tags={allergenTags}
              onDelete={onDelete}
              setTags={setAllergenTags}
              setSuggestions={setAllergenSuggestions}
            />
            <Tags
              title="Descriptive information"
              showTagDrop={showDescriptionDrop}
              setShowTagDrop={setShowDescriptionDrop}
              suggestions={descriptionSuggestions}
              onAddition={onAddition}
              tags={descriptionTags}
              onDelete={onDelete}
              setTags={setDescriptionTags}
              setSuggestions={setDescriptionSuggestions}
            />
            <Tags
              title="Business information"
              showTagDrop={showBusinessDrop}
              setShowTagDrop={setShowBusinessDrop}
              suggestions={businessSuggestions}
              onAddition={onAddition}
              tags={businessTags}
              onDelete={onDelete}
              setTags={setBusinessTags}
              setSuggestions={setBusinessSuggestions}
            />
            <div className="width_full">
              <label className="mb_15 normal_17px">Meal description</label>

              <textarea
                className="text_area text_area_short mb_30"
                placeholder="meal description "
                value={form.description}
                onChange={(e) => {
                  setForm({ ...form, description: e.target.value });
                }}
              />
            </div>
            <div className="width_full">
              <label className="mb_15 normal_17px">Meal summary</label>

              <textarea
                className="text_area text_area_short mb_35"
                placeholder="meal summary "
                value={form.summary}
                onChange={(e) => {
                  setForm({ ...form, summary: e.target.value });
                }}
              />
            </div>
          </section>
        </Fragment>
      }
      modals={
        <Fragment>
          {/* Meals by CSV Existing*/}
          <Modal
            title={`Upload ${
              isAddonCsv ? "meal addons" : "meals"
            } in CSV format`}
            showModal={showCsvModal}
            closeModal={() => setShowCsvModal(!showCsvModal)}
            customContent={
              <div>
                <div className="flex_row_start mb_25">
                  <CheckBox
                    id="is_addon_csv"
                    label={`Upload addon meals`}
                    checked={isAddonCsv}
                    onClick={() => {
                      setIsAddonCsv((prev) => !prev);
                    }}
                  />
                </div>
                {isAddonCsv ? (
                  <CsvParser
                    setCsvData={(e) => setCsvAddonData(e)}
                    isAddon={true}
                    setTitles={(e) => setTitlesAddon(e)}
                  />
                ) : (
                  <CsvParser
                    setCsvData={(e) => setCsvData(e)}
                    setTitles={(e) => setTitles(e)}
                  />
                )}
                {(csvData?.length > 0 || csvAddonData?.length > 0) &&
                  (isAddonCsv ? (
                    <h4 className="black mb_15">
                      {csvAddonData?.length} Addon Meals Found |{" "}
                      {checkedAddonMeals?.length} Addon Meals Selected
                    </h4>
                  ) : (
                    <h4 className="black mb_15">
                      {csvData?.length} Meals Found | {checkedMeals?.length}{" "}
                      Meals Selected
                    </h4>
                  ))}
                <MealsList
                  meals={isAddonCsv ? csvAddonData : csvData}
                  titles={isAddonCsv ? titlesAddon : titles}
                  setCheckedMeals={(e) => {
                    isAddonCsv ? setCheckedAddonMeals(e) : setCheckedMeals(e);
                  }}
                  isAddon={isAddonCsv}
                />
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            saveText={
              adding
                ? "Creating..."
                : `Create ${isAddonCsv ? "Meal Addons" : "Meals"}`
            }
            loading={adding}
          />

          {/* Meals by CSV Existing*/}

          <Modal
            title="Create Meal"
            showModal={showCreateModal}
            closeModal={() => setShowCreateModal(!showCreateModal)}
            customContent={
              <div>
                <Input
                  placeholder="Enter meal name"
                  value={form.name}
                  onChange={(e) => {
                    setForm({ ...form, name: e });
                  }}
                  required
                  containerClass="mb_15"
                />

                <Input
                  placeholder="Enter meal amount"
                  value={form.amount}
                  onChange={(e) => {
                    setForm({ ...form, amount: e });
                  }}
                  required
                  containerClass="mb_15"
                  type="number"
                />

                <Select
                  placeholder="Select cokitchen"
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      cokitchen_id: e,
                      //  posist_data:e?.posist_data,
                    });
                  }}
                  containerClass=""
                  options={cokitchens}
                  name="name"
                  id="id"
                />

                <Select
                  placeholder="Select brand"
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      brand_id: e?.id,
                      //  posist_data:e?.posist_data,
                    });
                  }}
                  containerClass=""
                  options={form?.cokitchen_id?.brands}
                  name="name"
                  id="id"
                />

                <Select
                  placeholder="Select meal category"
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      meal_category_id: e?.id,
                      //  posist_data:e?.posist_data,
                    });
                  }}
                  containerClass=""
                  options={cokitchens}
                  name="name"
                  id="id"
                  isLoading={ckLoading}
                />

                <Input
                  placeholder="Enter meal preparation time"
                  value={form.preparation_time}
                  onChange={(e) => {
                    setForm({ ...form, preparation_time: e });
                  }}
                  required
                  containerClass="mb_15"
                />
                <div className="flex_row_center mb_25">
                  <CheckBox
                    id="is_addon"
                    label={`Addon?`}
                    checked={form?.is_addon}
                    onClick={() => {
                      setForm({
                        ...form,
                        is_addon: !form?.is_addon,
                      });
                    }}
                  />
                </div>
                <textarea
                  className="text_area text_area_short mb_35"
                  placeholder="meal description "
                  value={form.description}
                  onChange={(e) => {
                    setForm({ ...form, description: e.target.value });
                  }}
                />

                <textarea
                  className="text_area text_area_short mb_35"
                  placeholder="meal summary "
                  value={form.summary}
                  onChange={(e) => {
                    setForm({ ...form, summary: e.target.value });
                  }}
                />

                <ImageFile
                  onChange={(e) => {
                    setNewPhoto(e.target.files[0]);
                    e && setNewPhotoDisplay(URL.createObjectURL(e));
                  }}
                  src={newPhotoDisplay}
                  text="Upload meal Image"
                />

                <h6 className="bold_18_px mb_35">Meal Tags & Metadata</h6>

                <Tags
                  title="Keywords"
                  showTagDrop={showTagDrop}
                  setShowTagDrop={setShowTagDrop}
                  suggestions={suggestions}
                  onAddition={onAddition}
                  tags={tags}
                  onDelete={onDelete}
                  setTags={setTags}
                  setSuggestions={setSuggestions}
                />
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
              formSubmit();
            }}
            saveText={adding ? "Creating..." : "Create Brand"}
            loading={adding}
          />
          {/* Meals by CSV End */}
          <Prompt
            showPrompt={showPrompt}
            closeModal={() => setShowPrompt(!showPrompt)}
            onSubmit={(e) => {
              e.preventDefault();
              deleteMeal();
            }}
            saveText="Cancel"
            loading={deleting}
            title={`Are you sure you want to delete ${form?.name}?`}
            deleteText={deleting ? "Deleting..." : "Delete meal"}
          />
        </Fragment>
      }
    />
  );

};
export default observer(withAlert()(ItemInfo));
