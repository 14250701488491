import {
	HOME,
	EXPLORE,
	DEALS_DISCOUNTS,
	VOUCHERS,
	PUSH_NOTIFICATIONS,
	BRAND_INFO,
	RATINGS,
	ITEM_INFO,
	TAG_CREATOR,
	CALENDER,
	LOCATION_SETTINGS,
	APP_FEEDBACKS,
	MEAL_ALLERGY,
	FAQ,
	MEAL_KEYWORD,
	COKITCHENS,
	MEAL_CATEGORIES,
	APP_VERSIONS,
	MEAL_ARRANGEMENTS,
	FEED,
} from "../router/router";

const ROUTES = [
	{
		name: "Home page header",
		id: "1",
		type: "",
		route: HOME,
	},
	{
		name: "Explore page",
		id: "2",
		type: "",
		route: EXPLORE,
	},

	{
		name: "Deals page & discounts",
		id: "3",
		type: "",
		route: DEALS_DISCOUNTS,
	},

	{
		name: "Push notifications",
		id: "4",
		type: "",
		route: PUSH_NOTIFICATIONS,
	},

	{
		name: "Vouchers",
		id: "5",
		type: "",
		route: VOUCHERS,
	},

	{
		name: "Cokitchens",
		id: "7",
		type: "",
		route: COKITCHENS,
	},
	{
		name: "Feed",
		id: "20",
		type: "",
		route: FEED,
	},
	{
		name: "Brand info",
		id: "6",
		type: "",
		route: BRAND_INFO,
	},

	{
		name: "Meal info",
		id: "8",
		type: "",
		route: ITEM_INFO,
	},

	{
		name: "Meal arrangements",
		id: "19",
		type: "",
		route: MEAL_ARRANGEMENTS,
	},

	{
		name: "Meal Categories",
		id: "14",
		type: "",
		route: "/meal-categories/meals",
	},
	{
		name: "Meal Keyword",
		id: "13",
		type: "",
		route: MEAL_KEYWORD,
	},
	{
		name: "Meal allergy metadata",
		id: "12",
		type: "",
		route: MEAL_ALLERGY,
	},
	{
		name: "Ratings & reviews",
		id: "9",
		type: "",
		route: RATINGS,
	},

	{
		name: "App feedbacks",
		id: "10",
		type: "",
		route: APP_FEEDBACKS,
	},

	{
		name: "Tag creator",
		id: "11",
		type: "",
		route: TAG_CREATOR,
	},

	{
		name: "App Versions",
		id: "15",
		type: "",
		route: APP_VERSIONS,
	},
	{
		name: "FAQs",
		id: "16",
		type: "",
		route: FAQ,
	},

	{
		name: "Calendar",
		id: "17",
		type: "",
		route: CALENDER,
	},

	{
		name: "Location settings",
		id: "18",
		type: "",
		route: LOCATION_SETTINGS,
	},
];

export default ROUTES;
