export const POLYGON_COLORS = [{
  "color_code": "#9b5de5"
}, {
  "color_code": "#264653"
}, {
  "color_code": "#d90429"
}, {
  "color_code": "#fee440"
}, {
  "color_code": "#f15bb5"
}, {
  "color_code": "#bc4749"
}, {
  "color_code": "#80e47c"
}, {
  "color_code": "#3c096c"
}, {
  "color_code": "#0496ff"
}, {
  "color_code": "#eb5e28"
}, {
  "color_code": "#718355"
}, {
  "color_code": "#f1c0e8"
}, {
  "color_code": "#8e7dbe"
}, {
  "color_code": "#661d44"
}, {
  "color_code": "#5d5f76"
}, {
  "color_code": "#23c881"
}, {
  "color_code": "#63e448"
}, {
  "color_code": "#3371fb"
}, {
  "color_code": "#21a5c8"
}, {
  "color_code": "#aa80fd"
}, {
  "color_code": "#87ca80"
}, {
  "color_code": "#4167cf"
}]