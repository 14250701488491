import React from "react";
import "./scss/app.scss";
import { observer } from "mobx-react";
import HomePage from "./screens/homePage";
import ExplorePage from "./screens/explorePage";
import DealsPage from "./screens/dealsPage";
import AddDealsPage from "./screens/addDiscount";
import NotificationsPage from "./screens/notificationPage";
import BrandInfoPage from "./screens/brandInfoPage";
import RatingsPage from "./screens/ratingsPage";
import ItemInfo from "./screens/itemInfo/itemInfo";
import TagCreator from "./screens/tagCreator";
import Calender from "./screens/calender";
import VoucherPage from "./screens/vouchersPage";
import LocationSettings from "./screens/locationSettings";
import AppFeedbacks from "./screens/appFeedbacks";
import MealAllergy from "./screens/mealAllergy";
import MealKeywords from "./screens/mealKeyword";
import MealCategories from "./screens/mealCategory";
import AddMeal from "./screens/addMeal";
import AppVersions from "./screens/appVersions";
import Faqs from "./screens/faqs";
import Cokitchens from "./screens/cokitchens";
import Login from "./screens/login";
import Signup from "./screens/signup";
import NotFound from "./screens/notFound";
import MealListPage from "./screens/addMeal/meal-list";
import CreateAddon from "./screens/addMeal/createAddon";
import DeleteAddonCat from "./screens/addMeal/deleteAddonCat";
import {
	// Auth
	LOGIN,
	SIGNUP,
	//   Dashboard
	HOME,
	EXPLORE,
	DEALS_DISCOUNTS,
	ADD_DEALS,
	VOUCHERS,
	PUSH_NOTIFICATIONS,
	BRAND_INFO,
	RATINGS,
	ITEM_INFO,
	TAG_CREATOR,
	CALENDER,
	LOCATION_SETTINGS,
	APP_FEEDBACKS,
	MEAL_ALLERGY,
	FAQ,
	MEAL_KEYWORD,
	MEAL_CATEGORIES,
	APP_VERSIONS,
	COKITCHENS,
	ADD_MEAL,
	ADD_MEAL_CSV,
	ADD_MEAL_ADDON,
	DELETE_ADDON_CATEGORY,
	MEAL_ARRANGEMENTS,
	MEALS_ARRANGEMENTS,
	FEED,
} from "./router/router";
import AuthRoute from "./router/AuthRoute";
import UnauthRoute from "./router/UnauthRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MealArrangement from "./screens/mealArrangement";
import MealsArrangement from "./screens/mealsArrangement";
import Feed from "./screens/feed";


function App() {
	return (
		<Router>
			<Switch>
				<AuthRoute exact path="/" component={HomePage} />
				<AuthRoute exact path={HOME} component={HomePage} />
				<AuthRoute exact path={EXPLORE} component={ExplorePage} />
				<AuthRoute exact path={DEALS_DISCOUNTS} component={DealsPage} />
				<AuthRoute exact path={ADD_DEALS} component={AddDealsPage} />
				<AuthRoute exact path={VOUCHERS} component={VoucherPage} />
				<AuthRoute
					exact
					path={PUSH_NOTIFICATIONS}
					component={NotificationsPage}
				/>
				<AuthRoute exact path={BRAND_INFO} component={BrandInfoPage} />
				<AuthRoute exact path={RATINGS} component={RatingsPage} />
				<AuthRoute exact path={ITEM_INFO} component={ItemInfo} />
				<AuthRoute exact path={FEED} component={Feed} />

				<AuthRoute exact path={MEAL_ARRANGEMENTS} component={MealArrangement} />
				<AuthRoute
					exact
					path={MEALS_ARRANGEMENTS}
					component={MealsArrangement}
				/>
				<AuthRoute exact path={ADD_MEAL} component={AddMeal} />
				<AuthRoute exact path={ADD_MEAL_ADDON} component={CreateAddon} />
				<AuthRoute exact path={ADD_MEAL_CSV} component={MealListPage} />
				<AuthRoute exact path={DELETE_ADDON_CATEGORY} component={DeleteAddonCat} />
				<AuthRoute exact path={TAG_CREATOR} component={TagCreator} />
				<AuthRoute exact path={CALENDER} component={Calender} />
				<AuthRoute exact path={APP_FEEDBACKS} component={AppFeedbacks} />
				<AuthRoute exact path={MEAL_ALLERGY} component={MealAllergy} />
				<AuthRoute exact path={MEAL_KEYWORD} component={MealKeywords} />
				<AuthRoute exact path={MEAL_CATEGORIES} component={MealCategories} />
				<AuthRoute exact path={FAQ} component={Faqs} />
				<AuthRoute exact path={APP_VERSIONS} component={AppVersions} />
				<AuthRoute exact path={COKITCHENS} component={Cokitchens} />

				<AuthRoute
					exact
					path={LOCATION_SETTINGS}
					component={LocationSettings}
				/>

				<UnauthRoute exact path={LOGIN} component={Login} />
				<UnauthRoute exact path={SIGNUP} component={Signup} />
				{/* Not Found */}
				<Route path="*" component={NotFound} />
			</Switch>
		</Router>
	);
}

export default observer(App);
