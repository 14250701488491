import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import { SketchPicker } from "react-color";
import NavItem from "../../components/sideNavItem";
import { Clock, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Input from "../../components/inputs";
import IconInput from "../../components/inputs/iconInput";
import Select from "../../components/select";
import Loader from "react-loader-spinner";
import { CheckBox } from "../../components/checkBoxes";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import axios from "axios";
import Modal from "../../components/modal";
import { makeCode } from "../../helpers/makeCode";
import RadioGroup from "../../components/inputs/RadioGroup";
import CreateFeed from "./CreateFeed";
import DealCard from "../../components/dealCard";
import FeedCard from "../../components/dealCard/FeedCard";
import { uploadFilesAndGetUrl } from "../../helpers/upload";

const Feed = ({ alert }) => {
	const [brands, setBrands] = useState([]);
	const [brand, setBrand] = useState("");
	const [ckLoading, setCkLoading] = useState(false);

	// shop feed
	const [shopFeeds, setShopFeeds] = useState([]);
	const [showCreateFeed, setShowCreateFeed] = useState(false);
	const [type, setType] = useState("edit");
	const [submitting, setSubmitting] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showPallete, setShowPallete] = useState(false);
	const [showFeed, setShowFeed] = useState(false);
	const [shopFeedLoading, setShopFeedLoading] = useState(false);
	const [brandSummary, setBrandSummary] = useState("");
	const [isNew, setIsNew] = useState(false);
	const [brandDescription, setBrandDescription] = useState("");
	const [deleteModal, setDeleteModal] = useState("");
	const [shortText, setShortText] = useState("");
	const [cokitchens, setCokitchens] = useState([]);
	const [currentCokitchen, setCurrentCokitchen] = useState("");
	const [currentBrands, setCurrentBrands] = useState([]);
	const [showSide, setShowSide] = useState(true);
	const [pageWidth, setPageWidth] = useState("");
	const [imageDisplay, setImageDisplay] = useState(null);
	const [brandImage, setBrandImage] = useState(null);
	const [logoDisplay, setLogoDisplay] = useState(null);
	const [brandLogo, setBrandLogo] = useState(null);
	const emptyForm = {
		name: "",
		posist_data: {
			customer_key: makeCode(15),
		},
		coming_soon: false,
		coming_soon_text: "",
		pop_up: false,
		popup_color: "",
		cokitchen_id: "",
		new: false,
		posist_customer_key: makeCode(15),
		position: "",
	};
	const [form, setForm] = useState({ ...emptyForm });

	useEffect(() => {
		setPageWidth(window.innerWidth);
		if (pageWidth <= 628) {
			setShowSide(false);
		} else {
			setShowSide(true);
		}
	}, [pageWidth]);
	useEffect(() => {
		const handleWindowResize = () => {
			setPageWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [pageWidth]);

	// load data from store
	useEffect(() => {
		loadCokitchen();
	}, []);

	useEffect(() => {
		loadShopFeed();
	}, []);

	useEffect(() => {
		setShopFeeds(CommonStore?.shopFeed);
	}, [CommonStore?.shopFeed]);

	useEffect(() => {
		shopFeedOptions();
	}, [shopFeeds]);

	const loadCokitchen = async () => {
		setLoading(true);
		setCkLoading(true);

		if (!CommonStore.dataCached) {
			await CommonStore.getSpecificCokitchens(alert);
			setCokitchens(CommonStore.specificCokitchens);
			setCkLoading(false);
		}
		setCkLoading(false);
		setLoading(false);
		setCokitchens(CommonStore.specificCokitchens);
		setBrands(CommonStore.brands);
	};

	const loadShopFeed = async () => {
		setShopFeedLoading(true);
		if (!CommonStore.dataCached) {
			await CommonStore.getShopFeed(alert);
		}
		setShopFeedLoading(false);
	};

	const shopFeedOptions = () => {
		let i;
		let options = [];
		let curr = shopFeeds || [];
		for (i = 0; i < curr.length; i++) {
			options.push({ ...curr[i], label: curr[i].name, value: curr[i].id });
		}
		setCurrentBrands(options);
	};

	// Set brand data based on current brand
	useEffect(() => {
		let descriptionCopy = brand.description || "";
		let summaryCopy = brand.summary || "";
		let shortTextCopy = brand.short_text || "";
		let logoCopy = brand.logo || "";
		let imageCopy = (brand.images && brand.images[0]) || "";
		let isNew = brand.new || false;

		setBrandDescription(descriptionCopy);
		setBrandSummary(summaryCopy);
		setIsNew(isNew);
		setShortText(shortTextCopy);
		setLogoDisplay(logoCopy);
		setImageDisplay(imageCopy);
		setForm({
			...form,
			name: brand?.name,
			position: brand?.position,
			coming_soon: brand?.coming_soon,
			coming_soon_text: brand?.coming_soon_text,
			pop_up: brand?.pop_up,
			popup_color: brand?.popup_color,
		});
	}, [brand]);

	//  Load Current working hours based on current brand

	const brandDelete = async () => {
		const { deleteShopFeed } = CommonStore;
		let feedId = brand?.id;
		if (!brand) {
			alert.show("Please select a Feed to delete", {
				type: "info",
			});
		} else {
			setSubmitting(true);
			await deleteShopFeed(alert, feedId);

			// let filteredBrand = currentBrands?.filter((item) => item?.id !== feedId);
			// setCurrentBrands(filteredBrand);
			shopFeedOptions();
			setBrand([]);
			setDeleteModal(!deleteModal);
			setSubmitting(false);
			setShowFeed(false);
		}
	};
	// Update brand info
	const brandUpdate = async (e) => {
		const { updateShopFeed } = CommonStore;

		if (!brand) {
			alert.show("Please select a brand to update", {
				type: "info",
			});
		} else if (shortText.length > 15) {
			alert.show("Short text should be a maximum of 15 characters", {
				type: "info",
			});
		} else if (!imageDisplay) {
			alert.show("Please upload an image for this brand", {
				type: "info",
			});
		} else if (!logoDisplay) {
			alert.show("Please upload a logo for this brand", {
				type: "info",
			});
		} else {
			setSubmitting(true);
			let url;
			if (brandImage) {
        const imageUrls = await uploadFilesAndGetUrl([brandImage], true)
        url = imageUrls[0]
			}
			let logoUrl;
			if (brandLogo) {
        const imageUrls = await uploadFilesAndGetUrl([brandLogo], false)
        logoUrl = imageUrls[0]
        setLogoDisplay(logoUrl);
			}

			let payload = {
				name: form?.name,
				// brand_id: brand.id,
				// cokitchen_id: form?.id,
				summary: brandSummary,
				description: brandDescription,
				short_text: shortText,
				logo: logoUrl || logoDisplay,
				images: (url && [url]) || [imageDisplay],
				position: form?.position,
				pop_up: form?.pop_up,
				popup_color: form?.popup_color,
			};

			await updateShopFeed(alert, brand?.id, payload);

			setSubmitting(false);
			setShowFeed(!showFeed);
		}
	};

	const onImageChange = async (e) => {
		setBrandImage(e);
		e && setImageDisplay(URL.createObjectURL(e));
	};

	const onLogoChange = async (e) => {
		setBrandLogo(e);
		e && setLogoDisplay(URL.createObjectURL(e));
	};

	return (
		<main className="main_container">
			<div className="">
				<Header />
				<section className="">
					<div className="top_fixed">
						<Tab ShopFeed="active_nav_item" />

						<div></div>

						<div className="border_bottom_thick">
							<div className="flex_row_between title_bar">
								<h6 className="bold_24px">Shop Feed</h6>
								<Button
									withIcon
									icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
									text="New Feed"
									onClick={() => {
										setShowCreateFeed(!showCreateFeed);
									}}
									loading={loading}
								/>
							</div>
						</div>

						{CommonStore?.shopFeed?.length === 0 ? (
							<div className="feed-loader">
								{shopFeedLoading ? (
									<Loader
										type="ThreeDots"
										color="#ff000060"
										height={10}
										width={50}
									/>
								) : (
									<span> No Feed </span>
								)}
							</div>
						) : (
							<div className="feed-flex">
								{CommonStore?.shopFeed?.map((item) => {
									return (
										<FeedCard
											key={item?.id}
											logo_src={item?.logo}
											src={item?.images[0]}
											name={item?.name}
											subtitle={item?.short_text}
											isFeed
											onClick={() => setShowFeed(!showFeed)}
											onClickItem={() => setBrand(item)}
											deleteFeed={() => setDeleteModal(!deleteModal)}
											isLoading={submitting}
										/>
									);
								})}
							</div>
						)}
					</div>
					<div className="top_section">
						<div className=""></div>
					</div>
					<section className="content_section ">
						<section className="cards_container grid_third_two px_40 easein-light"></section>
					</section>
				</section>
			</div>
			<Modal
				noSave
				title={`Edit ${brand?.name}`}
				showModal={showFeed}
				closeModal={() => {
					setShowFeed(!showFeed);
				}}
				customContent={
					<div onSubmit={(e) => brandUpdate(e)} className="flex_column_center">
						<Input
							placeholder="Brand Name"
							value={form?.name}
							onChange={(e) => setForm({ ...form, name: e })}
							required
							containerClass="width_full mb_35"
						/>
						<Input
							placeholder="Brand Position"
							value={form?.position}
							onChange={(e) => setForm({ ...form, position: e })}
							required
							containerClass="width_full mb_35"
						/>
						<Input
							placeholder="Brand summary"
							value={brandSummary}
							onChange={(e) => setBrandSummary(e)}
							required
							containerClass="width_full mb_35"
						/>
						<Input
							placeholder="Short Text"
							value={shortText}
							onChange={(e) => setShortText(e)}
							containerClass="width_full mb_35"
							maxLength="15"
						/>

						<div className="flex_row_left mb_25 width_full">
							<CheckBox
								id="pop-up"
								label={`Pop Up brand ?`}
								checked={form?.pop_up}
								onClick={() => {
									setForm({
										...form,
										pop_up: !form?.pop_up,
									});
								}}
							/>
						</div>

						{form?.pop_up && (
							<div className="d_flex flex_row_between width_full ">
								<Input
									placeholder="Enter popup color text"
									value={form?.popup_color}
									onChange={(e) => {
										setForm({ ...form, popup_color: e });
									}}
									containerClass="mb_15 width_full"
									label="Popup color code(HEX)"
								/>
								<span
									className=" open_pallete "
									onClick={() => setShowPallete(!showPallete)}
								>
									{!showPallete ? "Open Color Pallete" : "Close Color Palette"}
								</span>
							</div>
						)}
						{showPallete && (
							<SketchPicker
								className="mb_15"
								color={form?.popup_color}
								onChangeComplete={(color) => {
									setForm({
										...form,
										popup_color: color?.hex,
									});
								}}
							/>
						)}

						<div className="flex_row_left width_full mb_35">
							<div className="flex_column_left mr_25">
								<ImageFile
									onChange={(e) => {
										onImageChange(e.target.files[0]);
									}}
									src={imageDisplay}
									text="Upload Image"
								/>
								<label className="gray_label mt_10">Main brand image:</label>
							</div>
							<div className="flex_column_left">
								<ImageFile
									onChange={(e) => {
										onLogoChange(e.target.files[0]);
									}}
									src={logoDisplay}
									text="Upload logo"
								/>
								<label className="mt_10 gray_label">Brand logo:</label>
							</div>
						</div>

						<textarea
							className="text_area text_area_short mb_35"
							placeholder="Full brand description "
							value={brandDescription}
							onChange={(e) => {
								setBrandDescription(e.target.value);
							}}
							required
						/>
						<div className="d_flex flex_row_between">
							<Button
								type="button"
								onClick={brandUpdate}
								text={submitting ? "Updating..." : "Update Feed info"}
								loading={submitting}
								className="mr_10"
							/>
							<Button
								onClick={() => setDeleteModal(!deleteModal)}
								text={"Delete Feed"}
								type="button"
							/>
						</div>
					</div>
				}
			/>

			<Modal
				noSave
				title={`Are You Sure You Want To Delete ${brand?.name}`}
				showModal={deleteModal}
				closeModal={() => {
					setDeleteModal(!deleteModal);
				}}
				customContent={
					<>
						<Button
							onClick={brandDelete}
							text={submitting ? "Deleting..." : "Delete Feed"}
							loading={submitting}
							className="mr_10"
						/>
					</>
				}
			/>

			<CreateFeed
				onLogoChange={onLogoChange}
				onImageChange={onImageChange}
				submitting={submitting}
				alert={alert}
				logoDisplay={logoDisplay}
				imageDisplay={imageDisplay}
				setLogoDisplay={setLogoDisplay}
				setSubmitting={setSubmitting}
				setCurrentBrands={setCurrentBrands}
				currentBrands={currentBrands}
				showCreateFeed={showCreateFeed}
				setShowCreateFeed={setShowCreateFeed}
				cokitchens={cokitchens}
			/>
		</main>
	);
};
export default withAlert()(Feed);
