import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import { Button } from "../../components/buttons";
import { ArrowBack, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Select from "../../components/select";
import { Tags } from "../../components/appTag";
import MealLayout from "../../components/layout";
import Loader from "react-loader-spinner";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { FaInfo } from "react-icons/fa";
import axios from "axios";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import { ITEM_INFO } from "../../router/router";
import { useHistory } from "react-router-dom";
import CsvParser from "../../components/csvParser";
import MealsList from "../../components/mealsList";
import CsvParserNew from "../../components/csvParserNew";
import { CSV_TITLES_NEW } from "../../components/csvParserNew/utils";
import { uploadFilesAndGetUrl } from "../../helpers/upload";
const AddMeal = ({ alert }) => {
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [SelectedParentMeals, setSelectedParentMeals] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("add meal");
  const [mealCategoryModal, showMealCategoryModal] = useState(false);
  const [mealCategoryArray, setMealCategoryArray] = useState([""]);
  const [imageDisplay, setImageDisplay] = useState(null);

  const [brandMealCategory, setBrandMealCategory] = useState([]);

  const [image, setImage] = useState(null);
  const [mealCategory, setMealCategory] = useState("");

  const [showSide, setShowSide] = useState(true);
  const [showTagDrop, setShowTagDrop] = useState(false);
  const [showDietaryDrop, setShowDietaryDrop] = useState(false);
  const [showAllergenDrop, setShowAllergenDrop] = useState(false);
  const [showDescriptionDrop, setShowDescriptionDrop] = useState(false);
  const [showBusinessDrop, setShowBusinessDrop] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [adding, setAdding] = useState(false);
  const [brandmealCategories, setBrandMealCategories] = useState([]);
  const [currentBrandIndex, setCurrentBrandIndex] = useState("");

  const [pageWidth, setPageWidth] = useState("");
  const [isChecked, setIsChecked] = useState([""]);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [brandsId, setBrandsId] = useState([]);

  const [dietaryTags, setDietaryTags] = useState([]);
  const [dietarySuggestions, setDietarySuggestions] = useState([]);

  const [allergenTags, setAllergenTags] = useState([]);
  const [allergenSuggestions, setAllergenSuggestions] = useState([]);

  const [descriptionTags, setDescriptionTags] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [isAddon, setIsAddon] = useState([]);
  const [businessTags, setBusinessTags] = useState([]);
  const [businessSuggestions, setBusinessSuggestions] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [titlesNew, setTitlesNew] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isAddonCsv, setIsAddonCsv] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [form, setForm] = useState({
    name: "",
    meal_category_id: "",
    meal_parent_ids: "",
    amount: "",
    brand_id: "",
    calories:"",
    is_addon: false,
    new: false,
    preparation_time: "",
    description: "",
    summary: "",
    images: [],
    meal_keywords: [],
    meal_descriptive_metadatas: [],
    meal_business_metadatas: [],
    meal_dietary_metadatas: [],
    meal_allergy_metadatas: [],
  });
  const [categoryForm, setCategoryForm] = useState({
    name: "",
    summary: "",
    brand_id: "",
    meal_category_selection_type_id: "",
    super_meal_category_id: "",
    selection_no: "",
    is_addon: false,
    meal_category_id: "",
    is_addon_for_other_meals: "",
  });
  const [brandsAddon, setBrandsAddon] = useState([]);
  const [brandsAddonMeals, setBrandsAddonMeals] = useState([]);
  const [brandsAddonMealsMatch, setBrandsAddonMealsMatch] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [newPhotoDisplay, setNewPhotoDisplay] = useState(null);
  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  const [newCategoryBrand, setNewCategoryBrand] = useState("");
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [checkedMeals, setCheckedMeals] = useState([]);
  const [csvAddonData, setCsvAddonData] = useState([]);
  const [checkedAddonMeals, setCheckedAddonMeals] = useState([]);
  const [titles, setTitles] = useState([]);
  const [titlesAddon, setTitlesAddon] = useState([]);
  const [showCreateAddonModal, setShowCreateAddonModal] = useState();
  const [csvMeals, setCsvMeals] = useState([]);
  const [csvAddons, setCsvAddons] = useState([]);
  const [showCsvNewModal, setShowCsvNewModal] = useState(false);
  const [csvNewData, setCsvNewData] = useState([]);
  const [checkedNewMeals, setCheckedNewMeals] = useState([]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (
      !BrandStore.dataAltCached &&
      !MealCategoryStore.dataCached &&
      !BrandStore.dataCached &&
      !CommonStore.dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        MealCategoryStore.getMealCategories(alert, true),
        CommonStore.getCokitchensOnly(alert),
        BrandStore.getBrands(alert),
        MealCategoryStore.getMealCategorySelection(alert),
      ]);
    } else {
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt);
      }
      if (!BrandStore.dataCached) {
        await BrandStore.getBrands(alert);
      }

      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchensOnly(alert);
        setCokitchens(CommonStore.cokitchens);
        setCkLoading(false);
      }

      if (!MealCategoryStore.dataCached) {
        await Promise.all([
          MealCategoryStore.getMealCategorySelection(alert),
          MealCategoryStore.getMealCategories(alert, true),
        ]);
      }
    }
    setCkLoading(false);
    setLoading(false);
    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });

    setAllTags();
    setBrands(BrandStore.brandsAlt);
    setBrandsAddon(MealCategoryStore.mealCategories);
    setMealCategoryTypesOptions();
    mergeMealCategories();
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = currentCokitchen?.brands?.item_type === "FOOD" || [];
    //(item) => item?.cokitchen_id === form ||item.item_type==="FOOD"
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name,
        value: curr[i].id,
        index: i,
      });
    }
    setCurrentBrands(options);
  }, [currentCokitchen]);

  // Set brand data based on current brand
  useEffect(() => {
    setAllTags();

    let mealOptions = [];
    let mealCopy = brand.meals || [];
    for (let l = 0; l < mealCopy.length; l++) {
      mealOptions.push({
        ...mealCopy[l],
        label: mealCopy[l].images[0]
          ? mealCopy[l].name + " 📸"
          : mealCopy[l].name,
        value: mealCopy[l].id,
      });
    }

    setMeals(mealOptions);
    setMeal("");
    setImageDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
  }, [brand]);

  // Sort setMealCategoryTypesOptions options for react select
  const setMealCategoryTypesOptions = async () => {
    let opts = MealCategoryStore.mealCategoriesSelection;

    setmealCategorySelections(opts);
  };

  // Set meal data based on current meal
  useEffect(() => {
    setAllTags();
    let i, j, k, l, m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let keyCopy = meal.meal_keywords || [];
    let descCopy = meal.meal_descriptive_metadatas || [];
    let bussCopy = meal.meal_business_metadatas || [];
    let dietCopy = meal.meal_dietary_metadatas || [];
    let allergyCopy = meal.meal_allergy_metadatas || [];
    let descriptionCopy = meal.description || "";
    let imagesCopy = (meal && meal.images && meal.images[0]) || "";

    setImageDisplay(imagesCopy);
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        ...keyCopy[i],
        label: keyCopy[i].name,
        value: keyCopy[i].id,
      });
    }
    setTags(keyOptions);

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        ...dietCopy[j],
        label: dietCopy[j].name,
        value: dietCopy[j].id,
      });
    }
    setDietaryTags(dietOptions);

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        ...allergyCopy[k],
        label: allergyCopy[k].name,
        value: allergyCopy[k].id,
      });
    }

    setAllergenTags(allergyOptions);

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        ...descCopy[l],
        label: descCopy[l].name,
        value: descCopy[l].id,
      });
    }
    setDescriptionTags(descOptions);

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        ...bussCopy[m],
        label: bussCopy[m].name,
        value: bussCopy[m].id,
      });
    }
    setBusinessTags(bussOptions);
  }, [meal]);

  useEffect(async () => {
    let i;
    let options = [];
    let curr = CommonStore.cokitchens || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.brands];
      // options.push({
      //   ...curr[i]?.brands,

      // });
    }
    setAllBrands(options);
  }, [cokitchens]);

  useEffect(async () => {
    globalReset();

    setSelectedParentMeals([]);
    setBrandsAddonMealsMatch([]);
    setForm({
      ...form,
      brand_id: "",
      meal_category_id: "",
    });
    console.log(form);
  }, [form?.cokitchen_id]);

  useEffect(async () => {
    globalReset();
    findCurrentBrandMealCategories();
    setSelectedParentMeals([]);
    setBrandsAddonMealsMatch([]);
    setForm({
      ...form,
      meal_category_id: "",
    });
  }, [form?.brand_id]);

  useEffect(async () => {
    globalReset();
    setSelectedParentMeals([]);
    setBrandsAddonMealsMatch([]);
  }, [form?.meal_category_id]);

  const globalReset = () => {
    setForm({
      ...form,
      name: "",
      meal_parent_ids: "",
      amount: "",
      calories:"",
      is_addon: false,
      new: false,
      preparation_time: "",
      description: "",
      summary: "",
      images: [],
      meal_keywords: [],
      meal_descriptive_metadatas: [],
      meal_business_metadatas: [],
      meal_dietary_metadatas: [],
      meal_allergy_metadatas: [],
    });

    setMeal("");
    setNewPhoto("");
    setNewPhotoDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
    setSelectedAddons([]);
  };

  // Set meal addon categories options based on selected meal_parent_ids
  useEffect(() => {
    let activeMealsMatches = brandsAddonMeals?.filter((item) =>
      form?.meal_parent_ids.includes(item?.id)
    );

    setBrandsAddonMealsMatch(activeMealsMatches);
  }, [form?.meal_parent_ids]);

  const findCurrentBrandMealCategories = () => {
    let active = BrandStore.brandsAlt?.find(
      (item) => item.id === form?.brand_id
    );
    let activeMeals =
      active?.meal_categories?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      ) || [];
    setBrandMealCategories(activeMeals);
  };

  const mergeMealCategories = () => {
    let i;
    let options = [];
    let curr = MealCategoryStore.mealCategories || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.meals];
    }
    setBrandsAddonMeals(options);
  };
  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  // Sort tags
  const setAllTags = () => {
    let { tags } = TagsStore;
    let i;
    const tagsCopy = JSON.parse(JSON.stringify(tags));
    let curr =
      tagsCopy.meal_keyword
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setSuggestions(curr);
    let currDietary =
      tagsCopy.meal_dietary_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setDietarySuggestions(currDietary);
    let currAllergen =
      tagsCopy.meal_allergy_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setAllergenSuggestions(currAllergen);
    let currDescriptive =
      tagsCopy.meal_descriptive_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setDescriptionSuggestions(currDescriptive);
    let currBuss =
      tagsCopy.meal_business_metadata
        ?.filter((item) => item?.item_type === "FOOD")
        ?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [];
    setBusinessSuggestions(currBuss);
  };

  // Format tags to be submitted
  const formatTags = () => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let allOptions = {};
    let keyCopy = tags;
    let descCopy = descriptionTags;
    let bussCopy = businessTags;
    let dietCopy = dietaryTags;
    let allergyCopy = allergenTags;

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        name: allergyCopy[k].name,
        id: allergyCopy[k].id,
      });
    }
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        name: keyCopy[i].name,
        id: keyCopy[i].id,
      });
    }

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        name: dietCopy[j].name,
        id: dietCopy[j].id,
      });
    }

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        name: descCopy[l].name,
        id: descCopy[l].id,
      });
    }

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        name: bussCopy[m].name,
        id: bussCopy[m].id,
      });
    }

    allOptions = {
      key: keyOptions,
      desc: descOptions,
      buss: bussOptions,
      diet: dietOptions,
      allergy: allergyOptions,
    };

    return allOptions;
  };

  // Update meal
  const mealUpdate = async (e) => {
    e.preventDefault();
    const { createMealforMultipleBrands, getBrands, brands } = BrandStore;
    const allTags = await formatTags();
    // meal_parent_ids:

    let mealParentsIds = form?.meal_parent_ids;
    let selectedAddonsParentIds = selectedAddons?.map(
      (item) => item?.meal_parent_id
    );
    let mealParents = selectedAddons?.filter((item) =>
      mealParentsIds?.includes(item?.meal_parent_id)
    );
    let mealParentsAlt = SelectedParentMeals?.filter(
      (item) => !selectedAddonsParentIds?.includes(item?.id)
    );
    let mealsParentsData = mealParents.map((item) => {
      return {
        meal_addon_category_id: item?.id,
        meal_parent_id: item?.meal_parent_id,
      };
    });

    let brandsId = isChecked;

    let categoriesId = mealCategoryArray.map((item) => {
      return { meal_category_id: item.meal_cat_id, brand_id: item.brand_id };
    });
    categoriesId.splice(0, 1);
    brandsId.splice(0, 1);
    let body = {
      name: form.name,
      meal_category_ids: [...categoriesId],
      meal_parent_ids: form?.is_addon ? mealsParentsData : "",
      amount: form.amount,
      calories:form?.calories,
      is_addon: false,
      new: form?.new ? true : false,
      preparation_time: form.preparation_time,
      description: form.description,
      summary: form.summary,
      images: [],
      meal_keywords: allTags.key,
      meal_descriptive_metadatas: allTags.desc,
      meal_business_metadatas: allTags.buss,
      meal_dietary_metadatas: allTags.diet,
      meal_allergy_metadatas: allTags.allergy,
      item_type: "FOOD",
    };
    console.log(body);
    if (mealParentsAlt && mealParentsAlt[0] && form?.is_addon) {
      let addonMessages = mealParentsAlt.map(
        (item, i) =>
          item?.name +
          (i === mealParentsAlt?.length - 2
            ? " and "
            : i === mealParentsAlt?.length - 1
            ? ""
            : ", ")
      );
      alert.show("Select meal addon category" + addonMessages, {
        type: "info",
      });
    } else if (body.meal_category_ids.length === 0) {
      alert.show("Select brand and meal category", {
        type: "info",
      });
    } else {
      Object.keys(body).forEach((key, index) => {
        if (body[key] === "") {
          delete body[key];
        }
      });

      //   if (!form?.cokitchen_id) {
      //     alert.show("Select cokitchen too add meal!", {
      //       type: "info",
      //     });
      //}

      if (!body?.meal_category_ids) {
        alert.show("Meal category is required", {
          type: "info",
        });
      } else {
        setSubmitting(true);
        let url;
        if (newPhoto) {
          const imageUrls = await uploadFilesAndGetUrl([newPhoto], true);
          setNewPhotoDisplay(imageUrls[0]);
          url = imageUrls[0];
        }

        body.images = [url || newPhotoDisplay];
        let textBD = [body, body, body];
        navigator.clipboard.writeText(JSON.stringify(textBD));
        await createMealforMultipleBrands(alert, body, async () => {
          await CommonStore.getCokitchensOnly(alert);
          history.push(ITEM_INFO);
        });
        setSubmitting(false);
        alert.show("Fetching updates...", {
          type: "info",
        });

        setCokitchens(CommonStore.cokitchens);
        setCurrentCokitchen({
          brands:
            CommonStore?.cokitchens &&
            CommonStore?.cokitchens[0] &&
            CommonStore?.cokitchens[0].brands,
          name: "All users (all locations)",
        });
        setCurrentBrands(
          CommonStore?.cokitchens &&
            CommonStore?.cokitchens[0] &&
            CommonStore?.cokitchens[0].brands
        );

        setAllTags();
      }
    }
  };
  const createNewMeals = async () => {
    const { createBulkMeal } = BrandStore;
    let body = JSON.parse(JSON.stringify(checkedNewMeals));
    body = body?.map((item) => {
      let bodyAddons = item[CSV_TITLES_NEW.addonsString]?.map((ele) => {
        return {
          meal_category_name: ele[CSV_TITLES_NEW.addonCategoryString],
          name: ele[CSV_TITLES_NEW.addonNameString],
          amount: ele[CSV_TITLES_NEW.addonPriceString],
        };
      });

      return {
        name: item[CSV_TITLES_NEW.nameString],
        description: item[CSV_TITLES_NEW.descString],
        preparation_time: item[CSV_TITLES_NEW.prepString],
        amount: item[CSV_TITLES_NEW.rateString],
        meal_category_name: item[CSV_TITLES_NEW.categoryString],
        addons: bodyAddons,
        brand_id: brand?.id,
        item_type: "FOOD",
        calories:item[CSV_TITLES_NEW.calorieString]
      };
    });

    if (!brand) {
      alert.show("Select a brand for the new meals!", {
        type: "info",
      });
    } else if (!csvMeals[0]) {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    } else {
      // console.log("body",body,"checkedNewMeals",checkedNewMeals)
      setAddingNew(true);
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
      await createBulkMeal(alert, { meals: body });
      setAddingNew(false);
      setShowPrompt(false);
    }
  };

  // Submit add form
  const categoryFormSubmit = async () => {
    const { getMealCategories, createMealCategory } = MealCategoryStore;
    setCategoryForm({ ...categoryForm, brand_id: form.brand_id?.id });
    let brands = [...isChecked];

    let format = isChecked;

    let body = {
      name: categoryForm?.name,
      summary: categoryForm?.summary,
      brand_id: newCategoryBrand.id,
    };
    if (!body?.brand_id) {
      alert.show("Brand is required!", {
        type: "info",
      });
    }
    // else if (!body?.super_meal_category_id) {
    //   alert.show("Super meal category type is required!", {
    //     type: "info",
    //   });
    // }
    else {
      if (!isAddon) {
        body.meal_category_selection_type_id = mealCategorySelections[0]?.id;
      }
      Object.keys(body).forEach((key, index) => {
        if (!body[key] || body[key] == {}) {
          if (body !== "is_addon") delete body[key];
        }
      });
      try {
        setAdding(true);

        let res;

        res = await createMealCategory(alert, body);
        if (res) {
          setAdding(false);
          setShowCreateModal(false);
          await getMealCategories(alert, false);
          setBrands(MealCategoryStore.mealCategories);
          form?.brand_id?.meal_categories.push(res.data);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();
      }
    }
  };

  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const handleCheckboxChange = async (item) => {
    let i;
    let options = [];
    let curr = brandsAddonMealsMatch || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.meal_addon_categories];
    }

    let similarMatches = options?.filter((el) => el?.name === item?.name);

    setSelectedAddons(similarMatches);
  };

  const handleCheckboxChangeAlt = async (item) => {
    let newArr = [...selectedAddons, item];
    let match = selectedAddons?.find((el) => el?.id === item?.id);

    if (match) {
      newArr = newArr.filter((itm) => itm?.id !== item?.id);
    }

    setSelectedAddons(newArr);
  };
  const handleBranchCHeckBox = (id) => {
    isChecked?.includes(id)
      ? setIsChecked(isChecked?.filter((item) => item !== id))
      : setIsChecked([...isChecked, id]);

    //console.log(isChecked)
    // mealCategoryArray.map((item, i)=>{
    // 	 return item.id === isChecked?.filter((i)=>i!== item.id )
    // 	})
  };
  const updateMealCategories = (e) => {
    let arr = [...mealCategoryArray];
    let index = arr?.findIndex((item) => item.brand_id === e.brand_id);

    if (index === -1) {
      setMealCategoryArray([
        ...mealCategoryArray,
        { meal_cat_id: e.id, brand_id: e.brand_id, name: e.name },
      ]);
    } else {
      arr[index].meal_cat_id = e.id;
      arr[index].name = e.name;

      //arr.push({meal_cat_id: e.id, brand_id:e.brand_id, name:e.name})
      setMealCategoryArray([...arr]);
    }

    showMealCategoryModal(!mealCategoryModal);
    setMealCategory("");

    //setBrandMealCategory(...brandMealCategory, {brand: e.} )
  };
  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  return (
    <MealLayout
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      headerTitle={"Create new Meal"}
      content={"Add Meals to brands"}
      handleChange={setCurrentCokitchen}
      pageLoading={loading}
      loading={loading}
      options={cokitchens}
      children={
        <Fragment>
          <section className=" px_50 seond_width_desktop_2 mb_30">
            <div>
              <Button
                text="Clear Brand selcetion"
                onClick={() => {
                  setMealCategoryArray([]);
                  setIsChecked([]);
                }}
                isRed
                className="flex_row_align_left mb_30 "
                disabled={isChecked.length === 0}
                loading={ckLoading}
              />
            </div>
            <form
              onSubmit={(e) => mealUpdate(e)}
              className="flex_column_center"
            >
              {ckLoading ? (
                <Loader
                  type="ThreeDots"
                  color="#ff000060"
                  height={10}
                  width={50}
                />
              ) : (
                <div>
                  <div className="info_important bold">
                    <div className="flex_row">
                      <h5 className="">
                        <FaInfo width="20px" height="40px" />
                      </h5>
                      <p>
                        Select brand and meal category which the meal will be
                        created to
                      </p>
                    </div>
                  </div>
                  <div className="radio_class width_full radio_flex">
                    {brands
                      ?.filter(
                        (item) =>
                          item?.cokitchen_id === form ||
                          item.item_type === "FOOD"
                      )
                      .map((item, i) => {
                        let category = [...mealCategoryArray];
                        let curr = item;
                        const removeChecked = (props) => {
                          isChecked?.filter((item) => item.id !== i);
                          let filterArr = mealCategoryArray?.filter(
                            (item) => item.brand_id !== props
                          );
                          setMealCategoryArray([...filterArr]);
                        };
                        return (
                          <div>
                            <CheckBox
                              id={i}
                              checked={isChecked.includes(item?.id)}
                              onClick={() => {
                                handleBranchCHeckBox(item?.id);
                                isChecked.includes(item?.id)
                                  ? removeChecked(item?.id)
                                  : showMealCategoryModal(true);
                                setForm({
                                  ...form,
                                  brand_id: item,
                                });
                              }}
                              label={item.name}
                            />

                            {category
                              .filter((item) => item.brand_id === curr.id)
                              ?.map((item) => (
                                <div
                                  className=""
                                  onClick={() => {
                                    showMealCategoryModal(true);
                                    setForm({
                                      ...form,
                                      brand_id: curr,
                                    });
                                  }}
                                >
                                  <h6 className="meal_category_link">
                                    {item.name}
                                  </h6>
                                </div>
                              ))}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              <div className="ml d-flex flex_row_between width_full mt_35">
                <div className="">
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="New Meal Category"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                    loading={loading}
                    className="mb_30"
                  />
                </div>
              </div>

              <Input
                placeholder="Meal name"
                value={form.name}
                onChange={(e) => {
                  setForm({ ...form, name: e });
                }}
                required
                containerClass="mb_15 width_full"
              />

              <Input
                placeholder="Enter meal amount"
                value={form.amount}
                onChange={(e) => {
                  setForm({ ...form, amount: e });
                }}
                required
                containerClass="mb_15 width_full"
                type="number"
              />
              <Input
                placeholder="Calories"
                value={form.calories}
                onChange={(e) => {
                  setForm({ ...form, calories: e });
                }}
                required
                containerClass="mb_15 width_full"
                type="text"
              />
              <Input
                placeholder="Enter meal preparation time (minutes)"
                value={form.preparation_time}
                onChange={(e) => {
                  setForm({ ...form, preparation_time: e });
                }}
                required
                containerClass="mb_15 width_full"
              />

              <textarea
                className="text_area text_area_short mb_35"
                placeholder="meal description "
                value={form.description}
                onChange={(e) => {
                  setForm({ ...form, description: e.target.value });
                }}
              />

              <textarea
                className="text_area text_area_short mb_35"
                placeholder="meal summary "
                value={form.summary}
                onChange={(e) => {
                  setForm({ ...form, summary: e.target.value });
                }}
              />

              <div className="flex_row_left mb_25 width_full">
                <ImageFile
                  onChange={(e) => {
                    setNewPhoto(e.target.files[0]);
                    e &&
                      setNewPhotoDisplay(
                        URL.createObjectURL(e.target.files[0])
                      );
                  }}
                  src={newPhotoDisplay}
                  text="Upload meal Image"
                />
              </div>

              <div className="flex_row_left mb_25 width_full">
                <CheckBox
                  id="is_new"
                  label={`Is new?`}
                  checked={form?.new}
                  onClick={() => {
                    setForm({
                      ...form,
                      new: !form?.new,
                    });
                  }}
                />
              </div>

              {form?.is_addon && (
                <Select
                  placeholder="Select parent meal"
                  isMulti
                  handleChange={(e) => {
                    let i;
                    let copy = [];
                    for (i = 0; i < e.length; i++) {
                      copy.push(e[i].id);
                    }
                    setForm({ ...form, meal_parent_ids: copy });
                    setSelectedParentMeals(e);
                  }}
                  value={form?.meal_category_id?.meals?.filter((item) =>
                    form?.meal_parent_ids?.includes(item?.id)
                  )}
                  containerClass=""
                  options={form?.meal_category_id?.meals}
                  name="name"
                  id="id"
                />
              )}

              <Button
                text={submitting ? "Creating..." : "Create meal"}
                type="submit"
                loading={isChecked.length === 1 || submitting || ckLoading}
              />
            </form>
          </section>
          <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
            <h6 className="bold_18_px mb_35">Meal Tags & Metadata</h6>
            <Tags
              title="Keywords"
              showTagDrop={showTagDrop}
              setShowTagDrop={setShowTagDrop}
              suggestions={suggestions}
              onAddition={onAddition}
              tags={tags}
              onDelete={onDelete}
              setTags={setTags}
              setSuggestions={setSuggestions}
            />
            <Tags
              title="Dietary information"
              showTagDrop={showDietaryDrop}
              setShowTagDrop={setShowDietaryDrop}
              suggestions={dietarySuggestions}
              onAddition={onAddition}
              tags={dietaryTags}
              onDelete={onDelete}
              setTags={setDietaryTags}
              setSuggestions={setDietarySuggestions}
            />
            <Tags
              title="Allergen information"
              showTagDrop={showAllergenDrop}
              setShowTagDrop={setShowAllergenDrop}
              suggestions={allergenSuggestions}
              onAddition={onAddition}
              tags={allergenTags}
              onDelete={onDelete}
              setTags={setAllergenTags}
              setSuggestions={setAllergenSuggestions}
            />
            <Tags
              title="Descriptive information"
              showTagDrop={showDescriptionDrop}
              setShowTagDrop={setShowDescriptionDrop}
              suggestions={descriptionSuggestions}
              onAddition={onAddition}
              tags={descriptionTags}
              onDelete={onDelete}
              setTags={setDescriptionTags}
              setSuggestions={setDescriptionSuggestions}
            />
            <Tags
              title="Business information"
              showTagDrop={showBusinessDrop}
              setShowTagDrop={setShowBusinessDrop}
              suggestions={businessSuggestions}
              onAddition={onAddition}
              tags={businessTags}
              onDelete={onDelete}
              setTags={setBusinessTags}
              setSuggestions={setBusinessSuggestions}
            />

            {form?.is_addon && (
              <section className="mt_35 align_left justify_start column width_full">
                <h6 className="bold_18_px mb_25 grey">
                  Select meal addon category under each parent meal
                </h6>

                {brandsAddonMealsMatch?.map((item, i) => {
                  return (
                    <div
                      key={i + item?.id}
                      className="align_left justify_start column width_full mb_25 border_bottom_thin"
                    >
                      <div className="flex_row_center">
                        <p className="mb_25">{item?.name}</p>
                        <a
                          className="normal_18px mr_30 mb_25"
                          href="#/"
                          onClick={() => {
                            setShowCreateModal(true);
                            setCategoryForm({
                              ...categoryForm,
                              is_addon: true,
                            });
                          }}
                        >
                          Create meal addon category
                        </a>
                      </div>

                      <div className="grid_second width_full">
                        {item?.meal_addon_categories?.map((itm, ind) => {
                          let isChecked = selectedAddons?.find(
                            (el) => el.id === itm?.id
                          );
                          return (
                            <div
                              className="flex_row_left mb_4 width_full"
                              key={ind + itm?.id}
                            >
                              <CheckBox
                                id={itm?.id}
                                label={itm?.name}
                                checked={isChecked}
                                onClick={() => {
                                  if (isChecked) {
                                    handleCheckboxChangeAlt(itm);
                                  } else {
                                    handleCheckboxChange(itm);
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </section>
            )}
          </section>
        </Fragment>
      }
      modals={
        <Fragment>
          <Modal
            title={"Create Meal Category"}
            showModal={showCreateModal}
            closeModal={() => {
              setShowCreateModal(!showCreateModal);
              setMealCategoryArray([]);
            }}
            customContent={
              <div>
                <Select
                  placeholder="Select brand"
                  value={newCategoryBrand}
                  handleChange={(e) => {
                    setNewCategoryBrand(e);
                  }}
                  containerClass=""
                  options={brands?.filter((item) => item?.item_type === "FOOD")}
                  name="name"
                  id="id"
                  isLoading={loading}
                />
                <Input
                  placeholder="Enter meal category name"
                  value={categoryForm.name}
                  onChange={(e) => {
                    setCategoryForm({ ...categoryForm, name: e });
                  }}
                  required
                  containerClass="mb_15"
                />

                <textarea
                  className="text_area text_area_short mb_15"
                  placeholder="Meal category description "
                  value={categoryForm.summary}
                  onChange={(e) => {
                    setCategoryForm({
                      ...categoryForm,
                      summary: e.target.value,
                    });
                  }}
                />
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
              categoryFormSubmit();
            }}
            saveText={adding ? "Creating..." : "Create Meal Category"}
            loading={adding}
          />
          <Modal
            title={`Upload ${
              isAddonCsv ? "meal addons" : "meals"
            } in CSV format`}
            showModal={showCsvModal}
            closeModal={() => setShowCsvModal(!showCsvModal)}
            customContent={
              <div>
                <div className="flex_row_start mb_25">
                  <CheckBox
                    id="is_addon_csv"
                    label={`Upload addon meals`}
                    checked={isAddonCsv}
                    onClick={() => {
                      setIsAddonCsv((prev) => !prev);
                    }}
                  />
                </div>
                {isAddonCsv ? (
                  <CsvParser
                    setCsvData={(e) => setCsvAddonData(e)}
                    isAddon={true}
                    setTitles={(e) => setTitlesAddon(e)}
                  />
                ) : (
                  <CsvParser
                    setCsvData={(e) => setCsvData(e)}
                    setTitles={(e) => setTitles(e)}
                  />
                )}
                {(csvData?.length > 0 || csvAddonData?.length > 0) &&
                  (isAddonCsv ? (
                    <h4 className="black mb_15">
                      {csvAddonData?.length} Addon Meals Found |{" "}
                      {checkedAddonMeals?.length} Addon Meals Selected
                    </h4>
                  ) : (
                    <h4 className="black mb_15">
                      {csvData?.length} Meals Found | {checkedMeals?.length}{" "}
                      Meals Selected
                    </h4>
                  ))}
                <MealsList
                  meals={isAddonCsv ? csvAddonData : csvData}
                  titles={isAddonCsv ? titlesAddon : titles}
                  setCheckedMeals={(e) => {
                    isAddonCsv ? setCheckedAddonMeals(e) : setCheckedMeals(e);
                  }}
                  isAddon={isAddonCsv}
                />
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            saveText={
              adding
                ? "Creating..."
                : `Create ${isAddonCsv ? "Meal Addons" : "Meals"}`
            }
            loading={adding}
          />

          {/* Meals by CSV New */}
          <Modal
            title={`Upload meals and their addon mappings in CSV format`}
            showModal={showCsvNewModal}
            closeModal={() => setShowCsvNewModal(!showCsvNewModal)}
            customContent={
              <div>
                <CsvParserNew
                  setCsvData={(e) => setCsvNewData(e)}
                  setTitles={(e) => setTitlesNew(e)}
                  setCsvAddons={(e) => setCsvAddons(e)}
                  setCsvMeals={(e) => setCsvMeals(e)}
                />
                {csvNewData?.length > 0 &&
                  history.push({
                    pathname: "/add-meals-via-csv",
                    state: {
                      csvNewData: csvNewData,
                      titles: titlesNew,
                      csvMeals: csvMeals,
                      csvAddons: csvAddons,
                      brand: brand,
                    },
                  })}
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
              createNewMeals();
            }}
            saveText={addingNew ? "Creating..." : `Create Meals`}
            loading={addingNew}
          />
          <Modal
            title={`Upload meals and their addon mappings in CSV format`}
            showModal={showCsvNewModal}
            closeModal={() => setShowCsvNewModal(!showCsvNewModal)}
            customContent={
              <div>
                <CsvParserNew
                  setCsvData={(e) => setCsvNewData(e)}
                  setTitles={(e) => setTitlesNew(e)}
                  setCsvAddons={(e) => setCsvAddons(e)}
                  setCsvMeals={(e) => setCsvMeals(e)}
                />
                {csvNewData?.length > 0 && (
                  <h4 className="black mb_15">
                    {csvNewData?.length} Meals Found | {checkedNewMeals?.length}{" "}
                    Meals Selected
                  </h4>
                )}
                <MealsList
                  meals={csvNewData}
                  titles={titlesNew}
                  setCheckedMeals={(e) => {
                    setCheckedNewMeals(e);
                  }}
                  isNew
                />
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
              createNewMeals();
            }}
            saveText={addingNew ? "Creating..." : `Create Meals`}
            loading={addingNew || ckLoading}
          />

          <Modal
            title={`Add meal category`}
            showModal={mealCategoryModal}
            closeModal={() => {
              showMealCategoryModal(!mealCategoryModal);
            }}
            customContent={
              <div>
                <Select
                  placeholder="Select meal category"
                  value={form?.meal_category_id}
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      meal_category_id: e,
                    });
                    console.log(e);
                    updateMealCategories(e);
                  }}
                  containerClass=""
                  options={form?.brand_id?.meal_categories}
                  name="name"
                  id="id"
                />
              </div>
            }
            customClass="feedback_modal no-scroll"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            saveText={addingNew ? "Adding..." : `Add meal category`}
            loading={addingNew}
          />
        </Fragment>
      }
      backArrow
    />
  );
};
export default withAlert()(AddMeal);
