import React, { useState } from "react";
import Input from "../inputs";
import { Button } from "../buttons";
import "./styles.scss";

const Modal = ({
	showModal,
	isForm,
  disabled,
	title,
	onSubmit,
	saveText,
	closeModal,
	customContent,
	customClass,
	noSave,
	loading,
	deleteText,
	saveClick,
	withDel,
	delClick,
}) => {
	const [addForm, setAddForm] = useState([
		{
			type: "text",
			placeholder: "title",
			withLabel: true,
			label: "Post title",
			value: "",
			required: true,
			index: 0,
		},

		{
			type: "text",
			placeholder: "heading",
			withLabel: true,
			label: "Post heading",
			value: "",
			required: true,
			index: 1,
		},

		{
			type: "text",
			placeholder: "Enter post body",
			withLabel: true,
			label: "Post body",
			value: "",
			required: true,
			isTextArea: true,
			index: 2,
		},
		{
			type: "file",
			placeholder: "image",
			withLabel: true,
			label: "Upload post image",
			required: true,
			index: 3,
			value: "",
		},
	]);

	const [xploreAddForm, setXploreAddForm] = useState([
		{
			type: "text",
			placeholder: "Keyword",
			withLabel: true,
			label: "Keyword",
			value: "",
			required: true,
		},
		{
			type: "file",
			placeholder: "image",
			withLabel: true,
			label: "Upload post image",
			required: true,
			value: "",
		},
	]);
	const addFormChangeHandler = (fields, i, item, e, setFields) => {
		const field = [...fields];

		field[i][item] = e;
		setFields(field);
	};
	return (
		(showModal && (
			<div className="flex_column_right modal p_20">
				<button className="cursor_pointer close_button" onClick={closeModal}>
					x
				</button>
				<div className="back_drop flex_column_center p_20">
					{(isForm && (
						<form
							className="flex_column_center half_width_desktop scroll"
							onSubmit={onSubmit}
						>
							<button
								className="cursor_pointer close_button"
								onClick={closeModal}
								type="button"
							>
								x
							</button>
							<h6 className="bold_24px mb_35">{title}</h6>
							{addForm.map((item, i) => {
								return (
									(item.isTextArea && (
										<textarea
											className="text_area text_area_short mb_12"
											key={item.placeholder + i + i}
											placeholder={item.placeholder}
											value={item.value}
											onChange={(e) =>
												addFormChangeHandler(
													addForm,
													i,
													"value",
													e.target.value,
													setAddForm
												)
											}
											required={item.required}
										/>
									)) || (
										<Input
											key={item.placeholder + i + i}
											type={item.type}
											placeholder={item.placeholder}
											value={item.value}
											onChange={(e) =>
												addFormChangeHandler(addForm, i, "value", e, setAddForm)
											}
											required={item.required}
											withLabel={item.withLabel}
											label={item.label}
											containerClass="width_full mb_12"
										/>
									)
								);
							})}

							<Button text={saveText} className="mt_35" loading={loading} />
						</form>
					)) || (
						<form
							className={`custom_modal_content half_width_desktop scroll ${customClass}`}
							onSubmit={onSubmit}
						>
							<button
								className="cursor_pointer close_button"
								onClick={closeModal}
							>
								x
							</button>
							<h6 className="bold_24px mb_35">{title}</h6>
							{customContent}
							{!noSave && (
								<div className="flex_row_between">
									<Button
										text={saveText}
										className="mt_35"
										loading={loading}
										onClick={saveClick}
									/>
									{withDel && (
										<Button
											text={deleteText}
											className="mt_35"
											loading={loading}
											isRed
											type="button"
											onClick={delClick}
                      isDisabled={disabled}
										/>
									)}
								</div>
							)}
						</form>
					)}
				</div>
			</div>
		)) ||
		null
	);
};
export default Modal;
