import React from "react";
import "./styles.scss";
import { useHistory } from "react-router";

const NewNavItem = ({ activeItem, navText, onClick }) => {
return (
	<div
			className={`${
				activeItem
					? "side_nav_item flex_row_align_center cursor_pointer mb_35 underline bold_20"
					: "gray_label_sec bold_18 mb_35 cursor_pointer "
			} `}
			onClick={onClick}
		>
			<h6>{navText}</h6>
		</div>
	);
};

export default NewNavItem;
