import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ArrowBack, LocationIcon } from "../../assets/icons/icons";
import {FaInfo} from "react-icons/fa"
import {
	ITEM_INFO,
	ADD_MEAL,
	ADD_MEAL_CSV,
	ADD_MEAL_ADDON,
	DELETE_ADDON_CATEGORY
} from "../../router/router";
import Header from "../header";
import Loader from "../loader";
import AppSelect from "../select";
import Tabs from "../tabs";
import NewNavItem from "../newNavItem";
import "./styles.scss";

const MealLayout = ({
	content,
	headerTitle,
	handleChange,
	options,
	pageLoading,
	loading,
	children,
	modals,
	backArrow,
	currentLocation,
	setCurrentLocation,
}) => {
	const history = useHistory();
	const [pageWidth, setPageWidth] = useState("");
	const [showSide, setShowSide] = useState(true);

	useEffect(() => {
		setPageWidth(window.innerWidth);
		if (pageWidth <= 628) {
			setShowSide(false);
		} else {
			setShowSide(true);
		}
	}, [pageWidth]);

	useEffect(() => {
		const handleWindowResize = () => {
			setPageWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [pageWidth]);

	return (
		<main className="main_container layout">
			<div className="main_container_content">
				<Header />

				<section className="main_section">
					<div className="top_fixed">
						<Tabs itemInfo="active_nav_item" />

						<div className="border_bottom_thick">
							<div className="flex_row_between title_bar d-flex">
								<div className="flex_row_start">
									{/* {backArrow && (
										<button
											className="mr_30 cursor_pointer"
											onClick={history.goBack}
										>
											<ArrowBack width="17px" height="17px" fill="#FF0000" />
										</button>
									)} */}
									<div className="bold_24px flex_row">
										{headerTitle}
										<Tippy content={content}>
											<div style={{ marginLeft: "10px" }}>
												<AiFillQuestionCircle />
											</div>
										</Tippy>
									</div>
								</div>

								<div className="width_full select_width">
									<AppSelect
										placeholder="Select cokitchen"
										handleChange={(e) => {
											handleChange(e);
										}}
										containerClass=""
										options={options}
										name="name"
										id="id"
										isLoading={pageLoading}
									/>
									  <div className="info_important_cokitchen bold">
                    <div className="flex_row">
                    <h5 className=""><FaInfo width="20px" height="40px" /></h5> 
                  <p>
                 Select cokitchen
                  </p>
                    </div>
                
                </div>
								</div>
							</div>
						</div>
					</div>
					<div className="top_section">
						<div className="mobile_visible px_50 flex_row_between">
							{pageWidth <= 628 ? (
								<button
									className="mr_25 cursor_pointer"
									onClick={() => setShowSide(!showSide)}
								>
									<LocationIcon
										fill={"#FF0000"}
										width="18px"
										height="18px"
										className={showSide ? "rotate_90 ease" : "no_rotate ease"}
									/>
								</button>
							) : null}
						</div>
					</div>
				
						
						<section className="small_content mobile_column">
							{pageWidth > 628 || showSide ? (
								<aside className={`slide_in nav_links`}>
									<div className="flex_column_left">
									<NewNavItem
											navText="DELETE ADDON CATEGORY"
											key="delete addon category"
											onClick={() => {
												setCurrentLocation("delete addon category");
												history.push(DELETE_ADDON_CATEGORY);
											}}
											activeItem={currentLocation === "delete addon category"}
										/>
										<NewNavItem
											navText="CREATE MEAL ADDONS"
											key="create meal addons"
											onClick={() => {
												setCurrentLocation("create meal addons");
												history.push(ADD_MEAL_ADDON);
											}}
											activeItem={currentLocation === "create meal addons"}
										/>

										<NewNavItem
											navText="CREATE NEW MEAL"
											key="add meal"
											onClick={() => {
												setCurrentLocation("add meal");
												history.push(ADD_MEAL);
											}}
											activeItem={currentLocation === "add meal"}
										/>
										<NewNavItem
											navText="EDIT MEAL"
											key={"edit meal"}
											onClick={() => {
												setCurrentLocation("edit meal");
												history.push(ITEM_INFO);
											}}
											activeItem={currentLocation === "edit meal"}
										/>

										<NewNavItem
											navText="NEW MEALS/VIA CSV"
											key={"add meals via csv"}
											onClick={() => {
												setCurrentLocation("add meals via csv");
												history.push(ADD_MEAL_CSV);
											}}
											activeItem={currentLocation === "add meals via csv"}
										/>
									</div>
								</aside>
							) : null}
							{children}
						</section>
					
				</section>
			</div>
			{modals}
		</main>
	);
};

export default MealLayout;
// <Link to="">CREATE MEAL ADDONS</Link>

// 										<Link to="">CREATE NEW MEAL</Link>

// 										<Link to={ITEM_INFO}>EDIT MEAL</Link>

// 										<Link to="">NEW MEALS/VIA CSV</Link>

// 										<Link to="">EDIT MEALS/ADDONS VIA CSV</Link>
