import React from "react";
import PropTypes from "prop-types";

const RadioGroup = ({
	className,
	name,
	options,
	onChange,
	orientation,
	selectedOption,
	required,
	containerClass,
}) => {
	return (
		<div
			className={` radio_btn  flex flex-wrap justify-start  gap-7  ${
				orientation === "vertical" ? "vertical" : ""
			}`}
		>
			{options?.length > 0 &&
				options.map(({ value, title }, idx) => (
					<div key={idx} className="radio-flex">
						<input
							data-testid={name}
							id={`${name}-${title}-${idx}`}
							name={name}
							className={className}
							onChange={onChange}
							type="radio"
							value={value}
							checked={selectedOption === value}
							required={required}
						/>
						<label htmlFor={`${name}-${title}-${idx}`} className="bold_21px">
							<span className="text-sm radio_label bold_24_px ">{title}</span>
							<span className="bg-current checkmark "></span>
						</label>
					</div>
				))}
		</div>
	);
};

export default RadioGroup;
