import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";

import Select from "../../components/select";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import Tippy from "@tippyjs/react";
import CommonStore from "../../stores/dataStore/commonStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { AiFillQuestionCircle } from "react-icons/ai";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";
import RadioGroup from "../../components/inputs/RadioGroup";
import { ArrowBack } from "../../assets/icons/icons";
import MealLayout from "../../components/layout";
import { FaInfo } from "react-icons/fa";

import Loader from "react-loader-spinner";

const CreateAddon = ({ alert }) => {
  const emptyForm = {
    name: "",
    summary: "",
    brand_id: "",
    meal_category_selection_type_id: "",
    super_meal_category_id: "",
    selection_no: "",
    meal_category_id: "",
    is_addon_for_other_meals: false,
    meal_parent_ids: [],
    description: "",
    amount: "",
    is_addon: true,
    addon_category_name: "",
    addon_category_description: "",
  };
  const [mealParentIds, setMealParentIds]= useState([])
  const [categoryForm, setCategoryForm] = useState({ ...emptyForm });
  const [brandsAddonMeals, setBrandsAddonMeals] = useState([]);
  const [brandsAddon, setBrandsAddon] = useState([]);
  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  const [ckLoading, setCkLoading] = useState([]);
  const [duplicateParentMeals, setDuplicateParentMeals] = useState([]);
  const [adding, setAdding] = useState(false);
  const [brands, setBrands] = useState(null);
  const [isAddon, setISAddon] = useState(true);
  const [SelectedParentMeals, setSelectedParentMeals] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [mealCategoryArray, setMealCategoryArray] = useState([]);
  const [pageWidth, setPageWidth] = useState("");
  const [showSide, setShowSide] = useState(true);
  const [mealCategoryModal, showMealCategoryModal] = useState(false);
  const [brandId, setBrandId] = useState("");
  const [duplicateForm, setDuplicateForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("create meal addons");
  const [cokitchens, setCokitchens] = useState("");
  const [categorySelect, setCategorySelect] = useState([]);
  const [showMeal, setShowMeal] = useState("meals");
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showNew, setShowNew] = useState("new");
  const [selectBrand, setSelectBrand] = useState(true);
  const [addonCategories, setAddonCategories] = useState(true);
  const [selectedAddonCategories, setSelectedAddonCategories] = useState([]);
  const [selectedAddonCategory, setSelectedAddonCategory] = useState([]);
  const [mealsLoading, setMealsLoading] = useState(false);
  const [loadingAddonCategory, setLoadingAddonCategory] = useState(false);
  const [selectNew, setSelectNew] = useState(false);
  const [allMeals, setAllMeals] = useState([]);
  const [allAddons, setAllAddons] = useState([]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (
      !BrandStore.dataAltCached &&
      !MealCategoryStore.dataCached &&
      !CommonStore.dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        CommonStore.getCokitchensOnly(alert),
        MealCategoryStore.getMealCategorySelection(alert),
      ]);
    } else {
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt);
      }
      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchensOnly(alert);
        setCokitchens(CommonStore.cokitchens);
        setCkLoading(false);
      }

      if (!MealCategoryStore.dataCached) {
        await Promise.all([
          MealCategoryStore.getMealCategorySelection(alert),
          MealCategoryStore.getMealCategories(alert, true),
        ]);
      }
    }

    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });
    setBrands(BrandStore.brandsAlt);
    setBrandsAddon(MealCategoryStore.mealCategories);
    setMealCategoryTypesOptions();
    mergeMealCategories();
  }, []);

  const mergeMealCategories = () => {
    let i;
    let options = [];
    let curr = MealCategoryStore.mealCategories || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.meals];
    }
    setBrandsAddonMeals(options);
  };
  const setMealCategoryTypesOptions = async () => {
    let opts = MealCategoryStore.mealCategoriesSelection;

    setmealCategorySelections(opts);
  };

  const categoryFormSubmit = async () => {
    const { createAddonCategoriesandAddons } = BrandStore;
    let meal_parents = [];
    let ids = [...mealParentIds]
    ids.map((item) => meal_parents.push(item?.id));
    
    let body = {
      addon_category: {
        name: categoryForm?.addon_category_name,
        meal_category_selection_type_id:
          categoryForm?.meal_category_selection_type_id,
        description: categoryForm?.addon_category_description,
        summary: categoryForm?.addon_category_summary,
      },
      addon: {
        brand_id: brandId,
        amount: categoryForm?.amount,
        preparation_time: categoryForm?.preparation_time,
        description: categoryForm?.description,
        summary: categoryForm?.summary,
        name: categoryForm?.name,
      },
      meal_parent_ids: [...meal_parents],
    };

    if (!body.addon.brand_id) {
      alert.show("Brand is required!", {
        type: "info",
      });
    } else if (!body?.addon_category?.meal_category_selection_type_id) {
      alert.show("Meal category type is required!", {
        type: "info",
      });
    } else if (body?.meal_parent_ids && !body?.meal_parent_ids[0]) {
      alert.show("Meal parent is required!", {
        type: "info",
      });
     
    } else {
      if (!isAddon) {
        body.meal_category_selection_type_id = mealCategorySelections[0]?.id;
      }
      Object.keys(body).forEach((key, index) => {
        if (!body[key] || body[key] == {}) {
          if (body !== "is_addon") delete body[key];
        }
      });
      try {
        setAdding(true);
        let res;
        res = await createAddonCategoriesandAddons(alert, body);
        setAdding(false);
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        //setCategoryForm({ ...emptyForm });
        showMealCategoryModal(false);
      }
    }
  };
  const categoryAddon = async () => {
    const {
      getAddonCategories,
      createMultipleAddonCategories,
      duplicateAddonCategory,
    } = BrandStore;
    if (selectBrand) {
      if (SelectedParentMeals.length === 0) {
        alert.show("Parent Meal is required!", {
          type: "info",
        });
      } else {
        let parentMealIds = [];
        let parentMeals = [...mealParentIds];
        let parentMealId = parentMeals.map((item) => {
          parentMealIds.push(item.id);
        });
        let body = { meal_ids: [...parentMealIds] };
        setMealsLoading(true);
        await getAddonCategories(alert, body);
        let addonCat = [...BrandStore.mealAddonCategories];
        let addonCatOptions = [];

        addonCat.map((item) =>
          parentMeals.map((itm) => {
            if (item.meal_parent_id === itm.id) {
              let body = {
                name: `${item.name} - ${itm.name}`,
                id: item.id,
              };
              addonCatOptions.push(body);
            }
          })
        );
        setAddonCategories(addonCatOptions);
        setSelectBrand(!selectBrand);
        setSelectNew(!selectNew);
        setMealsLoading(false);
      }
    } else {
      if (showNew === "existing") {
        let body = {
          addon: {
            brand_id: brandId,
            name: categoryForm?.name,
            preparation_time: categoryForm?.preparation_time,
            description: categoryForm.description,
            summary: categoryForm.summary,
            amount: categoryForm?.amount,
          },

          addon_category_ids: [...selectedAddonCategories.copy],
        };

        setAdding(true);
        await createMultipleAddonCategories(alert, body);
        setAdding(false);
      } else if (showNew === "duplicate") {

        let meal_parents = [];
        let ids = [...mealParentIds];
        
        ids.map((item) => meal_parents.push(item?.id));
        

        let body = {
          from_meal_id: duplicateForm.id,
          to_meal_ids: meal_parents,
        };
        setAdding(true)
        await duplicateAddonCategory(alert, body)
        setAdding(false)
        setDuplicateForm({})
      } else {
        categoryFormSubmit();
      }
    }
  };
  const closeAddonCategoryModal = () => {
    showMealCategoryModal(!mealCategoryModal);
    setCategoryForm({ ...emptyForm });
    setCategoriesArray([]);
    setSelectedParentMeals([]);
    setSelectedCategories([]);
    setSelectBrand(true);
    setSelectNew(false);
  };
  const getMeals = async (item) => {
    const { getMealsOnly, getAddonsOnly } = BrandStore;
    setMealsLoading(true);
    await getMealsOnly(alert, item.id);
    setMealsLoading(false);

    setAllMeals(BrandStore.mealsOnly);
  };

  return (
    <MealLayout
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      headerTitle={"	Create Meal Addons"}
      content={"Add Meals to brands"}
      handleChange={setCurrentCokitchen}
      pageLoading={ckLoading}
      loading={loading}
      options={cokitchens}
      children={
        <Fragment>
          {" "}
          <section className="width_full new_addon_container">
            <div className="radio_class width_full radio_flex">
              {ckLoading ? (
                <Loader
                  type="ThreeDots"
                  color="#ff000060"
                  height={10}
                  width={50}
                />
              ) : (
                <div>
                  <div className="info_important bold flex_row ">
                    <div className="flex_row">
                      <h5 className="">
                        <FaInfo width="20px" height="40px" />
                      </h5>
                      <p>Select brand that addon will be created to</p>
                    </div>
                  </div>
                  <div className="radio_class width_full radio_flex">
                    {brands
                      ?.filter((item) => item.item_type === "FOOD")
                      .map((item, i) => {
                        let category = [...mealCategoryArray];
                        let curr = item;
                        return (
                          <div key={item?.id}>
                            <CheckBox
                              id={i}
                              checked={isChecked.includes(item?.id)}
                              onClick={(e) => {
                               
                                showMealCategoryModal(true);
                                setCategoryForm({
                                  ...categoryForm,
                                  brand_id: item,
                                });

                                setBrandId(item.id);

                                getMeals(item);
                              }}
                              label={item.name}
                            />

                            {category
                              .filter((item) => item.brand_id === curr.id)
                              ?.map((item) => (
                                <div
                                  className=""
                                  onClick={() => {
                                    showMealCategoryModal(true);

                                    setCategoryForm({
                                      ...categoryForm,
                                      brand_id: curr,
                                    });
                                  }}
                                >
                                  <h6 className="meal_category_link">
                                    {item.name}
                                  </h6>
                                </div>
                              ))}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>

            <div></div>
          </section>
        </Fragment>
      }
      modals={
        <Fragment>
          <Modal
            title={
              selectBrand
                ? "Select Parent Meal"
                : showNew === "existing"
                ? "Create Addon and update existing Addon category"
                : showNew === "duplicate"
                ? "Duplicate Addon Categories from  a meal "
                : "Create Addon category and Addon"
            }
            showModal={mealCategoryModal}
            closeModal={() => {
              closeAddonCategoryModal();
              setCategoryForm({ ...emptyForm });
            }}
            customContent={
              <div>
                {selectBrand && (
                  <div>
                    <div className="flex_row justify_between">
                      <div>
                      <Tippy
                        style={{ zIndex: "100" }}
                        content={`Select Parent meal addon will be created to `}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <AiFillQuestionCircle />
                        </div>
                      </Tippy>
                      </div>
                      <div>
                      <label className="bold_21px ">
									 Select meals addons will be created to
									</label>
                      <Select
                        placeholder="Select parent meal"
                        value={SelectedParentMeals || []}
                        isMulti
                        handleChange={(e) => {
                          let i;
                          let copy = [];
                          for (i = 0; i < e.length; i++) {
                            copy.push(e[i]);
                          }
                          setMealParentIds(copy);
                      

                          setSelectedParentMeals(e);
                        }}
                        containerClass=""
                        options={allMeals}
                        name="name"
                        id="id"
                        isLoading={mealsLoading}
                      />
                    </div>
                    </div>
                  </div>
                )}
                {selectNew && (
                  <div>
                    <div className="mb_30 d-flex flex_row_start ">
                      <div
                        className="mr_10 cursor_pointer"
                        onClick={() => {
                          setSelectBrand(true);
                          setSelectNew(false);
                        }}
                      >
                        <ArrowBack width="16px" height="16px" fill="#FF0000" />
                      </div>
                      {/* //{let opts= if(addonCategories.length === 0){}} */}
                      <div className="ml_15 mt_10">
                        <RadioGroup
                          name={"meal"}
                          selectedOption={showNew}
                          options={
                            addonCategories.length === 0
                              ? [
                                  { title: "New", value: "new" },
                                  { title: "Duplicate", value: "duplicate" },
                                ]
                              : [
                                  { title: "New", value: "new" },
                                  { title: "Existing", value: "existing" },
                                  { title: "Duplicate", value: "duplicate" },
                                ]
                          }
                          onChange={(e) => {
                            setShowNew(e.target.value);
                            setCategoryForm({...emptyForm})
                            setSelectedAddonCategory([])
                          
                          }}
                        />
                      </div>
                    </div>

                    {showNew === "new" && (
                      <div>
                        <h3 className="bold_21px mb_15">ADDON CATEGORY</h3>
                        <Input
                          placeholder="Meal addon category name"
                          value={categoryForm.addon_category_name}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              addon_category_name: e,
                            });
                          }}
                          required
                          containerClass="mb_15"
                        />
                        <Select
                          placeholder="Meal category addon type"
                          value={
                            mealCategorySelections?.find(
                              (item) =>
                                item?.id ===
                                categoryForm?.meal_category_selection_type_id
                            ) || ""
                          }
                          handleChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              meal_category_selection_type_id: e?.id,
                            });
                          }}
                          containerClass=""
                          options={mealCategorySelections}
                          name="name"
                          id="id"
                          isLoading={loading}
                        />
                        <Input
                          placeholder="Meal addon category description"
                          value={categoryForm.addon_category_description}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              addon_category_description: e,
                            });
                          }}
                          containerClass="mb_15"
                        />
                        <Input
                          placeholder="Meal addon category summary"
                          value={categoryForm.addon_category_summary}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              addon_category_summary: e,
                            });
                          }}
                          containerClass="mb_35"
                        />
                        <hr />
                        <div className="mt_10">
                          <h3 className="bold_21px mb_15">ADDON</h3>

                          <Input
                            placeholder="Enter Addon name"
                            value={categoryForm?.name}
                            onChange={(e) => {
                              setCategoryForm({ ...categoryForm, name: e });
                            }}
                            required
                            containerClass="mb_15 width_full"
                          />

                          <Input
                            placeholder="Enter Addon amount"
                            value={categoryForm?.amount}
                            onChange={(e) => {
                              setCategoryForm({ ...categoryForm, amount: e });
                            }}
                            required
                            containerClass="mb_15 width_full"
                            type="number"
                          />

                          <Input
                            placeholder="Enter Addon preparation time (minutes)"
                            value={categoryForm?.preparation_time}
                            onChange={(e) => {
                              setCategoryForm({
                                ...categoryForm,
                                preparation_time: e,
                              });
                            }}
                            required
                            containerClass="mb_15 width_full"
                          />

                          <textarea
                            className="text_area text_area_short mb_35"
                            placeholder="Enter Addon description "
                            value={categoryForm?.description}
                            onChange={(e) => {
                              setCategoryForm({
                                ...categoryForm,
                                description: e.target.value,
                              });
                            }}
                          />

                          <textarea
                            className="text_area text_area_short mb_35"
                            placeholder="Enter Addon summary "
                            value={categoryForm?.summary}
                            onChange={(e) => {
                              setCategoryForm({
                                ...categoryForm,
                                summary: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {showNew === "existing" && (
                      <div>
                        		<label className="bold_21px ">
									Select addon categories
									</label>
                        <Select
                          placeholder="Select addon category to be updated"
                          value={selectedAddonCategory || []}
                          isMulti
                          handleChange={(e) => {
                            let i;
                            let copy = [];
                            for (i = 0; i < e.length; i++) {
                              copy.push(e[i].id);
                            }
                            setSelectedAddonCategories({
                              ...selectedAddonCategories,
                              copy,
                            });
                            setSelectedAddonCategory(e);
                          }}
                          containerClass=""
                          options={addonCategories}
                          name="name"
                          id="id"
                          isLoading={loadingAddonCategory}
                        />
                        <Input
                          placeholder="Enter Addon name"
                          value={categoryForm?.name}
                          onChange={(e) => {
                            setCategoryForm({ ...categoryForm, name: e });
                          }}
                          required
                          containerClass="mb_15 width_full"
                        />

                        <Input
                          placeholder="Enter Addon amount"
                          value={categoryForm?.amount}
                          onChange={(e) => {
                            setCategoryForm({ ...categoryForm, amount: e });
                          }}
                          required
                          containerClass="mb_15 width_full"
                          type="number"
                        />

                        <Input
                          placeholder="Enter Addon preparation time (minutes)"
                          value={categoryForm?.preparation_time}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              preparation_time: e,
                            });
                          }}
                          required
                          containerClass="mb_15 width_full"
                        />

                        <textarea
                          className="text_area text_area_short mb_35"
                          placeholder="Enter Addon description "
                          value={categoryForm?.description}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              description: e.target.value,
                            });
                          }}
                        />

                        <textarea
                          className="text_area text_area_short mb_35"
                          placeholder="Enter Addon summary "
                          value={categoryForm?.summary}
                          onChange={(e) => {
                            setCategoryForm({
                              ...categoryForm,
                              summary: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {showNew === "duplicate" && !selectBrand && (
                  <div>
									<label className="bold_21px ">
									 Select meal addons should be duplicated from
									</label>
                    <Select
                      placeholder="Select meal"
                      value={duplicateForm || []}
                      handleChange={(e) => {
                        setDuplicateForm(e);
                        console.log(e);
                      }}
                      containerClass=""
                      options={allMeals}
                      name="name"
                      id="id"
                      isLoading={mealsLoading}
                    />
                  </div>
                )}
              </div>
            }
            customClass={
              selectBrand ? "no-scroll feedback_modal" : "feedback_modal"
            }
            onSubmit={(e) => {
              e.preventDefault();
              categoryAddon();
            }}
            saveText={adding ? "Updating":
              selectBrand
                ? "Next"
                : showNew === "existing"
                ? "Create Addon in an existing Category"
                : showNew === "new"
                ? "Create Addon  in a new Category"
                : "Duplicate Addon Categories" 
            }
            loading={adding}
          />
        </Fragment>
      }
      backArrow
    />
  );
};
export default withAlert()(CreateAddon);
